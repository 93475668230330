import { useCustomMutation, useNavigation } from '@refinedev/core';
import { Loading } from '@zodinet-pos/core-ui';
import { notification } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { getEnvConfig } from '../../../getEnvConfig';

export const ZaloCallbackIndex: React.FC = () => {
    const { mutate: customMutation } = useCustomMutation<any>();
    const apiUrl = getEnvConfig.CUSTOMER_URL;
    const [searchParams] = useSearchParams();
    const { t: translate } = useTranslation(['common']);
    const { push } = useNavigation();

    useEffect(() => {
        const code = searchParams?.get('code') as string;
        const zaloOA = searchParams?.get('oa_id') as string;

        customMutation(
            {
                url: `${apiUrl}/v1/bo/settings/connect-oa`,
                method: 'post',
                values: {
                    oaId: zaloOA,
                    code: code,
                },
                errorNotification: {
                    message: translate('update_err'),
                    type: 'error',
                },
            },
            {
                onSuccess: (data) => {
                    const response = data.data;
                    if (response) {
                        push('/dashboard');
                        notification.success({ message: translate('update_success') });
                    } else {
                        notification.error({ message: translate('update_err') });
                    }
                },
                onError: () => {
                    notification.error({ message: translate('update_err') });
                },
            },
        );
    }, []);

    return <Loading />;
};
