/** Mask phone to (000)-0000-0000 */
export const maskPhone = (phone: string): string => {
    if (!phone) {
        return phone;
    }
    const trim = phone.replace(/\D[^.]/g, '');
    if (trim.length !== 10) {
        return phone;
    }

    const masked = '(' + trim.slice(0, 3) + ')-' + trim.slice(3, 6) + '-' + trim.slice(6);
    return masked;
};
/** Convert masked phone to phone number (000)-0000-0000  to 0000000000s*/
export const maskedToPhone = (maskedPhone: string): string => {
    const trim = maskedPhone.replace(/\D[^.]/g, '');
    if (trim.length !== 10) {
        return trim;
    }

    return maskedPhone;
};

export const convertToPlain = (html: string): string => {
    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    const content = tempDivElement.textContent || tempDivElement.innerText || '';
    tempDivElement.remove();
    return content;
};

export const stringSlice = (text: string, length: number): string => {
    if (text.length > length) {
        return text.substring(0, length) + '...';
    } else {
        return text;
    }
};

export const camelToSnakeCase = (str: string) => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const toNormalForm = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
