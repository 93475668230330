import { AntDesignOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { VisibilityEnum } from '@enums/visibility.enum';
import { getDefaultSortOrder, mapAntdSorterToCrudSorting, useDrawerForm } from '@refinedev/antd';
import { useCustomMutation, useTable } from '@refinedev/core';
import {
    Avatar,
    Dropdown,
    Menu,
    notification,
    Popconfirm,
    Table,
    TablePaginationConfig,
    Tooltip,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { getEnvConfig } from 'getEnvConfig';
import { useTranslation } from 'react-i18next';

import { Chip } from '../../../components/modules/chip';
import { CustomSearch } from '../../../components/modules/custom-search';
import { CustomPagination } from '../../../components/modules/pagination';
import { PAGE_SIZE, VN_DATE_FORMAT_MINUTE } from '../../../constants/index.constant';
import { StoreSettingType } from '../../../enums/store-setting-type.enum';
import { TenantStatusEnum } from '../../../enums/tenant-status.enum';
import { TenantTypeEnum } from '../../../enums/tenant-type.enum';
import { IStore } from '../../../interfaces/store';
import { formatDate, getPublicMediaUrl } from '../../../utils/resource';
import { StoreDetail } from './detail/detail';

export const Merchants: React.FC = () => {
    const { t } = useTranslation(['partner', 'common']);
    const { mutate } = useCustomMutation();
    const apiUrl = getEnvConfig.MARKET_API_URL;

    const {
        setFilters,
        tableQueryResult,
        current,
        setCurrent,
        pageSize,
        setPageSize,
        sorters,
        setSorters,
    } = useTable<IStore.IStoreInfor>({
        resource: 'v1/bo/store',
        dataProviderName: 'marketplace',
        sorters: {
            initial: [
                {
                    field: 'name',
                    order: 'asc',
                },
            ],
        },
        filters: {
            initial: [
                {
                    field: 'type',
                    operator: 'eq',
                    value: TenantTypeEnum.TENANT,
                },
            ],
        },
        pagination: {
            mode: 'server',
            pageSize: PAGE_SIZE,
        },
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IStore.IStoreInfor>({
        action: 'edit',
        resource: 'v1/bo/store',
        dataProviderName: 'marketplace',
        successNotification: { message: 'Successfully edited', type: 'success' },
        redirect: false,
    });

    const onToggleStoreVisibility = (id: string, visibility?: VisibilityEnum) => {
        const url = `${apiUrl}/v1/bo/store/visibility`;
        mutate(
            {
                dataProviderName: 'marketplace',
                url: url,
                method: 'put',
                values: {
                    id,
                    visibility:
                        visibility === VisibilityEnum.VISIBLE
                            ? VisibilityEnum.HIDDEN
                            : VisibilityEnum.VISIBLE,
                },
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data, _, __) => {
                    tableQueryResult.refetch();
                    if (data && data.data?.isSuccess) {
                        notification.success({
                            className: 'success-notification',
                            message: '',
                            description: t('update_success', { ns: 'common' }),
                        });
                    } else {
                        notification.error({
                            className: 'error-notification',
                            message: '',
                            description: t('update_err', { ns: 'common' }),
                        });
                    }
                },
            },
        );
    };

    const menu = (id: string, record: IStore.IStoreInfor) => (
        <Menu mode="vertical">
            <Menu.Item
                key="1"
                icon={<img src="/images/icons/edit.svg" alt="edit" className="cursor-pointer" />}
                onClick={() => {
                    editShow(id);
                }}
            >
                {t('actions.edit', { ns: 'common' })}
            </Menu.Item>

            <Popconfirm
                placement="topRight"
                title={
                    record.visibility === VisibilityEnum.VISIBLE
                        ? t('actions.hide_from_market', { ns: 'common' })
                        : t('actions.show_from_market', { ns: 'common' })
                }
                okText={t('yes', { ns: 'common' })}
                cancelText={t('no', { ns: 'common' })}
                onConfirm={() => onToggleStoreVisibility(id, record.visibility)}
            >
                <Menu.Item
                    key="3"
                    icon={
                        <div className="cursor-pointer">
                            {record.visibility === VisibilityEnum.VISIBLE ? (
                                <EyeInvisibleOutlined />
                            ) : (
                                <EyeOutlined />
                            )}
                        </div>
                    }
                >
                    {record.visibility === VisibilityEnum.VISIBLE
                        ? t('actions.hide_from_market', { ns: 'common' })
                        : t('actions.show_from_market', { ns: 'common' })}
                </Menu.Item>
            </Popconfirm>
        </Menu>
    );

    const renderStatus = (visibility: VisibilityEnum) => {
        let label = '';
        let type = '';
        console.log(visibility);

        switch (visibility) {
            case VisibilityEnum.HIDDEN:
                label = t('partners.status.hidden', { ns: 'partner' });
                type = 'disable';
                break;

            default:
                label = t('partners.status.visible', { ns: 'partner' });
                type = 'success';
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    const onSearchTenant = (event: any) => {
        setCurrent(1);
        setFilters([
            {
                field: 'filter',
                operator: 'eq',
                value: event?.target?.value,
            },
        ]);
    };

    const onChangeTable = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    return (
        <>
            <StoreDetail
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
                isEditMode={true}
            />
            <section>
                <div className="flex justify-between items-center px-0 py-2">
                    <CustomSearch
                        placeholder={t('partners.search')}
                        className="w-52"
                        onChange={(event: any) => onSearchTenant(event)}
                        allowClear={true}
                    />
                </div>

                <div className="overflow-hidden">
                    <Table
                        dataSource={tableQueryResult?.data?.data ?? []}
                        pagination={false}
                        rowKey="id"
                        onChange={onChangeTable}
                        scroll={{ y: '100%', x: '800px' }}
                    >
                        <Table.Column
                            title={<>{t('partners.merchant_name')}</>}
                            dataIndex="name"
                            key="name"
                            sorter
                            defaultSortOrder={getDefaultSortOrder('name', sorters)}
                            render={(row, record: IStore.IStoreInfor) => (
                                <div className="flex justify-between items-center text-ellipsis overflow-hidden">
                                    <div className="flex justify-start items-center">
                                        <div className="w-10 h-10">
                                            <Avatar
                                                size={40}
                                                src={
                                                    record?.settings?.length
                                                        ? getPublicMediaUrl(
                                                              record.settings?.find(
                                                                  (setting) =>
                                                                      setting.type ===
                                                                      StoreSettingType.favicon,
                                                              )?.value || '',
                                                          )
                                                        : null
                                                }
                                                icon={<AntDesignOutlined />}
                                            />
                                        </div>

                                        <p className="table-tbody-text ml-2 item-name p-0 w-auto">
                                            <Tooltip title={row}>{row}</Tooltip>
                                        </p>
                                    </div>
                                </div>
                            )}
                            width={300}
                        />
                        <Table.Column
                            sorter
                            title={<>{t('partners.fields.email.label', { ns: 'partner' })}</>}
                            dataIndex="email"
                            key="email"
                            render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            width={250}
                        />
                        <Table.Column
                            sorter
                            title={<>{t('partners.fields.phoneNumber.label', { ns: 'partner' })}</>}
                            dataIndex="phone"
                            key="phone"
                            render={(text, _) => <p className="table-tbody-text">{text || '--'}</p>}
                            width={150}
                        />

                        <Table.Column
                            sorter
                            title={<>{t('partners.fields.status.label', { ns: 'partner' })}</>}
                            dataIndex="visibility"
                            key="visibility"
                            render={(text, _) => renderStatus(text)}
                            width={150}
                        />

                        <Table.Column
                            sorter
                            title={<>{t('partners.created_at', { ns: 'partner' })}</>}
                            dataIndex="createdAt"
                            key="createdAt"
                            render={(text, _) => (
                                <p className="table-tbody-text">
                                    {formatDate(text, VN_DATE_FORMAT_MINUTE)}
                                </p>
                            )}
                            width={100}
                        />
                        <Table.Column
                            dataIndex="id"
                            key="action"
                            render={(id, record: IStore.IStoreInfor) => (
                                <div className="flex justify-end items-center">
                                    <Dropdown
                                        overlay={menu(id, record)}
                                        placement="bottomRight"
                                        arrow
                                        overlayClassName="contact-dropdown-container"
                                        trigger={['click']}
                                    >
                                        <img
                                            src="/images/icons/dots-vertical.svg"
                                            alt="more"
                                            className="cursor-pointer"
                                        />
                                    </Dropdown>
                                </div>
                            )}
                            width={100}
                        />
                    </Table>
                </div>

                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>
        </>
    );
};
