import './styles.scss';

import { HttpError, useApiUrl, useCustom } from '@refinedev/core';
import { BusinessTypeEnum } from '@zodinet-pos/core-ui';
import { Col, Form, FormProps, Input, Radio, Row, Select, UploadFile } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Response from '../../../data-access/responses/response';
import { ItemVisibilityEnum } from '../../../enums';
import { NBusinessType } from '../../../interfaces/business-type';
import { DebounceAsyncValidator } from '../../../utils/validator';
import { Hint } from '../../modules/hint';
import { ImageUpload } from '../../modules/image-upload';

export type FormMode = 'show' | 'add' | 'edit';
type Prop = {
    formProps: FormProps;
    mode: FormMode;
    onCancelEdit?: () => void;
    onChangeMode?: (mode: FormMode) => void;
    isCreate?: boolean;
};
export const BusinessTypeDetailForm: React.FC<Prop> = ({ formProps, mode }) => {
    const apiUrl = useApiUrl();
    const { t, i18n } = useTranslation(['business_type', 'common']);
    const initialValues: NBusinessType.IInfo = {
        ...formProps.initialValues,
    } as NBusinessType.IInfo;

    const [code, setCode] = useState<string>(initialValues ? initialValues.code : '');
    const [selectedType, setSelectedType] = useState<BusinessTypeEnum>();

    const onChangeImage = (file: UploadFile<any>) => {
        formProps.form?.setFieldsValue({ imageUrl: file.response });
    };
    const onChangeFallbackImage = (file: UploadFile<any>) => {
        formProps.form?.setFieldsValue({ imageFallbackUrl: file.response });
    };

    const { refetch } = useCustom<
        Response<boolean>,
        HttpError,
        NBusinessType.ICheckUniqueCodeRequest
    >({
        url: `${apiUrl}/v1/business-type/is-unique`,
        method: 'post',
        config: {
            payload: { code },
        },
        queryOptions: {
            enabled: false,
        },
    });

    const asyncValidateUniqueHost = DebounceAsyncValidator(400, async () => {
        const { data } = await refetch();

        if (data && data?.data?.data) {
            return true;
        }
        return false;
    });

    const renderFormItem = (isReadonly: boolean, formItem: JSX.Element, showItem: JSX.Element) => {
        if (isReadonly) {
            return showItem;
        }
        return formItem;
    };

    return (
        <Form
            className={`business_detail ${mode === 'show' ? 'show-mode' : ''}`}
            {...formProps}
            onFinish={async (values) => {
                formProps.onFinish?.(values);
            }}
            layout="vertical"
            initialValues={{
                ...initialValues,
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    <Form.Item
                        label={t('fields.type.label')}
                        name="type"
                        required={mode === 'add'}
                        rules={[
                            {
                                required: true,
                                message: t(`fields.type.validation_rules.required`),
                            },
                        ]}
                    >
                        {mode === 'add' ? (
                            <Select
                                showArrow
                                suffixIcon={<img src="/images/icons/arrow-down-icon.svg" />}
                                onSelect={(type) => setSelectedType(type as BusinessTypeEnum)}
                            >
                                {(
                                    Object.keys(
                                        BusinessTypeEnum,
                                    ) as (keyof typeof BusinessTypeEnum)[]
                                ).map((type) => (
                                    <Select.Option value={type} key={type}>
                                        {i18n.exists(`types.${type}`, { ns: 'business_type' })
                                            ? t(`types.${type}`)
                                            : type}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <span>
                                {i18n.exists(`types.${initialValues.type}`, { ns: 'business_type' })
                                    ? t(`types.${initialValues.type}`)
                                    : initialValues.type}
                            </span>
                        )}
                    </Form.Item>

                    <Form.Item
                        className="toggle-button"
                        label={t('fields.code.label')}
                        name="code"
                        initialValue={dayjs().unix().toString()}
                        rules={
                            mode === 'add'
                                ? [
                                      {
                                          required: true,
                                          message: t('fields.code.validation_rules.required'),
                                      },
                                      {
                                          pattern: /^[a-zA-Z0-9]?[a-zA-Z0-9.-]*[a-zA-Z0-9]$/,
                                          message: t('fields.code.validation_rules.pattern'),
                                      },
                                      {
                                          validator: async (_, value) => {
                                              if (
                                                  value === '' ||
                                                  !value ||
                                                  value === initialValues.code ||
                                                  !/^[a-zA-Z0-9]?[a-zA-Z0-9.-]*[a-zA-Z0-9]$/.test(
                                                      value,
                                                  )
                                              ) {
                                                  return;
                                              }

                                              const result = await asyncValidateUniqueHost();
                                              if (result) {
                                                  return Promise.resolve();
                                              }
                                              return Promise.reject(
                                                  new Error(
                                                      t('fields.code.validation_rules.unique'),
                                                  ),
                                              );
                                          },
                                      },
                                  ]
                                : []
                        }
                    >
                        {renderFormItem(
                            mode !== 'add',
                            <Input maxLength={10} onChange={(e) => setCode(e.target.value)} />,
                            <span>{initialValues?.code}</span>,
                        )}
                    </Form.Item>

                    <Form.Item
                        label={t('fields.name.label')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: `${t('fields.name.validation_rules.required')}`,
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <Input maxLength={255} />,
                            <span>{initialValues?.name}</span>,
                        )}
                    </Form.Item>

                    <Form.Item
                        label={t('fields.visibility.label')}
                        name="visibility"
                        initialValue={true}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <Radio.Group buttonStyle="solid">
                                {(
                                    Object.keys(
                                        ItemVisibilityEnum,
                                    ) as (keyof typeof ItemVisibilityEnum)[]
                                ).map((key) => (
                                    <Radio.Button value={ItemVisibilityEnum[key]} key={key}>
                                        {t(`visibility.${key}`)}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>,
                            <span>{initialValues?.name}</span>,
                        )}
                    </Form.Item>

                    <Form.Item
                        label={t('fields.image_url.label')}
                        name="imageUrl"
                        rules={[
                            {
                                required: true,
                                message: 'Image is required',
                            },
                        ]}
                    >
                        <ImageUpload
                            width={52}
                            height={52}
                            onChange={(fileOrn) => onChangeImage(fileOrn)}
                            uploadText="Image"
                            value={initialValues?.imageUrl}
                        />
                        <Hint width={52} height={52} size={5} fileType={['JPG', 'PNG']} />
                    </Form.Item>

                    <Form.Item label={t('fields.image_fallback_url.label')} name="imageFallbackUrl">
                        <ImageUpload
                            width={52}
                            height={52}
                            onChange={(fileOrn) => onChangeFallbackImage(fileOrn)}
                            uploadText="Image"
                            value={initialValues?.imageUrl}
                        />
                        <Hint width={52} height={52} size={5} fileType={['JPG', 'PNG']} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
