import './styles.scss';

import { useUpdate } from '@refinedev/core';
import { Button, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Spin, Switch } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT_TIME, MARKETPLACE_SETTING_RESOURCE } from '../../../constants/index.constant';
import { MarketplaceBannerTypeEnum } from '../../../enums/marketplace-banner-type.enum';
import { IMarketplaceSetting } from '../../../interfaces/components/marketplace-setting';
import { getPublicMediaUrl } from '../../../utils/resource';
import { Hint } from '../../modules/hint';
import { ImageUpload } from '../../modules/image-upload';

type newSettingProps = {
    setting: IMarketplaceSetting.ICreateMarketplaceSetting;
    onDeleteBannerSetting: (bannerId: string) => void;
};

export const BannerSetting: React.FC<newSettingProps> = ({ setting, onDeleteBannerSetting }) => {
    const { mutate } = useUpdate();

    const { t } = useTranslation(['marketplace', 'common']);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExistImage, setIsExistImage] = useState<boolean>(false);

    useEffect(() => {
        if (setting) {
            const transformData = {
                isActive: setting?.isActive,
                imageUrl: getPublicMediaUrl(setting?.imageUrl || ''),
                bannerType: setting?.type,
                targetBanner: setting?.meta?.target,
                endTime: dayjs(setting?.endTime),
                order: setting?.order,
            };

            if (setting?.imageUrl) {
                setIsExistImage(true);
            }

            form.setFieldsValue(transformData);
        }
    }, [setting]);

    const onChangeImage = (e: any) => {
        setIsLoading(true);
        // response is the url of file has been upload if successfully
        if (e?.response && e?.status === 'done') {
            form.setFieldsValue({ imageUrl: e?.response });
            setIsExistImage(true);
            setIsLoading(false);
        }
    };

    const onFinish = async () => {
        setIsLoading(true);
        const isValid = await form.validateFields();
        const valueForm = form.getFieldsValue();
        let request: IMarketplaceSetting.ICreateMarketplaceSetting = {};
        if (isValid && valueForm) {
            request = {
                id: setting?.id,
                imageUrl: valueForm.imageUrl,
                type: valueForm.bannerType,
                meta: {
                    target: valueForm.targetBanner,
                },
                endTime: valueForm.endTime,
                isActive: valueForm.isActive,
                order: valueForm.order,
            };
        }

        const values = {
            banner: request,
        };

        mutate({
            resource: `${MARKETPLACE_SETTING_RESOURCE}/banner`,
            dataProviderName: 'marketplace',
            id: '',
            values: values,
            successNotification: () => {
                return {
                    message: t('marketplace.update_banner_success'),
                    type: 'success',
                };
            },
            errorNotification: (error) => {
                console.log(error);
                return {
                    message: 'marketplace.update_banner_setting_err',
                    type: 'error',
                };
            },
        });

        setIsLoading(false);
    };

    return (
        <Spin spinning={isLoading}>
            <section className="banner-setting-container">
                <Form
                    form={form}
                    name="wrap"
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                    layout="vertical"
                    initialValues={{ imageUrl: '', isActive: false }}
                >
                    <Row gutter={[32, 16]}>
                        <Col xs={24} xl={24}>
                            <section className="w-full flex items-center relative">
                                <div className="mb-4 w-full">
                                    <Col xs={24} xl={24}></Col>

                                    <div className="form-container my-1">
                                        <div
                                            className={`mt-2 mb-4 banner marketplace-banner w-full flex items-center ${
                                                !isExistImage ? 'banner-not-value' : ''
                                            }`}
                                        >
                                            <Col xs={24} xl={12}>
                                                <ImageUpload
                                                    width={430}
                                                    height={175}
                                                    uploadText={t('marketplace.banner')}
                                                    shape={'rect'}
                                                    isTemporary={false}
                                                    hideIconAfterUpload={true}
                                                    onChange={(e) => onChangeImage(e)}
                                                    value={setting?.imageUrl ?? ''}
                                                />
                                                <Form.Item
                                                    className="image-form-container"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'marketplace.image_required',
                                                            ),
                                                        },
                                                    ]}
                                                    name="imageUrl"
                                                >
                                                    <></>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={12}>
                                                <Hint
                                                    width={430}
                                                    height={175}
                                                    size={5}
                                                    fileType={['JPG', 'PNG']}
                                                />
                                            </Col>
                                        </div>

                                        <Col xs={24} xl={12}>
                                            <div className="banner-config-container w-full">
                                                <Form.Item
                                                    required
                                                    name="isActive"
                                                    label={t('marketplace.is_active')}
                                                    valuePropName="checked"
                                                >
                                                    <Switch
                                                        checkedChildren={t('marketplace.enable')}
                                                        unCheckedChildren={t('marketplace.disable')}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={'bannerType'}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'marketplace.banner_type_required',
                                                            ),
                                                        },
                                                    ]}
                                                    label={t('marketplace.banner_type.title')}
                                                >
                                                    <Select
                                                        className="custom-select flex items-center"
                                                        placeholder={t(
                                                            `marketplace.banner_type.title`,
                                                        )}
                                                        options={(
                                                            Object.keys(
                                                                MarketplaceBannerTypeEnum,
                                                            ) as (keyof typeof MarketplaceBannerTypeEnum)[]
                                                        ).map((key) => {
                                                            return {
                                                                label: t(
                                                                    `marketplace.banner_type.${key}`,
                                                                ),
                                                                value: MarketplaceBannerTypeEnum[
                                                                    key
                                                                ],
                                                            };
                                                        })}
                                                        showSearch
                                                        allowClear
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={'targetBanner'}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'marketplace.banner_target_required',
                                                            ),
                                                        },
                                                    ]}
                                                    label={t('marketplace.target')}
                                                    className="w-full"
                                                >
                                                    <Input
                                                        className="input"
                                                        placeholder={t(
                                                            'marketplace.banner_target_placeholder',
                                                        )}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={'endTime'}
                                                    label={t('marketplace.end_time')}
                                                    className="w-full"
                                                >
                                                    <DatePicker
                                                        placeholder={t('marketplace.select_time')}
                                                        getPopupContainer={() => {
                                                            return document.querySelector?.(
                                                                '.ant-col',
                                                            ) as HTMLElement;
                                                        }}
                                                        allowClear={true}
                                                        format={DATE_FORMAT_TIME}
                                                        className="w-full time"
                                                        showTime
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={'order'}
                                                    tooltip={t('marketplace.banner_order_hint')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'marketplace.banner_order_required',
                                                            ),
                                                        },
                                                    ]}
                                                    label={t('marketplace.banner_order')}
                                                    className="w-1/4"
                                                >
                                                    <Input
                                                        className="input"
                                                        placeholder={t(
                                                            'marketplace.banner_order_placeholder',
                                                        )}
                                                        type="number"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            </section>
                        </Col>
                    </Row>
                </Form>

                <div className="flex items-start justify-end">
                    <div className="flex">
                        <Popconfirm
                            placement="top"
                            title={t('marketplace.delete_banner_confirm')}
                            onConfirm={() => onDeleteBannerSetting(setting?.id as string)}
                            okText={t('yes', { ns: 'common' })}
                            cancelText={t('no', { ns: 'common' })}
                        >
                            <Button
                                type="primary"
                                className="float-right btn-submit animated fadeInDown mr-4"
                            >
                                {t('buttons.delete', { ns: 'common' })}
                            </Button>
                        </Popconfirm>

                        <Button
                            type="primary"
                            className="float-right btn-submit animated fadeInDown"
                            onClick={onFinish}
                        >
                            {t('buttons.save', { ns: 'common' })}
                        </Button>
                    </div>
                </div>
            </section>
        </Spin>
    );
};
