import { CustomModal } from '@components/modules/modal-custom';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import s from './notification-payment-modal.module.scss';

type NotiModalProps = {
    onClose: () => void;
    title: string;
    subTitle: string;
};

export const NotificationInformationModal: FC<NotiModalProps> = ({ onClose, subTitle, title }) => {
    const { t } = useTranslation('common');

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    useEffect(() => {
        if (subTitle) setIsOpenModal(true);
    }, [subTitle]);

    const handleClose = useCallback(() => {
        onClose();
        setIsOpenModal(false);
    }, []);

    return (
        <CustomModal
            footer={false}
            closable={true}
            open={isOpenModal}
            maskClosable={false}
            onCancel={handleClose}
        >
            <section className={`${s.notificationPaymentModal} flex flex-col items-center`}>
                <div className={`  flex flex-col items-center mt-4`}>
                    <img src="/images/payment-success.png" className={s.successImg} />
                    <h4 className={`${s.title} text-center mt-4 mb-2`}>{title}</h4>
                    {subTitle && <p>{t(subTitle)}</p>}
                </div>
            </section>
        </CustomModal>
    );
};
