import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import { COLORS_CHART } from '../../../constants/index.constant';

export const GoalChart: React.FC<{
    value: string;
}> = ({ value }) => {
    const { t: translate } = useTranslation(['common']);
    const gaugeData = [
        {
            value: value,
            detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%'],
            },
        },
    ];
    const options = {
        color: COLORS_CHART,
        series: [
            {
                type: 'gauge',
                startAngle: 90,
                endAngle: -270,
                pointer: {
                    show: false,
                },
                progress: {
                    show: true,
                    overlap: false,
                    roundCap: true,
                    clip: false,
                },
                axisLine: {
                    lineStyle: {
                        width: 20,
                    },
                },
                splitLine: {
                    show: false,
                    distance: 0,
                    length: 10,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                    distance: 50,
                },
                data: gaugeData,
                title: {
                    fontSize: 14,
                },
                detail: {
                    formatter: '{value}%',
                },
            },
        ],
    };

    return (
        <>
            <ReactECharts option={options} />
        </>
    );
};
