import './styles.scss';

import { useDrawerForm } from '@refinedev/antd';
import { BaseKey, useGetIdentity, useNavigation, useOne, usePermissions } from '@refinedev/core';
import { Avatar, Button, Col, Row, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ContactDetail } from '../../components/contact';
import { ContactInvoiceList } from '../../components/contact-invoice-list';
import { ContactNoteList } from '../../components/contact-note/note-list';
import { ContactOrderList } from '../../components/contact-order-list';
import { ContactQuoteList } from '../../components/contact-quote-list';
import { FacebookIcon } from '../../components/layout/icons/facebook';
import { TwitterIcon } from '../../components/layout/icons/twitter';
import { WebsiteIcon } from '../../components/layout/icons/website';
import { PERMISSIONS } from '../../constants/permission';
import { UserTypeEnum } from '../../enums/user-type.enum';
import { IContact } from '../../interfaces/contact';
import { IUser } from '../../interfaces/user';
import { getPublicMediaUrl } from '../../utils/resource';
import { maskPhone } from '../../utils/string';

export const ContactDetailPage: React.FC = () => {
    const { goBack, list, push } = useNavigation();
    const { t } = useTranslation(['contact', 'common']);
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();
    const isTenantUser = userIdentity?.userType === UserTypeEnum.TENANT;
    const { id } = useParams();
    if (!id) {
        list('wl_contact');
        return <></>;
    }
    const { data, error, isLoading } = useOne<IContact.IContact>({
        resource: 'v1/contacts',
        id: id as BaseKey,
        errorNotification: false,
        queryOptions: {
            retry: 1,
        },
    });
    const [contact, setContact] = useState<IContact.IContact | null>(null);
    const { data: permissions } = usePermissions<string[]>();

    useEffect(() => {
        if (data?.data) {
            setContact(data.data);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            const { statusCode } = error as { statusCode: number };
            if (statusCode === 404) {
                push('/404');
            }
        }
    }, [error]);

    const renderField = (
        label: string,
        value = '--',
        isPhoneField = false,
        isLinkField = false,
    ) => {
        if (!value) {
            value = '--';
        }
        return (
            <>
                <div className="label"> {label}</div>
                {isPhoneField ? (
                    <div className="value mt-1">
                        <a href={`${value === '--' ? '' : `tel:${value}`}`}>{maskPhone(value)}</a>
                    </div>
                ) : isLinkField ? (
                    <div className="value mt-1">
                        {value === '--' ? (
                            <p>{value}</p>
                        ) : (
                            <a href={value} target="blank">
                                {value}
                            </a>
                        )}
                    </div>
                ) : (
                    <div className="value mt-1"> {value}</div>
                )}
            </>
        );
    };

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IContact.IContact>({
        action: 'edit',
        resource: 'v1/contacts',
        successNotification: { message: 'Successfully edited', type: 'success' },
        redirect: false,
    });

    const socialUrl = (social: 'website' | 'twitter' | 'facebook', profile: string): string => {
        if (!profile || profile.includes('http')) return profile;

        switch (social) {
            case 'website':
                return `https://${profile}`;
            case 'twitter':
                return `https://twitter.com/${profile}`;
            case 'facebook':
                return `https://facebook.com/${profile}`;
            default:
                return profile;
        }
    };
    return !isLoading && !error ? (
        <>
            <ContactDetail
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
                isEditMode={true}
            />
            <div>
                <div
                    className="flex items-center cursor-pointer pb-2"
                    onClick={() => {
                        goBack();
                    }}
                >
                    <img
                        sizes="24"
                        src="/images/icons/back.svg"
                        alt="edit"
                        className="cursor-pointer"
                    />
                    <span className="body-2">{t('contacts.back_to_contact')}</span>
                </div>
            </div>
            <Row className="contact-information mt-2">
                <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <div className="basic-information pr-2">
                        <div className="flex">
                            <div className="avatar">
                                <Avatar
                                    className="flex justify-center"
                                    src={
                                        contact?.avatarUrl
                                            ? getPublicMediaUrl(contact.avatarUrl)
                                            : '/images/avatar-placeholer.png'
                                    }
                                />
                            </div>

                            <div className="ml-8 overflow-hidden">
                                <div className="full-name break-words">{contact?.fullName}</div>
                                <div className="email-contact mt-1 break-words">
                                    <a href={`mailto:${contact?.email}`}>{contact?.email}</a>
                                </div>
                                <Space className="contact-social mt-5">
                                    {contact?.website ? (
                                        <a
                                            href={socialUrl('website', contact.website)}
                                            target="_blank"
                                        >
                                            <WebsiteIcon />
                                        </a>
                                    ) : (
                                        <WebsiteIcon />
                                    )}
                                    {contact?.twitter ? (
                                        <a
                                            href={socialUrl('twitter', contact.twitter)}
                                            target="_blank"
                                        >
                                            <TwitterIcon />
                                        </a>
                                    ) : (
                                        <TwitterIcon />
                                    )}
                                    {contact?.facebook ? (
                                        <a
                                            href={socialUrl('facebook', contact.facebook)}
                                            target="_blank"
                                        >
                                            <FacebookIcon />
                                        </a>
                                    ) : (
                                        <FacebookIcon />
                                    )}
                                </Space>
                            </div>
                        </div>
                        {(permissions || []).includes(PERMISSIONS.WL_CONTACT_EDIT) ? (
                            <Button
                                type="primary"
                                htmlType="button"
                                onClick={() => {
                                    editShow(id);
                                }}
                                className="edit-btn edit-profile"
                            >
                                {t('contacts.editProfile')}
                            </Button>
                        ) : null}
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                    <div className="about-contact relative xl:pl-12 mt-3 xl:mt-0">
                        <div className="title">About Contact</div>
                        <div className="absolute divider-line left-0 hidden xl:block"></div>
                        <Row gutter={8}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <div className="break-words h-16">
                                    {renderField(
                                        t('contacts.fields.firstName.label'),
                                        contact?.firstName,
                                    )}
                                </div>

                                <div className="break-words">
                                    {renderField(
                                        t('contacts.fields.phoneNumber.label'),
                                        contact?.phoneNumber,
                                        true,
                                    )}
                                </div>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <div className="break-words h-16">
                                    {renderField(
                                        t('contacts.fields.lastName.label'),
                                        contact?.lastName,
                                    )}
                                </div>
                                <div className="break-words">
                                    {renderField(
                                        t('contacts.fields.website.label'),
                                        contact?.website,
                                        false,
                                        true,
                                    )}
                                </div>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <div className=" h-16 break-words invisible-wrap">
                                    <div className="invisible">invisible</div>
                                    <div className="invisible mt-1">invisible</div>
                                </div>
                                <div className="break-words">
                                    {renderField(
                                        t('contacts.fields.company.label'),
                                        contact?.company,
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="contact-note">{id && <ContactNoteList contactId={id} />}</div>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p className="list-title">{t('contacts.quotes', { ns: 'contact' })}</p>
                    {id && <ContactQuoteList contactId={id} />}
                </Col>

                {isTenantUser && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <p className="list-title">{t('contacts.orders', { ns: 'contact' })}</p>
                        {id && <ContactOrderList contactId={id} />}
                    </Col>
                )}

                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="pb-4">
                    <p className="list-title">{t('contacts.invoices', { ns: 'contact' })}</p>
                    {id && <ContactInvoiceList contactId={id} />}
                </Col>
            </Row>
        </>
    ) : (
        <Spin spinning={true}></Spin>
    );
};
