import './style.scss';

import { PAGE_SIZE } from '@constants/index.constant';
import { MQTT_TOPIC } from '@constants/mqtt-topic';
import { FileTypeEnum } from '@enums/file-type.enum';
import { IHelpdesk } from '@interfaces/helpdesk';
import { BaseRecord, useCreate, useTable } from '@refinedev/core';
import { toLocaleDateTime } from '@utils/date';
import { getPublicMediaUrl } from '@utils/resource';
import { useSubscription } from '@zodinet-pos/core-ui';
import { Avatar, Image } from 'antd';
import _, { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { FooterTicketMessageDetailComponent } from './footer';
import { HeaderTicketMessageComponent } from './header';

interface ITicketProps {
    ticket?: IHelpdesk.Ticket;
}

export const TicketMessageDetailComponent: React.FC<ITicketProps> = ({ ticket }) => {
    const { message } = useSubscription.default(MQTT_TOPIC.TICKET_MESSAGE);

    const [messageList, setMessageList] = useState<IHelpdesk.TicketMessage[]>([]);
    const { mutate } = useCreate<BaseRecord>();
    const { id } = useParams();

    const messages = useTable<IHelpdesk.TicketMessage>({
        resource: `v1/ticket-messages`,
        queryOptions: {
            enabled: false,
        },
        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc',
                },
            ],
        },
        pagination: {
            pageSize: PAGE_SIZE,
            current: 1,
        },
        filters: {
            initial: [
                {
                    field: 'ticketId',
                    value: id,
                    operator: 'eq',
                },
                {
                    field: 'toDate',
                    value: new Date(),
                    operator: 'eq',
                },
            ],
        },
    });

    useEffect(() => {
        messages.tableQueryResult.refetch();
    }, []);

    useEffect(() => {
        if (ticket?.id || id) {
            setMessageList([]);
            messages.setCurrent(1);
            messages.setFilters([
                {
                    field: 'ticketId',
                    value: ticket?.id || id,
                    operator: 'eq',
                },
                {
                    field: 'toDate',
                    value: new Date(),
                    operator: 'eq',
                },
            ]);
        }
    }, [ticket?.id, id]);

    useEffect(() => {
        if (id) {
            messages.tableQueryResult.refetch();
        }
    }, [messages.current, messages.filters]);

    useEffect(() => {
        if (message?.message) {
            const newMessageList = [...messageList];
            const newMessage = JSON.parse(message.message.toString());
            if (newMessage.ticketId === ticket?.id || newMessage.ticketId === id) {
                newMessage.content = detectLink(newMessage.content);
                newMessageList.push(newMessage);
                setMessageList(newMessageList);
            }

            const element = document.getElementById('messageContainer');
            if (element) {
                element.scrollTo({ top: 0 });
            }
        }
    }, [message]);

    useEffect(() => {
        if (messages) {
            const messageListCloneDeep = cloneDeep(messages.tableQueryResult?.data?.data);
            messageListCloneDeep?.map((item) => {
                item.content = detectLink(item.content);
                return item;
            });

            if (messages.current > 1) {
                const newTicketList = [
                    ...(messageListCloneDeep?.reverse() || []),
                    ...(messageList || []),
                ];
                const uniqueList = _.uniqBy(newTicketList, 'id');

                setMessageList(uniqueList);
            } else {
                const messageList = messageListCloneDeep?.reverse() as IHelpdesk.TicketMessage[];
                setMessageList(messageList);
            }
        }
    }, [messages.tableQueryResult?.data]);

    const loadMore = () => {
        messages.setFilters([
            {
                field: 'toDate',
                value: messageList[0].createdAt,
                operator: 'eq',
            },
        ]);
        messages.setCurrent(messages.current + 1);
    };

    const handleSendMessage = (params: IHelpdesk.ICreateMessageRequest): boolean => {
        params = { ticketId: ticket?.id || id, ...params };

        mutate({
            resource: 'v1/ticket-messages',
            values: params,
            successNotification: false,
        });
        return true;
    };

    const detectLink = (text: string): string => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        if (text) {
            return text.replace(urlRegex, function (url) {
                return '<a href="' + url + '" target=_blank">' + url + '</a>';
            });
        } else return '';
    };

    return (
        <div className="conversation-detail-container w-full h-full flex flex-col justify-between">
            <HeaderTicketMessageComponent ticket={ticket} />

            <div id="messageContainer" className="ticket-message-container conversation-body">
                <InfiniteScroll
                    dataLength={messageList?.length ?? 0}
                    next={loadMore}
                    hasMore={messages?.tableQueryResult?.data?.data?.length === PAGE_SIZE}
                    loader={null}
                    scrollableTarget="messageContainer"
                    inverse={true}
                    initialScrollY={0}
                >
                    {messageList && messageList.length
                        ? messageList.map((item, index) => (
                              <div key={item.id} className={`message-box flex cursor-pointer`}>
                                  <div className="conversation-card-avatar">
                                      <Avatar
                                          size="large"
                                          icon={
                                              item?.senderAvatar ? (
                                                  <Image
                                                      className="customer-cell-avatar"
                                                      src={getPublicMediaUrl(item?.senderAvatar)}
                                                      preview={false}
                                                  />
                                              ) : (
                                                  <Avatar
                                                      style={{
                                                          verticalAlign: 'middle',
                                                      }}
                                                      size="large"
                                                  >
                                                      {item?.senderName.charAt(0)}
                                                  </Avatar>
                                              )
                                          }
                                      />
                                      <div className="tail-item"></div>
                                  </div>
                                  <div className="conversation-card-content flex flex-col items-start ml-3 mr-3">
                                      <div className="header-conversation-content flex justify-between w-full">
                                          <p className="name-card">{item?.senderName}</p>
                                          <p className="time-card">
                                              {toLocaleDateTime(new Date(item.createdAt))}
                                          </p>
                                      </div>

                                      <div className="message-container w-full">
                                          {item?.content && (
                                              //   <p className="message-card w-fit max-w-full">
                                              //       {item?.content}
                                              //   </p>
                                              <div
                                                  className="message-card w-fit max-w-full"
                                                  dangerouslySetInnerHTML={{
                                                      __html: item?.content,
                                                  }}
                                              ></div>
                                          )}
                                          {item?.attachment?.length && (
                                              <div
                                                  className={`file-container ${
                                                      item?.content && 'mt-9'
                                                  } `}
                                              >
                                                  {item.attachment.map((attachment) =>
                                                      attachment?.type === FileTypeEnum.DOCUMENT ? (
                                                          <div
                                                              key={uuidv4()}
                                                              className={`col-span-1`}
                                                          >
                                                              <a
                                                                  className="document-box flex items-center w-fit "
                                                                  href={getPublicMediaUrl(
                                                                      attachment.url,
                                                                  )}
                                                              >
                                                                  <Image
                                                                      className="customer-cell-avatar"
                                                                      src={
                                                                          '/images/icons/file-icon.svg'
                                                                      }
                                                                      width={20}
                                                                      height={20}
                                                                      preview={false}
                                                                  />
                                                                  <div className="p-3">
                                                                      <span
                                                                          key={uuidv4()}
                                                                          className="document-name"
                                                                      >
                                                                          {attachment.name}
                                                                      </span>
                                                                  </div>
                                                              </a>
                                                          </div>
                                                      ) : (
                                                          <Image
                                                              key={uuidv4()}
                                                              className="img-container flex justify-center"
                                                              src={getPublicMediaUrl(
                                                                  attachment.url,
                                                              )}
                                                              preview={false}
                                                          />
                                                      ),
                                                  )}
                                              </div>
                                          )}
                                      </div>
                                  </div>
                              </div>
                          ))
                        : null}
                    {messageList?.length ? <div className="last-tail-item px-5"></div> : null}
                </InfiniteScroll>
            </div>

            <FooterTicketMessageDetailComponent
                handleSendMessage={handleSendMessage}
                ticketId={ticket?.id}
            />
        </div>
    );
};
