import './styles.scss';

import { UploadOutlined } from '@ant-design/icons';
import { getValueFromEvent, useForm } from '@refinedev/antd';
import { HttpError, useApiUrl, useDelete } from '@refinedev/core';
import { Button, Col, Form, Modal, Row, Space, Spin, Upload, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { Store } from 'rc-field-form/lib/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IContact } from '../../interfaces/contact';
import { getPublicMediaUrl } from '../../utils/resource';
import { TextEditor } from '../modules/editor';

export type FormMode = 'add' | 'edit';

type ContactNoteFormProps = {
    data?: IContact.IContactNote;
    contactId: string;
    onCancelEdit?: () => void;
    onUpdate?: (data: IContact.IContactNote) => void;
};

interface INoteFormValues {
    contactId: string;
    content: string;
    attachmentUrls?: string[];
    attachments?: {
        id?: string;
        uid?: string;
        name?: string;
        url?: string;
        type?: string;
        size?: number;
        percent?: number;
        status?: string;
        response?: { url: string };
    }[];
}

export const ContactNoteItemForm: React.FC<ContactNoteFormProps> = ({
    data,
    contactId,
    onCancelEdit,
    onUpdate,
}) => {
    const apiUrl = useApiUrl();
    const isEditMode = data && data.id;
    const { t } = useTranslation(['common', 'contact']);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [isFormValid, setIsFormValid] = useState<boolean>(isEditMode ? true : false);
    const { formProps, form, formLoading } = useForm<
        IContact.IContactNote,
        HttpError,
        INoteFormValues
    >({
        resource: 'v1/contact-notes',
        action: isEditMode ? 'edit' : 'create',
        redirect: false,
        ...{ id: data?.id },
        onMutationSuccess: (data: any) => {
            if (!isEditMode) {
                form.resetFields();
                onFieldsChange();
                onUpdate && onUpdate(data);
                return;
            }
            const note: IContact.IContactNote = {
                ...data,
                content: form.getFieldValue('content'),
                attachments: form.getFieldValue('attachments'),
            };
            onUpdate && onUpdate(note);
        },
        successNotification: {
            message: isEditMode ? 'Successfully updated' : 'Successfully created',
            type: 'success',
        },
        errorNotification: {
            message: isEditMode ? 'Error updating note' : 'Error creating note',
            type: 'error',
        },
    });

    const initialValues: Store = { ...formProps.initialValues, contactId: contactId };

    (initialValues?.attachments || []).forEach(
        (item: IContact.IContactAttachment) => (item.url = getPublicMediaUrl(item.url)),
    );
    const { mutate: deleteAttachment, isLoading: isLoadingRemoveAttachment } = useDelete();

    const onFieldsChange = () => {
        setIsFormValid(form.getFieldValue('content')?.length > 0);
    };

    // set AttachmentUrls
    useEffect(() => {
        if (isEditMode) {
            fileList
                ?.filter((item) => item.response?.url && item.status === 'done')
                .forEach((item: UploadFile & { id?: string }) => {
                    item.id = item.response?.id;
                    item.url = getPublicMediaUrl(item.response?.url);
                });
        } else {
            const attachmentUrls = fileList
                ?.filter((item) => item.response?.url && item.status === 'done')
                .map((item) => item.response?.url as string);

            form.setFieldsValue({
                attachmentUrls: attachmentUrls.length > 0 ? attachmentUrls : undefined,
            });
        }
    }, [fileList]);

    const onCancel = () => {
        onCancelEdit && onCancelEdit();
    };

    const onRemoveFile = (file: UploadFile & { id?: string }) => {
        const { confirm } = Modal;
        if (!file.id) {
            return true;
        }

        return new Promise<boolean>((resolve, reject) => {
            confirm({
                title: 'Are you sure you want to Delete ?',

                onOk: () => {
                    deleteAttachment({
                        resource: 'v1/contact-notes/attachment',
                        id: file?.id as string,
                        successNotification: () => {
                            resolve(true);
                            return {
                                message: 'Successfully deleted',
                                type: 'success',
                            };
                        },
                        errorNotification: () => {
                            reject(true);
                            return {
                                message: 'Error delete note attachment',
                                type: 'error',
                            };
                        },
                    });
                },
                onCancel: () => {
                    reject(true);
                },
            });
        });
    };

    const onFileListChange = (info: UploadChangeParam) => {
        setFileList(info.fileList);
    };
    const token = localStorage.getItem('access_token');

    return (
        <Spin spinning={formLoading || isLoadingRemoveAttachment}>
            <Form {...formProps} initialValues={initialValues} onFieldsChange={onFieldsChange}>
                <Form.Item name="contactId" hidden={true}></Form.Item>
                <Form.Item name="attachmentUrls" hidden={true}></Form.Item>
                <Form.Item
                    name="content"
                    rules={[
                        {
                            required: true,
                            message: 'Content is required',
                        },
                    ]}
                >
                    <TextEditor height="150px" menubar={false} />
                </Form.Item>
                <Row justify="space-between">
                    <Col className="upload-attachment-wrapper" flex="auto">
                        <Form.Item
                            name="attachments"
                            isListField={false}
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                        >
                            <Upload
                                className="upload-list-inline"
                                name="file"
                                action={
                                    isEditMode
                                        ? `${apiUrl}/v1/contact-notes/${data.id}/upload-attachment`
                                        : `${apiUrl}/v1/resource/upload-temporary-attachment`
                                }
                                headers={{ Authorization: `Bearer ${token}` }}
                                listType="text"
                                multiple
                                onChange={onFileListChange}
                                onRemove={onRemoveFile}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    disabled={formLoading || fileList.length >= 3}
                                >
                                    {t('upload')}
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Space>
                            <Button htmlType="button" onClick={onCancel}>
                                {t('cancel')}
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                // onClick={onSaveNote}
                                disabled={!isFormValid}
                                className="btn-submit-note"
                            >
                                {t('save')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};
