import './styles.scss';

import { CheckIconOutline } from '@components/layout/icons';
import { APPLICATIONS } from '@constants/index.constant';
import { NApplication } from '@interfaces/application';
import { useOne } from '@refinedev/core';
import { POS_PLAN_ID } from '@zodinet-pos/core-ui';
import { Button, Form, Input } from 'antd';
import { PLAN_FEATURE_CONTENTS } from 'pages/extra-application-plan/constant';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrencyType } from '../../enums/currency-type.enum';
import { SubscriptionOrderTypeEnum } from '../../enums/subscription-order-type.enum';
import { NRegistration } from '../../interfaces/registration';
import { formatPriceBaseOnCurrency } from '../../utils/resource';
import { CustomModal } from '../modules/modal-custom';
import { IForm } from '.';

type IDrawerRegistrationForm = {
    type: SubscriptionOrderTypeEnum;
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    handlePayment: (values: IForm, totalPayment: number) => void;
    remainingMonths: number;
    registration: NRegistration.IRegistrationForDashboard;
    paymentFormula: (params: {
        price: number;
        expiredTime: number;
        qtyTable: number;
        isFnb?: boolean;
    }) => number;
};

export const AddOnPlanModal: React.FC<IDrawerRegistrationForm> = ({
    type,
    isOpen,
    isLoading,
    registration,
    remainingMonths,
    onClose,
    handlePayment,
    paymentFormula,
}) => {
    const { t } = useTranslation(['common']);
    const [form] = Form.useForm();

    const { data: extraAppPlans } = useOne<NApplication.IExtraAppPlanData>({
        resource: 'v1/application/extra-app-plans',
        id: APPLICATIONS.POS.id,
        queryOptions: {
            enabled: true,
        },
    });

    const addOnPlan = extraAppPlans?.data?.plans?.find((p) => p.id === POS_PLAN_ID.STANDARD_FNB);
    const addOnPlanContents = addOnPlan?.id ? PLAN_FEATURE_CONTENTS?.[addOnPlan.id] : [];

    const renderTotalPayment = useMemo(() => {
        const _expiredTime = remainingMonths || 0;
        return paymentFormula({
            price: addOnPlan?.fnbPricing || 0,
            expiredTime: _expiredTime,
            qtyTable: 0,
            isFnb: true,
        });
    }, [remainingMonths, addOnPlan?.id]);

    return (
        <CustomModal
            open={isOpen}
            footer={false}
            closable={!isLoading}
            onCancel={onClose}
            maskClosable={false}
            width={550}
        >
            <div>
                <p className="text-2xl italic">
                    {t(`plan_form.fields.service_package`)}{' '}
                    <span className="font-bold">
                        {t(
                            `plan_form.plan_type.${registration.extraAppPlanName.toLowerCase()}`,
                        ).toUpperCase()}{' '}
                    </span>
                </p>
            </div>

            <div className="flex flex-col gap-y-3 my-6">
                <p className="text-base">
                    {t('add_on_plan_description', {
                        fnbPrice: formatPriceBaseOnCurrency(
                            addOnPlan?.fnbPricing || 0,
                            CurrencyType.Vnd,
                        ),
                    })}
                </p>

                {addOnPlanContents.map((content, index) => (
                    <div
                        key={`add_on_plan_feature_content_${index}`}
                        className="flex justify-start items-start gap-x-3"
                    >
                        <span className="mt-1">
                            <CheckIconOutline />
                        </span>
                        <span className="text-base text-black font-normal">{content}</span>
                    </div>
                ))}
            </div>
            <Form
                layout="vertical"
                className="upgrade-modal"
                form={form}
                onFinish={(value) => handlePayment(value, renderTotalPayment)}
            >
                <Form.Item
                    className="mt-2"
                    label={t('plan_form.fields.reference_code')}
                    name={'referenceCode'}
                    initialValue={registration.referenceCode}
                >
                    <Input placeholder={t('plan_form.fields.reference_code_placeholder')} />
                </Form.Item>

                <p className="mb-2">
                    *{t('plan_form.fields.total_price')}:{' '}
                    {formatPriceBaseOnCurrency(renderTotalPayment, CurrencyType.Vnd)}
                </p>

                <Button
                    type="primary"
                    className="w-full flex justify-center"
                    htmlType="submit"
                    loading={isLoading}
                >
                    {t('plan_form.actions.payment_btn')}
                </Button>
            </Form>
        </CustomModal>
    );
};
