import { CustomPagination } from '@components/modules/pagination';
import { useTranslate } from '@refinedev/core';
import { Col, Row, Table } from 'antd';

type UserTabProps = {
    total: number;
    current: number;
    pageSize: number;
    cols: any;
    tableResult: any;
    setCurrent: (v: number) => void;
    setPageSize: (v: number) => void;
};

export const UserTabContent: React.FC<UserTabProps> = ({
    total,
    cols,
    current,
    tableResult,
    pageSize,
    setCurrent,
    setPageSize,
}) => {
    const translate = useTranslate();
    return (
        // Role
        <Row className="users-table-container">
            <Col span={24} className="flex flex-col">
                <div className="users-total-container flex justify-between">
                    <div className="total-users">
                        <span className="all-users">{translate('users.all_users')}</span>
                        <span className="total-number-users ml-2">
                            <span>{total ?? 0}</span>
                            <span className="ml-1">
                                {(total ?? 0) <= 1
                                    ? translate('users.user').toLowerCase()
                                    : translate('users.users').toLowerCase()}
                            </span>
                        </span>
                    </div>
                </div>
                <Table
                    className="users-table"
                    columns={cols}
                    dataSource={tableResult?.data?.data}
                    pagination={false}
                    scroll={{ y: '100%', x: '800px' }}
                    rowKey="id"
                />
            </Col>
            <Col span={24}>
                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableResult?.data?.total}
                        current={current}
                        onChange={(value: any, size: any) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </Col>
        </Row>
    );
};
