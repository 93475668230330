import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@refinedev/antd';
import { Button, Drawer, Grid } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactNoteItemForm } from './note-form';
export const CreateContactNoteButton: React.FC<{ contactId: string }> = ({ contactId }) => {
    const { t } = useTranslation(['contact', 'common']);
    const breakpoint = Grid.useBreakpoint();

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="flex justify-between w-full">
                <div className="title-note">{t('contacts.note')}</div>
                <Button type="primary" onClick={showDrawer} className="btn-add-note">
                    <PlusOutlined />
                    {t('add_new', { ns: 'common' })}
                </Button>
            </div>
            <Drawer
                width={breakpoint.sm ? '700px' : '100%'}
                bodyStyle={{ padding: '12x 16px' }}
                maskClosable={false}
                zIndex={100}
                className="drawer-form-container"
                getContainer={false}
                forceRender={true}
                visible={open}
                onClose={onClose}
            >
                <PageHeader title={t('contacts.createNote')} style={{ padding: 0 }} />
                <div className="mt-2">
                    <ContactNoteItemForm
                        contactId={contactId}
                        onCancelEdit={() => {
                            onClose();
                        }}
                        onUpdate={() => {
                            onClose();
                        }}
                    />
                </div>
            </Drawer>
        </>
    );
};
