import './style.scss';

import { PERMISSIONS } from '@constants/permission';
import { useCreate, useCustom, usePermissions } from '@refinedev/core';
import { Button, Col, Form, InputNumber, Row } from 'antd';
import Response from 'data-access/responses/response';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { WHITE_LABEL_SETTING_RESOURCE } from '../../constants/index.constant';
import { getEnvConfig } from '../../getEnvConfig';
import { WLSetting } from '../../interfaces/white-label-setting';

enum FormFields {
    WL_PARTNER_DISCOUNT_PERCENTAGE = 'wl_partner_discount_percentage',
    MAXIMUM_MARGIN_PERCENTAGE = 'maximum_margin_percentage',
    SERVICE_CHARGE_PERCENTAGE = 'service_charge_percentage',
}

export const WLSettingLayout: React.FC = () => {
    const { t } = useTranslation(['common']);
    const [form] = Form.useForm();
    const { mutate } = useCreate();
    const { data: permissions } = usePermissions<string[]>();
    const apiUrl = getEnvConfig.PORTAL_URL;
    const wlSettingResource = `${apiUrl}/${WHITE_LABEL_SETTING_RESOURCE}`;
    const { data: wlSetting } = useCustom<Response<WLSetting.IWLSetting>>({
        url: wlSettingResource,
        method: 'get',
        config: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
        },
    });

    useEffect(() => {
        const setting = wlSetting?.data?.data;
        if (setting) {
            form.setFieldsValue({
                [FormFields.WL_PARTNER_DISCOUNT_PERCENTAGE]:
                    setting.data[FormFields.WL_PARTNER_DISCOUNT_PERCENTAGE],
                [FormFields.MAXIMUM_MARGIN_PERCENTAGE]:
                    setting.data[FormFields.MAXIMUM_MARGIN_PERCENTAGE],
                [FormFields.SERVICE_CHARGE_PERCENTAGE]:
                    setting.data[FormFields.SERVICE_CHARGE_PERCENTAGE],
            });
        }
    }, [wlSetting?.data]);

    const onSaveSetting = async () => {
        const isValid = await form.validateFields();
        if (isValid) {
            const formValue = form.getFieldsValue();
            const values = {
                data: {
                    [FormFields.WL_PARTNER_DISCOUNT_PERCENTAGE]:
                        formValue[FormFields.WL_PARTNER_DISCOUNT_PERCENTAGE],
                    [FormFields.MAXIMUM_MARGIN_PERCENTAGE]:
                        formValue[FormFields.MAXIMUM_MARGIN_PERCENTAGE],
                    [FormFields.SERVICE_CHARGE_PERCENTAGE]:
                        formValue[FormFields.SERVICE_CHARGE_PERCENTAGE],
                },
            };
            mutate({
                resource: 'v1/wl-settings',
                values: values,
                successNotification: () => {
                    return {
                        message: t('wl_setting.save_setting_successfully'),
                        type: 'success',
                    };
                },
                errorNotification: () => {
                    return {
                        message: t('wl_setting.save_setting_fail'),
                        type: 'error',
                    };
                },
            });
        }
        return null;
    };

    return (
        <div className="wl-setting-container">
            <Row className="wl-setting-header-row py-6">
                <Col
                    className="wl-setting-header-col w-full h-full flex items-center justify-between px-6 pt-6"
                    span={24}
                >
                    <h2 className="tenant-setting-header-title">
                        {t('wl_setting.white_label_setting')}
                    </h2>
                    {(permissions || []).includes(PERMISSIONS.WL_SETTING_CREATE) ? (
                        <Button type="primary" onClick={() => onSaveSetting()}>
                            {t('wl_setting.save_setting')}
                        </Button>
                    ) : null}
                </Col>
            </Row>

            <Form
                layout="vertical"
                form={form}
                initialValues={{ items: [{}] }}
                className="px-6 pt-4 form-payout-setting"
            >
                <Row gutter={16}>
                    <Col span={8} className="form-item-payout-setting">
                        <Form.Item
                            label={t('wl_setting.wl_partner_discount_percentage')}
                            name="wl_partner_discount_percentage"
                            rules={[
                                {
                                    required: true,
                                    message: `${t('wl_setting.value_required')}`,
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100} className="w-3/4" />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="form-item-payout-setting">
                        <Form.Item
                            label={t('wl_setting.maximum_margin_percentage')}
                            name="maximum_margin_percentage"
                            rules={[
                                {
                                    required: true,
                                    message: `${t('wl_setting.value_required')}`,
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100} className="w-3/4" />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="form-item-payout-setting">
                        <Form.Item
                            label={t('wl_setting.service_charge_percentage')}
                            name="service_charge_percentage"
                            rules={[
                                {
                                    required: true,
                                    message: `${t('wl_setting.value_required')}`,
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100} className="w-3/4" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
