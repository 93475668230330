import { useSetLocale } from '@refinedev/core';
import React, { useEffect } from 'react';

import { SubscriptionPlan } from '../../../../components/subscription-plan';

export const SubscriptionPlanIndex: React.FC = () => {
    const changeLang = useSetLocale();

    useEffect(() => {
        changeLang('vi');
    }, []);

    return (
        <>
            <SubscriptionPlan />
        </>
    );
};
