import './styles.scss';

import { useApiUrl } from '@refinedev/core';
import { Button, message } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import Upload from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getPublicMediaUrl } from '../../../utils/resource';

export const AvatarUpload: React.FC<{
    onChange?: (file: UploadFile<any>) => void;
    size?: number;
    width: number;
    height: number;
    quality?: number;
    uploadText: string;
    value?: string;
    isTemporary?: boolean;
    hideUploadBtn?: boolean;
    isCircle?: boolean;
    statusEmitChange?: string;
}> = ({
    onChange,
    size = 5,
    width,
    height,
    quality = 0.8,
    uploadText,
    value,
    isTemporary,
    hideUploadBtn,
    isCircle,
    statusEmitChange,
}) => {
    const { t } = useTranslation('common');

    const [imageUrl, setImageUrl] = useState<string | null>();

    useEffect(() => {
        if (value) {
            setImageUrl(getPublicMediaUrl(value));
        } else {
            setImageUrl(null);
        }
    }, [value]);

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt = file.size / 1024 / 1024 < size;
        if (!isLt) {
            message.error(`Image must smaller than ${size}MB!`);
        }

        if (!(isJpgOrPng && isLt)) {
            return false;
        }

        getBase64(file, (url) => {
            setImageUrl(url);
        });

        return true;
    };

    const changeUpload = (info: any) => {
        if (statusEmitChange) {
            info?.file?.status === statusEmitChange && onChange && onChange(info.file);
        } else {
            onChange && onChange(info.file);
        }
    };

    const apiUrl = useApiUrl();
    const token = localStorage.getItem('access_token');

    return (
        <ImgCrop
            modalTitle={t('common.edit_image')}
            modalOk={t('common.ok')}
            modalCancel={t('common.cancel')}
            minZoom={0.1}
            aspect={width / height}
            quality={quality || 0.8}
        >
            <Upload
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
                action={`${apiUrl}/v1/resource/${
                    isTemporary ? 'upload-temporary-image' : 'upload-image'
                }`}
                headers={{ Authorization: `Bearer ${token}` }}
                onChange={changeUpload}
                maxCount={1}
                className="avatar-upload w-full h-full"
            >
                <div className="w-full h-full">
                    <div className="relative h-full w-full flex flex-col items-center justify-center">
                        {imageUrl && (
                            <img
                                src={imageUrl}
                                alt="img"
                                className={`rounded-xl img w-auto h-full m-auto ${
                                    isCircle ? 'border-circle' : ''
                                }`}
                            />
                        )}
                        {!imageUrl && (
                            <div className="upload">
                                <Button className="placeholder">
                                    <img src="/images/icons/avatar.svg" />
                                </Button>
                            </div>
                        )}
                    </div>
                    {!imageUrl && !hideUploadBtn && (
                        <div className="relative h-full w-full">
                            <Button className="btn-add-photo">
                                <img src="/images/icons/img.svg" />
                                {t('pages.profile.avatar')}
                            </Button>
                        </div>
                    )}
                </div>
            </Upload>
        </ImgCrop>
    );
};
