import './styles.scss';

import { MODULE_ITEMS_ORDER } from '@constants/index.constant';
import { ACTIONS_ORDER } from '@constants/permission';
import { Permission } from '@interfaces/permission';
import { Role } from '@interfaces/role';
import { IUser } from '@interfaces/user';
import { useCreate, useGetIdentity, useList, useTranslate, useUpdate } from '@refinedev/core';
import { Button, Col, Drawer, Form, Input, notification, Space, Tree } from 'antd';
import { MenuEnum } from 'components/layout/sidebar';
import { UserTypeEnum } from 'enums/user-type.enum';
import * as _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

enum UserRoleFormField {
    NAME = 'name',
    PERMISSIONS = 'permissions',
}

interface IProps {
    visible: boolean;
    emitCloseRoleDrawer: () => void;
    emitChangedRole: () => void;
    role: Role.IRole | null;
}

export const CreateRoleDrawer: React.FC<IProps> = ({
    visible,
    emitCloseRoleDrawer,
    emitChangedRole,
    role,
}) => {
    /* State */
    // eslint-disable-next-line prettier/prettier
    const [permissionTreeData, setPermissionTreeData] = useState<Permission.IPermissionTreeData[]>(
        [],
    );
    // permissions
    const [groupPermissions, setGroupPermissions] = useState<Permission.IPermissionGroup[]>([]);
    const [checkedPermissionKeys, setCheckedPermissionKeys] = useState<React.Key[]>([]);
    const [parentKeys, setParentKeys] = useState<React.Key[]>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const { data: user } = useGetIdentity<IUser.IUserDto>();

    /* Hooks */
    const { data: permissions } = useList<Permission.IPermission>({
        resource: 'v1/tenant/permissions',
    });
    //get permissions when edit role
    const { data: rolePermissions, refetch: getRolePermissions } = useList<Permission.IPermission>({
        resource: `v1/roles/permissions/${role?.id}`,
        queryOptions: {
            enabled: false,
        },
    });
    const translate = useTranslate();
    const [form] = Form.useForm();

    //mutate
    const { mutate: createRole, isLoading: isAddingRole } = useCreate();
    const { mutate: updateRole, isLoading: isUpdateRole } = useUpdate();

    const sortModules = (
        treeData: Permission.IPermissionTreeData[],
    ): Permission.IPermissionTreeData[] => {
        if (!treeData?.length) return [];
        //sort modules
        const moduleKeys = Object.keys(MODULE_ITEMS_ORDER) || [];
        const sortedModuleTree = _.sortBy(treeData, (item) => moduleKeys.indexOf(item?.key));

        for (const module of sortedModuleTree) {
            if (!module?.children?.length) {
                continue;
            }

            const menuItemsOrder = MODULE_ITEMS_ORDER[module.key as MenuEnum];
            if (!menuItemsOrder || !Object.keys(menuItemsOrder).length) {
                continue;
            }

            const menuItemKeys = Object.keys(menuItemsOrder) || [];
            let itemsLength = module.children.length;
            module.children = _.sortBy(module.children, (child) =>
                menuItemKeys.indexOf(child.key) !== -1
                    ? menuItemKeys.indexOf(child.key)
                    : itemsLength++,
            );
        }
        //sort groups in each module
        return sortedModuleTree;
    };

    const sortActions = (permissions: Permission.IPermission[]) => {
        if (!permissions?.length) return [];
        const sortedPermissions = _.sortBy(permissions, (p) => ACTIONS_ORDER.indexOf(p.code));
        return sortedPermissions;
    };

    /* Effects */
    useEffect(() => {
        setIsEdit(!!role);
        if (role) {
            form.setFieldsValue({
                [UserRoleFormField.NAME]: role.name,
            });
            getRolePermissions();
        }
    }, [role]);

    useEffect(() => {
        //just filter children key, not use parent permission key
        const permissionIds = (checkedPermissionKeys || []).filter(
            (key) => !parentKeys.some((parentKey) => parentKey === key),
        );
        if (permissionIds?.length) {
            form.setFieldsValue({
                [UserRoleFormField.PERMISSIONS]: permissionIds,
            });
        } else {
            form.setFieldsValue({
                [UserRoleFormField.PERMISSIONS]: [],
            });
        }
    }, [checkedPermissionKeys]);

    useMemo(() => {
        //set check permissions
        const checkedPermissions = (rolePermissions?.data || []).map((item) => item.id);

        if (groupPermissions?.length) {
            let treeData: Permission.IPermissionTreeData[] = [];
            let parentKeys: React.Key[] = [];
            groupPermissions.forEach((group) => {
                // split group to name of application and name of submenu
                const splitGroups = group?.name?.split('_');
                group.permissions = sortActions(group.permissions);
                const permissions: Permission.IPermissionTreeData[] = (group.permissions || []).map(
                    (item) => ({
                        key: item?.id,
                        title: translate(`permissions.${group?.name}_${item?.code}`),
                    }),
                );

                if (splitGroups?.length <= 1) {
                    //like: pos, wl
                    const appKey = group?.name; //add to parent keys list
                    parentKeys.push(appKey);

                    const existApp = treeData.find((app) => app.key === appKey);
                    if (!existApp) {
                        treeData.push({
                            key: group?.groupCode,
                            title: translate(`permissions.${group?.name}`),
                            children: permissions,
                        });
                    } else {
                        existApp.children = [...(existApp?.children || []), ...permissions];
                    }
                } else {
                    //like: wl_service => splitGroups: ['wl', 'service'] => using service as subtree
                    const appKey = splitGroups[0];
                    const moduleKey = splitGroups[1];
                    const appModuleKey = `${appKey}_${moduleKey}`;

                    parentKeys = [...parentKeys, appKey, appModuleKey];

                    let existApp = treeData.find((app) => app.key === appKey);
                    if (!existApp) {
                        //naming wl module to agency module if current user is wl type
                        const _appKey =
                            appKey === 'wl' && user?.userType === UserTypeEnum.WHITELABEL
                                ? 'agency_crm'
                                : appKey;
                        existApp = {
                            key: appKey,
                            title: translate(`permissions.${_appKey}`),
                            children: [],
                        };
                        treeData.push(existApp);
                    }

                    const existSubTree = (existApp?.children || []).find(
                        (sub) => sub.key === appModuleKey,
                    );

                    if (!existSubTree) {
                        existApp.children?.push({
                            key: appModuleKey,
                            title: translate(`permissions.${moduleKey}`),
                            children: permissions,
                        });
                    }
                }
            });

            treeData = sortModules(treeData);

            setParentKeys(parentKeys);
            setPermissionTreeData(treeData);
            setCheckedPermissionKeys(checkedPermissions);
        }
    }, [groupPermissions, rolePermissions?.data]);

    useMemo(() => {
        if (permissions?.data?.length) {
            const mappingPermission: {
                [code: string]: { permissions: Permission.IPermission[]; name: string };
            } = {};

            permissions?.data.forEach((permission: Permission.IPermission) => {
                const group = mappingPermission[permission?.groupCode] || {
                    name: '',
                    permissions: [],
                };
                group.permissions.push(permission);
                group.name = group.name ? group.name : permission?.name;
                mappingPermission[permission?.groupCode] = group;
            });

            const permissionGroups: Permission.IPermissionGroup[] = Object.keys(
                mappingPermission,
            ).map((key) => ({
                groupCode: key,
                permissions: mappingPermission[key]?.permissions || [],
                name: mappingPermission[key]?.name,
            }));
            setGroupPermissions(permissionGroups);
        }
    }, [permissions?.data]);

    /* Methods */
    const onCheckPermission = (checkedKeysValue: React.Key[]) => {
        setCheckedPermissionKeys(checkedKeysValue);
    };

    const addRole = async () => {
        try {
            const formValue = await form.validateFields();

            const permissionIds = formValue[UserRoleFormField?.PERMISSIONS];
            if (permissionIds?.length) {
                const createPermissions: Array<{ code: string; groupCode: string }> = (
                    permissions?.data || []
                )
                    .filter((permission) =>
                        permissionIds.some((id: string) => id === permission.id),
                    )
                    .map((permission) => ({
                        code: permission?.code,
                        groupCode: permission?.groupCode,
                        name: permission?.name,
                    }));

                if (!isEdit) {
                    await createRole({
                        resource: 'v1/roles',
                        values: {
                            name: formValue[UserRoleFormField?.NAME],
                            permissions: createPermissions,
                        } as Role.ICreateRole,
                        successNotification: (data) => {
                            resetForm();
                            emitChangedRole();
                            return {
                                message: translate('users.create_role_successfully'),
                                type: 'success',
                            };
                        },
                        errorNotification: (data) => {
                            return {
                                message: translate('users.create_role_failed'),
                                type: 'error',
                            };
                        },
                    });
                } else {
                    await updateRole({
                        id: role?.id ?? '',
                        resource: `v1/roles`,
                        values: {
                            name: formValue[UserRoleFormField?.NAME],
                            permissions: createPermissions,
                        } as Role.ICreateRole,
                        successNotification: (data) => {
                            resetForm();
                            emitChangedRole();
                            return {
                                message: translate('users.update_role_successfully'),
                                type: 'success',
                            };
                        },
                        errorNotification: (data) => {
                            return {
                                message: translate('users.update_role_failed'),
                                type: 'error',
                            };
                        },
                    });
                }
            } else {
                notification.error({ message: translate('users.permissions_required') });
            }
        } catch (err) {
            console.error(err);
        }
    };

    const closeDrawer = () => {
        resetForm();
        emitCloseRoleDrawer();
    };

    const resetForm = () => {
        form.resetFields();
        setCheckedPermissionKeys([]);
    };

    return (
        <>
            {/* Create role drawer */}
            <Drawer
                className="drawer-form-container add-role-drawer-container"
                title={
                    <span className="text-lg">
                        {isEdit ? translate('users.edit_role') : translate('users.add_role')}
                    </span>
                }
                closable={true}
                onClose={closeDrawer}
                visible={visible}
                extra={
                    <Space>
                        <Button
                            icon={false}
                            className="save-role-button"
                            type="primary"
                            onClick={addRole}
                            loading={isAddingRole || isUpdateRole}
                        >
                            <span>{translate('users.save')}</span>
                        </Button>
                    </Space>
                }
            >
                <Form form={form} layout="vertical" className="add-role-form">
                    <Col span={24} className="add-role-form-col">
                        <Form.Item
                            className="add-role-form-item"
                            name={UserRoleFormField.NAME}
                            label={<span>{translate('users.role_name')}:</span>}
                            required
                            rules={[
                                { required: true, message: translate('users.role_name_required') },
                            ]}
                        >
                            <Input placeholder={translate('users.role_name_placeholder')} />
                        </Form.Item>

                        <Form.Item
                            className="add-role-form-item"
                            name={UserRoleFormField.PERMISSIONS}
                            label={<span>{translate('users.permissions')}:</span>}
                            rules={[
                                {
                                    validator: () => {
                                        if (checkedPermissionKeys?.length) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject();
                                    },
                                    message: translate('users.permissions_required'),
                                },
                            ]}
                            required
                        >
                            {/* <Input placeholder={translate('users.role_name_placeholder')} /> */}
                            <Tree
                                checkable
                                onCheck={(keys) => onCheckPermission(keys as React.Key[])}
                                checkedKeys={checkedPermissionKeys}
                                treeData={permissionTreeData}
                            />
                        </Form.Item>
                    </Col>
                </Form>
            </Drawer>
        </>
    );
};
