import './styles.scss';

import { SearchOutlined } from '@ant-design/icons';
import { Input, InputProps } from 'antd';
import debounce from 'lodash.debounce';
import { ChangeEvent } from 'react';

export const CustomSearch: React.FC<InputProps> = (props) => {
    const { value, onChange, className, placeholder, ...res } = props;

    const filterName = (event: ChangeEvent<HTMLInputElement>) => {
        const debouncedFilter = debounce(() => {
            onChange && onChange(event);
        }, 500);

        debouncedFilter();
    };

    return (
        <>
            <Input
                value={value}
                onChange={(event) => {
                    filterName(event);
                }}
                className={`custom-search ${className}`}
                prefix={<SearchOutlined />}
                placeholder={placeholder}
                {...res}
            ></Input>
        </>
    );
};
