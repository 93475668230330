import './styles.scss';

import { useModal } from '@refinedev/antd';
import { useCreate } from '@refinedev/core';
import Response from '@responses/response';
import { Button, Form, Input, Modal, notification } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IOtpPayload {
    email: string;
    id: string;
    resourceName: string;
}

interface IOtpConfirmProps {
    visible: boolean;
    isSendingOtp: boolean;
    confirmData: IOtpPayload;
    title: string;
    setVisible: (visible: boolean) => void;
    onClose?: (isVerified: boolean) => void;
    onResend: () => void;
}

interface IOtpForm {
    code: string;
}

export const OtpConfirm: React.FC<IOtpConfirmProps> = ({
    visible,
    isSendingOtp,
    confirmData,
    setVisible,
    onClose,
    onResend,
    title,
}) => {
    const [isResend, setIsResend] = useState<boolean>(false);

    const { t: translate } = useTranslation('common');
    const { modalProps, close, show } = useModal();

    const [form] = Form.useForm<IOtpForm>();
    const { mutate: verify, isLoading: isVerifying } = useCreate<Response<boolean>>();

    useMemo(() => {
        if (visible) {
            show();
        }
    }, [visible]);

    const onSubmit = async () => {
        try {
            const isValid = await form.validateFields();
            if (isValid) {
                const values = form.getFieldsValue();
                verify(
                    {
                        resource: 'v1/otp/verify',
                        values: {
                            code: values.code,
                            ...confirmData,
                        },
                        errorNotification: false,
                        successNotification: false,
                    },
                    {
                        onSuccess: (res) => {
                            if (res?.data?.data) {
                                setVisible(false);
                                close();
                                onClose && onClose(res?.data?.data);
                            } else {
                                notification.error({
                                    message: translate('otp.errors.otp_expired_or_invalid'),
                                    type: 'error',
                                });
                                setIsResend(true);
                            }
                        },
                        onError: (err) => {
                            notification.error({
                                message: translate('otp.errors.otp_expired_or_invalid'),
                                type: 'error',
                            });
                            setIsResend(true);
                        },
                    },
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Modal
                {...modalProps}
                className="otp-confirm-container"
                title={title ? <span>{title}</span> : ''}
                onOk={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
                confirmLoading={isVerifying}
                onCancel={() => {
                    setVisible(false);
                    close();
                    // some callback after modal close
                    onClose && onClose(false);
                }}
                okText={translate('buttons.otp_confirm')}
                cancelText={translate('buttons.cancel')}
                maskClosable={false}
                closable={false}
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: translate('otp.title', { email: confirmData.email }),
                    }}
                ></span>
                <Form form={form} layout="vertical" name="form-otp" className="form-otp mt-4">
                    <Form.Item
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: translate('otp.errors.otp_required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>

                {isResend ? (
                    <Button
                        type="link"
                        className="resend-otp-button"
                        loading={isSendingOtp}
                        onClick={(e) => {
                            e.preventDefault();
                            onResend();
                            setIsResend(false);
                        }}
                    >
                        {translate('otp.resend')}
                    </Button>
                ) : null}
            </Modal>
        </>
    );
};
