export const getNaturalImageSize = async (
    file: File,
): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const { naturalWidth: width, naturalHeight: height } = img;
            resolve({ width, height });
        };

        img.onerror = () => {
            reject(null);
        };

        img.src = URL.createObjectURL(file);
    });
};
