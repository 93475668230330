import './styles.scss';

import { HttpError, useList } from '@refinedev/core';
import { Col, Form, FormProps, Input, Row, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useTranslation } from 'react-i18next';

import { NCategory } from '../../../../interfaces/category';
import { ITenant } from '../../../../interfaces/tenant';
import { maskPhone } from '../../../../utils/string';

export type FormMode = 'show' | 'add' | 'edit';
type PartnerDetailFormProps = {
    formProps: FormProps;
    mode: FormMode;
    onCancelEdit?: () => void;
    onChangeMode?: (mode: FormMode) => void;
    isCreate?: boolean;
};
export const StoreDetailForm: React.FC<PartnerDetailFormProps> = ({ formProps, mode }) => {
    const { t } = useTranslation(['partner', 'common']);

    const initialValues: ITenant.ITenantInfor = {
        ...formProps.initialValues,
    } as ITenant.ITenantInfor;

    const { data: categories, isLoading } = useList<NCategory.ICategory, HttpError>({
        resource: `v1/bo/category`,
        dataProviderName: 'marketplace',
    });

    const renderFormItem = (isReadonly: boolean, formItem: JSX.Element, showItem: JSX.Element) => {
        if (isReadonly) {
            return showItem;
        }
        return formItem;
    };

    return (
        <Form
            className={`partner-detail ${mode === 'show' ? 'show-mode' : ''}`}
            {...formProps}
            onFinish={async (values) => {
                if (values) formProps.onFinish && formProps.onFinish(values);
            }}
            layout="vertical"
            initialValues={{
                ...initialValues,
                phone: maskPhone(initialValues?.phone || ''),
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    <Form.Item
                        className="toggle-button"
                        label={t('partners.fields.host.label')}
                        name="host"
                    >
                        <span>{initialValues?.host}</span>
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.name.label')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: `${t('partners.fields.name.required')}`,
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <Input
                                placeholder={t('partners.fields.name.placeholder')}
                                maxLength={100}
                            />,
                            <span>{initialValues?.name}</span>,
                        )}
                    </Form.Item>

                    <Form.Item label={t('partners.fields.email.label')} name="email">
                        <span>{initialValues?.email}</span>
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.phoneNumber.label')}
                        name="phone"
                        rules={[
                            {
                                pattern: /\(\d{3}\)-\d{3}-\d{4}/,
                                message: t('partners.fields.phoneNumber.required'),
                            },
                            {
                                required: true,
                                message: t('partners.fields.phoneNumber.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <MaskedInput
                                mask={
                                    //  https://imask.js.org/guide.html#masked-pattern
                                    '(000)-000-0000'
                                }
                            />,
                            <span>{maskPhone(initialValues?.phone || '')}</span>,
                        )}
                    </Form.Item>

                    <Form.Item name="categoryIds" label={t('partners.fields.category.label')}>
                        <Select
                            placeholder={t('partners.fields.category.label')}
                            showArrow
                            suffixIcon={<img src="/images/icons/arrow-down-icon.svg" />}
                            mode="multiple"
                        >
                            {!isLoading &&
                                categories?.data?.map((item: NCategory.ICategory) => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
