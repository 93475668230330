import { ModuleItemModal } from '@components/subscription-plan/module-item-modal';
import { ModulesBanner } from '@components/subscription-plan/modules-banner';
import { PERMISSIONS } from '@constants/permission';
import { IMarketplace } from '@interfaces/marketplace';
import {
    HttpError,
    useApiUrl,
    useCustom,
    useCustomMutation,
    useGetIdentity,
    useList,
    usePermissions,
} from '@refinedev/core';
import { checkCountTableQty, isFreePlan as checkFreePlan } from '@utils/plan';
import { BusinessTypeEnum, POS_PLAN_ID } from '@zodinet-pos/core-ui';
import {
    Button,
    Col,
    Divider,
    notification,
    Progress,
    Row,
    Spin,
    Tabs,
    TabsProps,
    Tag,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    APPLICATIONS,
    MESSAGE_CONTACT_COLLABORATOR_TO_ADD_ON,
    MESSAGE_CONTACT_COLLABORATOR_TO_RENEW,
    MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE,
    TIMEZONE_DEFAULT,
} from '../../constants/index.constant';
import Response from '../../data-access/responses/response';
import { CurrencyType } from '../../enums/currency-type.enum';
import { SubscriptionOrderTypeEnum } from '../../enums/subscription-order-type.enum';
import { NApplication } from '../../interfaces/application';
import { NRegistration } from '../../interfaces/registration';
import { NSubscriptionOrder } from '../../interfaces/subscription-order';
import { IUser } from '../../interfaces/user';
import { IntroduceIndex } from '../../pages/dashboard/introduce';
import { NotificationPaymentModal } from '../../pages/extra-application-plan/notification-payment-modal';
import { NotificationInformationModal } from '../../pages/extra-application-plan/notification-payment-modal/notification-information';
import { QRModal } from '../../pages/extra-application-plan/qr-modal';
import { dayjsDateDiff, getLocalTime } from '../../utils/date';
import { formatPriceBaseOnCurrency, validPermission } from '../../utils/resource';
import { SubscriptionOrder } from '../subscription-order';
import { AddOnPlanModal } from './add-on-plan-modal';
import { UpgradeLicenseModal } from './upgrade-license-modal';
import { UpgradeModal } from './upgrade-modal';

const LIMIT_PRINTER_QTY = 4;
const LIMIT_CONNECT_DEVICE_QTY = 4;
const APPLICATION_RESOURCE_URL = 'v1/application';

export type IForm = { quantity?: number; referenceCode: string; extraAppPlanId?: string };

enum TabEnum {
    PAYGATE_TAB = 'paygate',
    TAX_TAB = 'tax',
    PLAN_TAB = 'plan',
}

export const SubscriptionPlan: React.FC = () => {
    const { t, i18n } = useTranslation('common');
    const apiUrl = useApiUrl();
    const { data: permissions } = usePermissions<string[]>();
    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();

    const [qrUrl, setQrUrl] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [subTitle, setSubTitle] = useState<string>();
    const [isRefetch, setIsRefetch] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalPayment, setTotalPayment] = useState<number>(0);
    const [isOpenQrModal, setIsOpenQrModal] = useState<boolean>(false);
    const [typeModal, setTypeModal] = useState<SubscriptionOrderTypeEnum>();
    const [subscriptionOrderId, setSubscriptionOrderId] = useState<string>();
    const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState<boolean>(false);
    const [registration, setRegistration] = useState<NRegistration.IRegistrationForDashboard>();

    const isFreePlan = registration?.extraAppPlanId
        ? checkFreePlan(registration.extraAppPlanId)
        : false;

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedApplication, setSelectedApplication] = useState<IMarketplace.IApplication>();
    const [applications, setApplications] = useState<IMarketplace.IApplication[]>([]);
    const [notificationFirstBuying, setNotification] = useState<string>();

    const isTenantOwner = userIdentity?.isTenantOwner;

    const isCountByTableQty = checkCountTableQty(registration?.businessType);
    const businessType = registration?.businessType || BusinessTypeEnum.NET;

    const isStandardPlan = [POS_PLAN_ID.STANDARD, POS_PLAN_ID.STANDARD_FNB].includes(
        registration?.extraAppPlanId,
    );
    const {
        data,
        refetch: refetchDetail,
        isLoading: detailLoading,
    } = useCustom<Response<NRegistration.IRegistrationForDashboard>, HttpError>({
        url: `${apiUrl}/v1/registration/detail`,
        method: 'get',
        config: {},
        queryOptions: {
            enabled: isTenantOwner,
        },
    });

    const { mutate } = useCustomMutation<Response<NApplication.IPlanResponse>>();

    const { data: listApplications } = useList<IMarketplace.IApplication>({
        resource: `${APPLICATION_RESOURCE_URL}/available-module`,
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'includeUnpublish',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
    });

    useEffect(() => {
        if (data?.data?.data) {
            setRegistration(data.data.data);
        }
    }, [data]);

    useEffect(() => {
        if (listApplications?.data) {
            setApplications(listApplications.data);
        }
    }, [listApplications]);

    const remainingMonths: number = useMemo(() => {
        if (!registration) return 0;

        return dayjsDateDiff(
            dayjs(registration.expiredDate),
            dayjs(),
            registration.isTrial ? registration.frequency : 'M',
        );
    }, [registration]);

    const handlePayment = (values: IForm, totalPayment: number, isUsingAddOn?: boolean) => {
        const _values: IForm & { timezone: string } = { ...values, timezone: TIMEZONE_DEFAULT };

        setIsLoading(true);

        let action = null;

        switch (typeModal) {
            case SubscriptionOrderTypeEnum.RENEW_LICENSE:
                action = 'renew-license';
                if (isUsingAddOn) {
                    action = 'add-on-and-renew-plan';
                }
                break;
            case SubscriptionOrderTypeEnum.UPGRADE_TABLE:
                action = 'upgrade-table';
                break;
            case SubscriptionOrderTypeEnum.UPGRADE_LICENSE: {
                action = 'upgrade-license';
                _values.extraAppPlanId = values?.extraAppPlanId;
                break;
            }
            case SubscriptionOrderTypeEnum.ADD_ON_PLAN: {
                action = 'add-on-plan';
                _values.extraAppPlanId = POS_PLAN_ID.STANDARD_FNB;
                break;
            }
        }

        mutate(
            {
                url: `${apiUrl}/v1/registration/${action}/${registration?.id}`,
                method: 'put',
                values: { ..._values },
                config: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                    },
                },

                errorNotification: (error) => {
                    setIsLoading(false);

                    const code = error?.response?.data?.error;

                    if (i18n.exists(`plan_form.errors.${code}`)) {
                        return {
                            message: t(`plan_form.errors.${code}`, { ns: 'common' }),
                            type: 'error',
                        };
                    } else {
                        return { message: code, type: 'error' };
                    }
                },
            },
            {
                onSuccess: (data) => {
                    const response = data.data?.data;

                    if (data.data?.isSuccess) {
                        setIsOpenUpgradeModal(false);

                        switch (response.qrUrl) {
                            case MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE:
                            case MESSAGE_CONTACT_COLLABORATOR_TO_RENEW:
                            case MESSAGE_CONTACT_COLLABORATOR_TO_ADD_ON: {
                                setTitle(t(`plan_form.actions.${typeModal}`));
                                setSubTitle(`notifications.${response.qrUrl}`);
                                setIsOpenQrModal(false);
                                break;
                            }
                            default: {
                                handleOpenQrPayment({
                                    totalPayment: response.paymentAmount || 0,
                                    url: response.qrUrl,
                                    subscriptionOrderId: response.subscriptionOrderId as string,
                                    title: t(`plan_form.success.${typeModal}_success`),
                                });
                            }
                        }
                    } else {
                        notification.error({
                            message: t(`plan_form.errors.upgrade_table_error`),
                        });
                    }
                    setIsLoading(false);
                },
            },
        );
    };

    const handlePaymentSubscriptionOrder = (subscriptionOrder: NSubscriptionOrder.IInfo) => {
        setIsLoading(true);

        const url = `${apiUrl}/v1/subscription-orders/payment/${subscriptionOrder.id}`;
        mutate(
            {
                url: url,
                method: 'put',
                values: { timezone: TIMEZONE_DEFAULT },
                errorNotification: false,
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    const code = error?.response?.data?.error;

                    if (i18n.exists(`plan_form.errors.${code}`)) {
                        notification.error({
                            message: t(`plan_form.errors.${code}`, { ns: 'common' }),
                        });
                    } else {
                        notification.error({
                            message: code,
                        });
                    }

                    console.error(error);
                    setIsLoading(false);
                },
                onSuccess: (data) => {
                    const response = data.data?.data;

                    if (data.data?.isSuccess) {
                        setTitle(t(`plan_form.success.${subscriptionOrder.type}_success`));
                        setTypeModal(subscriptionOrder.type);
                        setIsOpenQrModal(true);
                        onChangeQrUrl(response.qrUrl);
                        setTotalPayment(response.paymentAmount || 0);
                    } else {
                        notification.error({
                            message: t(`plan_form.errors.upgrade_table_error`),
                        });
                    }
                    setIsLoading(false);
                },
            },
        );
    };

    const paymentFormula = ({
        expiredTime,
        price,
        qtyTable,
        fnbPrice = 0,
        isFnb,
    }: {
        price: number;
        expiredTime: number;
        qtyTable: number;
        fnbPrice?: number;
        isFnb?: boolean;
    }) => {
        return isFnb
            ? price * expiredTime
            : price * expiredTime * qtyTable + fnbPrice * expiredTime;
    };

    const handleNotiResetState = () => {
        setIsOpenUpgradeModal(false);
        setTypeModal(undefined);
        refetchDetail();
        onChangeQrUrl(undefined);
        setSubTitle(undefined);
        setTitle(undefined);
        handleRefetchSubscriptionOrder();
        setSubscriptionOrderId(undefined);
    };

    const handleCloseModal = () => {
        setIsOpenUpgradeModal(false);
        setTypeModal(undefined);
        handleRefetchSubscriptionOrder();
    };

    const handleRefetchSubscriptionOrder = () => {
        setIsRefetch(true);
    };

    const onChangeQrUrl = (url?: string) => {
        setQrUrl(url);
    };

    const handleOpenQrPayment = (data: {
        totalPayment: number;
        url: string;
        subscriptionOrderId: string;
        title: string;
    }) => {
        const { totalPayment, url, subscriptionOrderId } = data;
        setIsOpenQrModal(true);
        setTotalPayment(totalPayment);
        onChangeQrUrl(url);
        setSubscriptionOrderId(subscriptionOrderId);
        setTitle(title);

        const isShowSubTitle =
            typeModal &&
            [
                SubscriptionOrderTypeEnum.UPGRADE_PAYGATE_TRANSACTION,
                SubscriptionOrderTypeEnum.UPGRADE_TAX_TRANSACTION,
            ].includes(typeModal);
        if (isShowSubTitle) {
            setNotification(t(`notifications.refresh_token`));
        }
    };

    const subscriptionContents = (registration: NRegistration.IRegistrationForDashboard) => [
        {
            label: t('plan_form.member_since'),
            value: dayjs(getLocalTime(registration.startDate))
                .locale(`${i18n.language}`)
                .format('ll'),
        },
        {
            label: t('plan_form.next_payment'),
            value: dayjs(getLocalTime(registration.expiredDate))
                .locale(`${i18n.language}`)
                .format('ll'),
        },
        {
            label: t('plan_form.remaining_time', {
                frequency: t(
                    `subscription_order.frequency_type.${
                        registration.isTrial ? registration.frequency : 'month'
                    }`,
                ),
            }),
            value: remainingMonths,
        },
    ];

    const reportPlanContents = (
        plan: NRegistration.TUsedPlan,
        qtyTable: number,
        display: boolean,
    ) => [
        {
            label: t(`plan_form.printer_noti`, {
                usedPrinter: plan?.printerQuantity || 0,
                qtyPrinter: LIMIT_PRINTER_QTY,
            }),
            percent: Math.round(((plan?.printerQuantity || 0) * 100) / LIMIT_PRINTER_QTY),
            display,
        },
        {
            label: t(`plan_form.connect_device_noti`, {
                usedConnectDevice: plan?.connectDeviceQuantity || 0,
                qtyConnectDevice: LIMIT_CONNECT_DEVICE_QTY,
            }),
            percent: Math.round(
                ((plan?.connectDeviceQuantity || 0) * 100) / LIMIT_CONNECT_DEVICE_QTY,
            ),
            display,
        },
        {
            label: t(`plan_form.table_noti`, {
                usedTable: plan?.table || 0,
                qtyTable: qtyTable,
            }),
            percent: Math.round(((plan?.table || 0) * 100) / qtyTable),
            display: true,
        },
    ];

    const items: TabsProps['items'] = [
        {
            key: TabEnum.PLAN_TAB,
            label: t(`plan_form.plan_type.${registration?.extraAppPlanName.toLowerCase()}`),
            children: (
                <div className="plan-tab">
                    <div>
                        {reportPlanContents(
                            registration?.usedPlan as NRegistration.TUsedPlan,
                            registration?.qtyTable || 0,
                            isStandardPlan,
                        ).map((i, index) => (
                            <div key={index} className={i.display ? 'pb-3' : 'hidden'}>
                                <p className="text-sm font-semibold">{i.label}</p>
                                <Progress className="h-5" percent={i.percent} />
                            </div>
                        ))}
                    </div>

                    {!registration?.isTrial && !isFreePlan && (
                        <Button
                            type="default"
                            className="button-action absolute bottom-0"
                            onClick={() => {
                                setTypeModal(SubscriptionOrderTypeEnum.UPGRADE_TABLE);
                                setIsOpenUpgradeModal(true);
                            }}
                        >
                            {t('plan_form.actions.upgrade_table')}
                        </Button>
                    )}
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (listApplications?.data) {
            setApplications(listApplications.data);
        }
    }, [listApplications]);

    if (validPermission(permissions || [], PERMISSIONS.PAYGATE_LIST)) {
        const application = applications.find((i) => i.id === APPLICATIONS.PAYGATE.id);
        items.push({
            key: TabEnum.PAYGATE_TAB,
            label: t(`modules_banner.${TabEnum.PAYGATE_TAB}.title`),
            children: (
                <div className="flex justify-between">
                    <p className="text-sm font-semibold">{t(`plan_form.remaining_transactions`)}</p>
                    <div className="flex flex-row justify-between">
                        <p>
                            {t(`plan_form.turn`, {
                                quantity: registration?.usedPlan.subscriptionQuantity || 0,
                            })}
                        </p>
                        <button
                            className="btn-add-more"
                            onClick={() => {
                                onChangeOpenModal(true, application);
                            }}
                        >
                            {t('actions.buy_more')}
                        </button>
                    </div>
                </div>
            ),
        });
    }

    if (validPermission(permissions || [], PERMISSIONS.TAX_LIST)) {
        const application = applications.find((i) => i.id === APPLICATIONS.TAX.id);

        items.push({
            key: TabEnum.TAX_TAB,
            label: t(`modules_banner.${TabEnum.TAX_TAB}.title`),
            children: (
                <div className="flex justify-between">
                    <p className="text-sm font-semibold">
                        {t(`plan_form.remaining_tax_transactions`)}
                    </p>
                    <div className="flex flex-row justify-between">
                        <p>
                            {t(`plan_form.turn`, {
                                quantity: registration?.usedPlan.taxQuantity || 0,
                            })}
                        </p>
                        <button
                            className="btn-add-more"
                            onClick={() => {
                                onChangeOpenModal(true, application);
                            }}
                        >
                            {t('actions.buy_more')}
                        </button>
                    </div>
                </div>
            ),
        });
    }

    const onChangeOpenModal = (value: boolean, module?: IMarketplace.IApplication) => {
        setOpenModal(value);
        setSelectedApplication(module);
        switch (module?.id) {
            case APPLICATIONS.PAYGATE.id:
                setTypeModal(SubscriptionOrderTypeEnum.UPGRADE_PAYGATE_TRANSACTION);
                break;
            case APPLICATIONS.TAX.id:
                setTypeModal(SubscriptionOrderTypeEnum.UPGRADE_TAX_TRANSACTION);
                break;
        }
    };

    return (
        <>
            {!isTenantOwner || (!isLoading && !detailLoading && !registration) ? (
                <>
                    <IntroduceIndex />
                </>
            ) : (
                <Spin spinning={isLoading}>
                    {registration ? (
                        <>
                            <section className="plan-detail">
                                <Row gutter={[0, 48]} className="w-full justify-between">
                                    <Col span={24} className="rounded-lg border p-5">
                                        <div className="flex flex-col lg:flex-row justify-between gap-5">
                                            <div className="flex-1">
                                                <section className="flex justify-between items-start lg:flex-row flex-col">
                                                    <h2 className="text-2xl">
                                                        {t(`plan_form.fields.service_package`)}
                                                    </h2>
                                                    <div className="flex flex-col justify-between">
                                                        <p className={'text-4xl font-bold'}>
                                                            <span>
                                                                {formatPriceBaseOnCurrency(
                                                                    registration.planPrice,
                                                                    CurrencyType.Vnd,
                                                                )}{' '}
                                                            </span>
                                                            <span className="text-xl font-normal text-slate-400">
                                                                /
                                                                {isCountByTableQty
                                                                    ? t('plan_form.pc_month')
                                                                    : t('plan_form.month')}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </section>

                                                <Tag
                                                    bordered={false}
                                                    className="px-3 py-2 lg:mt-1 mt-3 mb-6"
                                                >
                                                    <span>
                                                        {t(
                                                            `plan_form.plan_type.${registration.extraAppPlanName.toLowerCase()}`,
                                                        ).toUpperCase()}
                                                    </span>
                                                    {registration.isTrial ? (
                                                        <span className="text-slate-400">
                                                            {' '}
                                                            ({t('plan_form.trial')})
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Tag>

                                                <section className="grid grid-cols-1 sm:grid-cols-3 gap-8 my-8 text-sm font-normal">
                                                    {subscriptionContents(registration).map(
                                                        (i, index) => (
                                                            <div
                                                                key={index}
                                                                className="flex items-center justify-between flex-col lg:border-none lg:p-0 border-b pb-3"
                                                            >
                                                                <p className="text-slate-400 mb-2">
                                                                    {i.label}
                                                                </p>
                                                                <p className="font-semibold text-base">
                                                                    {i.value}
                                                                </p>
                                                            </div>
                                                        ),
                                                    )}
                                                </section>

                                                <div className="flex flex-col gap-4">
                                                    <div className="flex flex-col sm:flex-row gap-4">
                                                        {!isFreePlan && (
                                                            <Button
                                                                type="default"
                                                                onClick={() => {
                                                                    setTypeModal(
                                                                        SubscriptionOrderTypeEnum.RENEW_LICENSE,
                                                                    );
                                                                    setIsOpenUpgradeModal(true);
                                                                }}
                                                                className="button-action"
                                                            >
                                                                {t(
                                                                    'plan_form.actions.renew_license',
                                                                )}
                                                            </Button>
                                                        )}
                                                        {!registration.isTrial &&
                                                            registration.extraAppPlanId !==
                                                                POS_PLAN_ID.PREMIUM && (
                                                                <Button
                                                                    type="primary"
                                                                    onClick={() => {
                                                                        setTypeModal(
                                                                            SubscriptionOrderTypeEnum.UPGRADE_LICENSE,
                                                                        );
                                                                        setIsOpenUpgradeModal(true);
                                                                    }}
                                                                    className="button-action"
                                                                >
                                                                    {t(
                                                                        'plan_form.actions.upgrade_license',
                                                                    )}
                                                                </Button>
                                                            )}
                                                    </div>
                                                    {!registration.isTrial &&
                                                        [POS_PLAN_ID.STANDARD].includes(
                                                            registration.extraAppPlanId,
                                                        ) && (
                                                            <Button
                                                                type="primary"
                                                                onClick={() => {
                                                                    setTypeModal(
                                                                        SubscriptionOrderTypeEnum.ADD_ON_PLAN,
                                                                    );
                                                                    setIsOpenUpgradeModal(true);
                                                                }}
                                                                className="button-action"
                                                            >
                                                                {t('plan_form.actions.add_on_plan')}
                                                            </Button>
                                                        )}
                                                </div>
                                            </div>
                                            <Divider
                                                type="vertical"
                                                className="hidden xl:block h-auto min-h-full bg-[#F3F4F6]"
                                            />
                                            <div className="flex-1">
                                                <Tabs defaultActiveKey="1" items={items} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </section>

                            <ModulesBanner
                                onChangeOpenModal={onChangeOpenModal}
                                applications={applications}
                            />

                            <SubscriptionOrder
                                isRefetch={isRefetch}
                                setIsRefetch={setIsRefetch}
                                handlePaymentSubscriptionOrder={(record) => {
                                    setSubscriptionOrderId(record.id);
                                    handlePaymentSubscriptionOrder(record);
                                }}
                            />

                            {isOpenUpgradeModal && typeModal ? (
                                <>
                                    {[SubscriptionOrderTypeEnum.UPGRADE_LICENSE].includes(
                                        typeModal,
                                    ) && (
                                        <UpgradeLicenseModal
                                            isOpen={isOpenUpgradeModal}
                                            onClose={handleCloseModal}
                                            handlePayment={handlePayment}
                                            isLoading={isLoading}
                                            registration={registration}
                                            paymentFormula={paymentFormula}
                                            remainingMonths={remainingMonths}
                                            isCountByTableQty={isCountByTableQty}
                                            businessType={businessType}
                                        />
                                    )}

                                    {[
                                        SubscriptionOrderTypeEnum.UPGRADE_TABLE,
                                        SubscriptionOrderTypeEnum.RENEW_LICENSE,
                                    ].includes(typeModal) && (
                                        <UpgradeModal
                                            type={typeModal}
                                            isOpen={isOpenUpgradeModal}
                                            onClose={handleCloseModal}
                                            handlePayment={handlePayment}
                                            isLoading={isLoading}
                                            registration={registration}
                                            isCountByTableQty={isCountByTableQty}
                                            paymentFormula={paymentFormula}
                                            remainingMonths={remainingMonths}
                                        />
                                    )}

                                    {[SubscriptionOrderTypeEnum.ADD_ON_PLAN].includes(
                                        typeModal,
                                    ) && (
                                        <AddOnPlanModal
                                            type={typeModal}
                                            isOpen={isOpenUpgradeModal}
                                            onClose={handleCloseModal}
                                            handlePayment={handlePayment}
                                            isLoading={isLoading}
                                            registration={registration}
                                            paymentFormula={paymentFormula}
                                            remainingMonths={remainingMonths}
                                        />
                                    )}
                                </>
                            ) : (
                                <></>
                            )}

                            {qrUrl && (
                                <>
                                    <QRModal
                                        url={qrUrl}
                                        isOpen={isOpenQrModal}
                                        totalPrice={totalPayment}
                                        onClose={() => {
                                            onChangeQrUrl(undefined);
                                            setIsOpenQrModal(false);
                                            handleRefetchSubscriptionOrder();
                                            setSubscriptionOrderId(undefined);
                                            setSubTitle(undefined);
                                        }}
                                    />
                                </>
                            )}

                            {subscriptionOrderId && (
                                <NotificationPaymentModal
                                    objectId={subscriptionOrderId || ''}
                                    onClose={handleNotiResetState}
                                    onCloseQrModal={() => {
                                        onChangeQrUrl(undefined);
                                        setIsOpenQrModal(false);
                                    }}
                                    subTitle={subTitle}
                                    title={title}
                                    id={registration.id}
                                    notification={notificationFirstBuying}
                                />
                            )}

                            {subTitle && !subscriptionOrderId && (
                                <NotificationInformationModal
                                    onClose={handleNotiResetState}
                                    title={title || ''}
                                    subTitle={subTitle || ''}
                                />
                            )}
                        </>
                    ) : (
                        <></>
                    )}

                    {openModal && selectedApplication && (
                        <ModuleItemModal
                            isLoading={false}
                            handleOpenQrPayment={handleOpenQrPayment}
                            isOpen={openModal}
                            item={selectedApplication}
                            registrationId={registration?.id || ''}
                            onClose={() => onChangeOpenModal(false)}
                        />
                    )}
                </Spin>
            )}
        </>
    );
};
