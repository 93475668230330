import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { CollaboratorDetail } from '@components/collaborator/detail';
import { CollaboratorDetailForm } from '@components/collaborator/detail-form';
import { NCollaborator } from '@interfaces/collaborator';
import { DeleteButton, mapAntdSorterToCrudSorting, useDrawerForm } from '@refinedev/antd';
import { LogicalFilter, useTable } from '@refinedev/core';
import { Button, Dropdown, Menu, notification, Table, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomSearch } from '../../components/modules/custom-search';
import { CustomPagination } from '../../components/modules/pagination';
import { DATE_FORMAT_TIME, PAGE_CURRENT, PAGE_SIZE } from '../../constants/index.constant';

export const CollaboratorIndex: React.FC = () => {
    const { t } = useTranslation(['collaborator', 'common']);

    const [idCollaborator, setIdCollaborator] = useState<string>();
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

    const {
        setFilters,
        filters,
        tableQueryResult,
        current,
        setCurrent,
        pageSize,
        setPageSize,
        setSorters,
    } = useTable<NCollaborator.IRequest>({
        resource: 'v1/collaborator',
        initialPageSize: PAGE_SIZE,
        initialCurrent: PAGE_CURRENT,
        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc',
                },
            ],
        },
    });

    const { drawerProps, formProps, saveButtonProps, show, formLoading } =
        useDrawerForm<NCollaborator.IRequest>({
            redirect: false,
            resource: 'v1/collaborator',
            action: idCollaborator ? 'edit' : 'create',
            successNotification: {
                message: t(idCollaborator ? 'update_success' : 'create_success', { ns: 'common' }),
                type: 'success',
            },
            onMutationSuccess: () => {
                setIsOpenDrawer(false);
                setIdCollaborator(undefined);
                formProps.form.resetFields();
            },
            onMutationError: () => {
                notification.error({
                    message: t(idCollaborator ? 'update_err' : 'create_error', { ns: 'common' }),
                    type: 'error',
                });
            },
        });

    const menu = (id: string) => (
        <Menu mode="vertical">
            <Menu.Item
                key="1"
                icon={<img src="/images/icons/edit.svg" alt="edit" className="cursor-pointer" />}
                onClick={() => {
                    show(id);
                    setIdCollaborator(id);
                    setIsOpenDrawer(true);
                }}
            >
                {t('actions.edit', { ns: 'common' })}
            </Menu.Item>
            <Menu.Item key="2">
                <DeleteButton
                    recordItemId={id}
                    resource={'v1/collaborator'}
                    className="border-none shadow-none"
                    onSuccess={() => tableQueryResult.refetch()}
                    successNotification={{
                        message: t('delete_success', {
                            ns: 'common',
                        }),
                        type: 'success',
                    }}
                    errorNotification={() => ({
                        message: t('delete_error', {
                            ns: 'common',
                        }),
                        type: 'error',
                    })}
                />
            </Menu.Item>
        </Menu>
    );

    const columns: ColumnsType<NCollaborator.IRequest> = [
        {
            title: <div className="table-thead-text rounded center">{t('fields.no.')}</div>,
            dataIndex: 'number',
            key: 'number',
            width: 100,
            render: (__, _, index) => (
                <div className="center">
                    <p className="table-tbody-text">{index + 1 + (current - 1) * pageSize}</p>
                </div>
            ),
        },
        {
            title: <div className="table-thead-text rounded">{t('fields.email')}</div>,
            dataIndex: 'email',
            key: 'email',
            width: 200,
            sorter: () => 0,
            render: (email) => (
                <div className="table-tbody-text">
                    <div>
                        <Tooltip title={email}>{email}</Tooltip>
                    </div>
                </div>
            ),
        },
        {
            title: <div className="table-thead-text rounded">{t('fields.full_name')}</div>,
            dataIndex: 'fullName',
            key: 'fullName',
            width: 200,
            sorter: () => 0,
            render: (fullName) => (
                <div className="table-tbody-text">
                    <div>
                        <Tooltip title={fullName}>{fullName}</Tooltip>
                    </div>
                </div>
            ),
        },
        {
            title: <div className="table-thead-text rounded">{t('fields.phone_number')}</div>,
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: 200,
            render: (phoneNumber) => (
                <div className="table-tbody-text">
                    <div>
                        <Tooltip title={phoneNumber}>{phoneNumber}</Tooltip>
                    </div>
                </div>
            ),
        },
        {
            title: <div className="table-thead-text rounded">{t('fields.discount')}</div>,
            dataIndex: 'discount',
            key: 'discount',
            width: 150,
            sorter: () => 0,
            render: (discount) => (
                <div className="table-tbody-text">
                    <div>
                        <Tooltip title={`${discount}%`}>{`${discount}%`}</Tooltip>
                    </div>
                </div>
            ),
        },
        {
            title: <div className="table-thead-text rounded">{t('fields.code')}</div>,
            dataIndex: 'code',
            key: 'code',
            width: 150,
            render: (code) => (
                <div className="table-tbody-text">
                    <div>
                        <Tooltip title={code}>{code}</Tooltip>
                    </div>
                </div>
            ),
        },
        {
            title: <div className="table-thead-text rounded">{t('fields.created_at')}</div>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200,
            sorter: () => 0,
            render: (createdAt) => (
                <div className="table-tbody-text">
                    <div>
                        <Tooltip title={dayjs(createdAt).format(DATE_FORMAT_TIME)}>
                            {dayjs(createdAt).format(DATE_FORMAT_TIME)}
                        </Tooltip>
                    </div>
                </div>
            ),
        },
        {
            key: 'id',
            dataIndex: 'id',
            width: 100,
            fixed: 'right',
            render: (id) => (
                <div className="flex justify-center items-center">
                    <Dropdown
                        arrow
                        overlay={menu(id)}
                        trigger={['click']}
                        placement="bottomRight"
                        overlayClassName="contact-dropdown-container"
                    >
                        <img
                            alt="more"
                            className="cursor-pointer"
                            src="/images/icons/dots-vertical.svg"
                        />
                    </Dropdown>
                </div>
            ),
        },
    ];

    const onChangeTable = (
        _: TablePaginationConfig,
        __: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const handleFilterChange = (field: string, value: string) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === field);

            if (index < 0) {
                newFilter.push({
                    field,
                    operator: 'eq',
                    value: value,
                });
            } else {
                newFilter[index].value = value;
            }

            return newFilter;
        });

        setCurrent(1);
    };

    return (
        <>
            <section className="collaborator-list-container">
                <div className="list-header">
                    <CustomSearch
                        allowClear={true}
                        className={'search-item'}
                        placeholder={t('placeholders.search_collaborator')}
                        defaultValue={filters?.find((f: any) => f.field === 'filter')?.value}
                        onChange={(event: any) => {
                            handleFilterChange('filter', event.target.value);
                        }}
                    />

                    <Button
                        type="primary"
                        className="btn-add-new"
                        onClick={() => {
                            show();
                            setIsOpenDrawer(true);
                        }}
                    >
                        <PlusOutlined />
                        {t('add_new', { ns: 'common' })}
                    </Button>
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            rowKey="id"
                            columns={columns}
                            pagination={false}
                            tableLayout="fixed"
                            scroll={{ x: 768 }}
                            onChange={onChangeTable}
                            loading={tableQueryResult.isFetching}
                            dataSource={tableQueryResult?.data?.data}
                        />
                    </div>
                </div>

                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        current={current}
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>

            {isOpenDrawer ? (
                <CollaboratorDetail
                    formProps={formProps}
                    isEditMode={!!idCollaborator}
                    formLoading={formLoading}
                    drawerProps={drawerProps}
                    saveButtonProps={saveButtonProps}
                    title={idCollaborator ? t(`titles.edit`) : t(`titles.create`)}
                    resetStatusDrawer={() => {
                        setIsOpenDrawer(false);
                        setIdCollaborator(undefined);
                    }}
                >
                    <CollaboratorDetailForm
                        formProps={formProps}
                        isEditMode={!!idCollaborator}
                        id={idCollaborator || undefined}
                    />
                </CollaboratorDetail>
            ) : null}
        </>
    );
};
