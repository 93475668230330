import { NSubscriptionPlan } from '@interfaces/subcription-plan';
import { useCallback } from 'react';

interface UsePriceCalculatorProps {
    priceThresholds: NSubscriptionPlan.PriceThreshold[];
    minValue: number;
    defaultPrice?: number;
    defaultThreshold?: number;
}

export const usePriceCalculator = ({ priceThresholds, minValue }: UsePriceCalculatorProps) => {
    const calculatePrice = useCallback(
        (value: number): { price: number; step?: number } => {
            const threshold = priceThresholds
                .sort((a, b) => b.threshold - a.threshold)
                .find((t) => value >= t.threshold);

            return {
                price: threshold?.price || 0,
                step: threshold?.step,
            };
        },
        [priceThresholds],
    );

    const calculateTaxPrice = useCallback(
        (value: number): { price: number; step?: number } => {
            if (value === 0) return { price: 0, step: 0 };
            if (value > 10000) {
                const threshold = priceThresholds
                    .sort((a, b) => b.threshold - a.threshold)
                    .find((t) => {
                        return value > t?.threshold;
                    });

                if (threshold) {
                    return {
                        price: threshold?.price || 0,
                        step: threshold?.step,
                    };
                }
            }

            const threshold = priceThresholds.find((t) => value == t.threshold);
            const pricePerUnit = threshold?.price ?? 0;

            return {
                price: Math.round(pricePerUnit),
                step: threshold?.step,
            };
        },
        [priceThresholds],
    );

    const getThresholdByPrice = useCallback(
        (value: number): NSubscriptionPlan.PriceThreshold | null => {
            return priceThresholds.find((t) => value == t.price) ?? null;
        },
        [priceThresholds],
    );

    const mappingValueWithThreshold = useCallback(
        (value: number | null): number | null => {
            if (value) {
                const roundedValue = Math.round(value / minValue) * minValue;
                return roundedValue;
            }
            return null;
        },
        [priceThresholds],
    );

    const handleValueChange = useCallback(
        (inputValue: number | null, onChange: (value: number) => void): number | null => {
            const onChangeValue = mappingValueWithThreshold(inputValue);
            if (onChangeValue) {
                onChange(onChangeValue);
                return onChangeValue;
            }
            return null;
        },
        [minValue],
    );

    const calculateTotal = useCallback(
        (quantity: number): number => {
            const price = calculatePrice(quantity);
            return quantity * price.price;
        },
        [calculatePrice],
    );

    const calculateTaxTotal = useCallback(
        (quantity: number): number => {
            const price = calculateTaxPrice(quantity);
            return quantity * price.price;
        },
        [calculateTaxPrice],
    );

    return {
        calculatePrice,
        handleValueChange,
        calculateTotal,
        calculateTaxTotal,
        calculateTaxPrice,
        getThresholdByPrice,
    };
};
