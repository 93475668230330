import './styles.scss';

import { HttpError, useApiUrl, useCustom, usePermissions } from '@refinedev/core';
import { Col, Form, FormProps, Input, Row, UploadFile } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Response from '../../data-access/responses/response';
import { IContact } from '../../interfaces/contact';
import { maskPhone } from '../../utils/string';
import { DebounceAsyncValidator, validateEmail } from '../../utils/validator';

export type FormMode = 'show' | 'add' | 'edit';
type ContactDetailFormProps = {
    id?: string;
    formProps: FormProps;
    mode: FormMode;
    onCancelEdit?: () => void;
    onChangeMode?: (mode: FormMode) => void;
};
export const ContactDetailForm: React.FC<ContactDetailFormProps> = ({
    id,
    formProps,
    mode,
    onCancelEdit,
    onChangeMode,
}) => {
    const apiUrl = useApiUrl();
    const { t } = useTranslation(['contact', 'common']);
    const [loading, setLoading] = useState<boolean>(false);

    const [isUpdateAvatar, setIsUpdateAvatar] = useState<boolean>(false);
    const initialValues: IContact.IContact = {
        ...formProps.initialValues,
    } as IContact.IContact;
    const { form } = formProps;
    const [fullName, setFullname] = useState<string>(
        initialValues ? `${initialValues.firstName} ${initialValues.lastName}` : '',
    );
    const [company, setCompany] = useState<string>(initialValues ? initialValues.company : '');

    const [email, setEmail] = useState('');

    const renderFormItem = (formItem: JSX.Element, showItem: JSX.Element) => {
        if (mode === 'show') {
            return showItem;
        }
        return formItem;
    };

    const { refetch } = useCustom<Response<boolean>, HttpError, IContact.ICheckUniqueEmailRequest>({
        url: `${apiUrl}/v1/contacts/check-unique-email`,
        method: 'post',
        config: {
            payload: { email: email, id: id },
        },
        queryOptions: {
            enabled: false,
        },
    });

    const asyncValidateUniqueEmail = DebounceAsyncValidator(400, async () => {
        const { data } = await refetch();

        if (data && data?.data?.data) {
            return true;
        }
        return false;
    });

    useEffect(() => {
        setFullname(initialValues ? `${initialValues.firstName} ${initialValues.lastName}` : '');
        setCompany(initialValues ? initialValues.company : '');
        // setEmail(initialValues ? initialValues.email : '');
    }, [initialValues]);

    return (
        <Form
            className={`contact-detail-form ${mode === 'show' ? 'show-mode' : ''}`}
            {...formProps}
            form={form}
            onFinish={async (values) => {
                if (mode === 'edit') {
                    values.avatarUrl = isUpdateAvatar ? values.avatarUrl : null;
                } else {
                    values.avatarUrl = values.avatarUrl?.length > 0 ? values.avatarUrl : null;
                }

                formProps.onFinish && formProps.onFinish(values);
            }}
            layout="vertical"
            initialValues={{
                ...initialValues,
                phoneNumber: maskPhone(initialValues?.phoneNumber || ''),
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    <Form.Item
                        label={t('contacts.fields.email.label')}
                        name="email"
                        rules={
                            mode === 'add'
                                ? [
                                      {
                                          required: true,
                                          message: t('contacts.fields.email.required'),
                                      },
                                      {
                                          type: 'email',
                                          message: t('contacts.fields.email.valid'),
                                      },
                                      {
                                          validator: async (_, value) => {
                                              if (
                                                  !value ||
                                                  !validateEmail(value) ||
                                                  value === initialValues.email
                                              ) {
                                                  return;
                                              }

                                              const result = await asyncValidateUniqueEmail();
                                              if (result) {
                                                  return Promise.resolve();
                                              }
                                              return Promise.reject(
                                                  new Error(t('contacts.fields.email.unique')),
                                              );
                                          },
                                      },
                                  ]
                                : []
                        }
                    >
                        {renderFormItem(
                            mode !== 'add' ? (
                                <span>{initialValues?.email}</span>
                            ) : (
                                <Input
                                    onChange={(e) => {
                                        if (e?.target?.value) {
                                            const _value = e.target.value.trim().toLowerCase();
                                            formProps.form?.setFieldsValue({ email: _value });
                                            setEmail(_value);
                                        }
                                    }}
                                />
                            ),
                            <span>{initialValues?.email}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('contacts.fields.firstName.label')}
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: t('contacts.fields.firstName.required'),
                            },
                        ]}
                    >
                        {renderFormItem(<Input />, <span>{initialValues?.firstName}</span>)}
                    </Form.Item>
                    <Form.Item
                        label={t('contacts.fields.lastName.label')}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: t('contacts.fields.lastName.required'),
                            },
                        ]}
                    >
                        {renderFormItem(<Input />, <span>{initialValues?.lastName}</span>)}
                    </Form.Item>

                    <Form.Item
                        label={t('contacts.fields.company.label')}
                        name="company"
                        rules={[
                            {
                                required: true,
                                message: t('contacts.fields.company.required'),
                            },
                        ]}
                    >
                        {renderFormItem(<Input />, <span>{initialValues?.company}</span>)}
                    </Form.Item>
                    <Form.Item
                        label={t('contacts.fields.phoneNumber.label')}
                        name="phoneNumber"
                        rules={[
                            {
                                pattern: /\(\d{3}\)-\d{3}-\d{4}/,
                                message: t('contacts.fields.phoneNumber.required'),
                            },
                            {
                                required: true,
                                message: t('contacts.fields.phoneNumber.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            <MaskedInput
                                mask={
                                    //  https://imask.js.org/guide.html#masked-pattern
                                    '(000)-000-0000'
                                }
                            />,
                            <span>{maskPhone(initialValues?.phoneNumber || '')}</span>,
                        )}
                    </Form.Item>
                    <Form.Item label={t('contacts.fields.website.label')} name="website">
                        {renderFormItem(<Input />, <span>{initialValues?.website}</span>)}
                    </Form.Item>
                    <Form.Item label="Twitter" name="twitter">
                        {renderFormItem(<Input />, <span>{initialValues?.twitter}</span>)}
                    </Form.Item>
                    <Form.Item label="Facebook" name="facebook">
                        {renderFormItem(<Input />, <span>{initialValues?.facebook}</span>)}
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
