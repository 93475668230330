export const CheckIconFilled = ({ color }: { color?: string }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.99967 1.33325C4.31767 1.33325 1.33301 4.31792 1.33301 7.99992C1.33301 11.6819 4.31767 14.6666 7.99967 14.6666C11.6817 14.6666 14.6663 11.6819 14.6663 7.99992C14.6663 4.31792 11.6817 1.33325 7.99967 1.33325ZM11.8043 6.47125L7.13767 11.1379C7.00767 11.2679 6.83701 11.3333 6.66634 11.3333C6.49567 11.3333 6.32501 11.2679 6.19501 11.1379L4.19501 9.13792C3.93434 8.87725 3.93434 8.45592 4.19501 8.19525C4.45567 7.93459 4.87701 7.93459 5.13767 8.19525L6.66634 9.72392L10.8617 5.52859C11.1223 5.26792 11.5437 5.26792 11.8043 5.52859C12.065 5.78925 12.065 6.21059 11.8043 6.47125Z"
                fill="white"
            />
        </svg>
    );
};
