import './styles.scss';

import { DownOutlined, FormOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@constants/permission';
import { DeleteButton } from '@refinedev/antd';
import { usePermissions } from '@refinedev/core';
import { Collapse, Dropdown, Menu, Space, Upload } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IContact } from '../../interfaces/contact';
import { toLocaleDateTime } from '../../utils/date';
import { getPublicMediaUrl } from '../../utils/resource';
import { convertToPlain, stringSlice } from '../../utils/string';
import { ContactNoteItemForm } from './note-form';

export const ContactNoteItem = ({ item }: { item: IContact.IContactNote }) => {
    const { t } = useTranslation(['common', 'contact']);
    const [isActive, setIsActive] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [data, setData] = useState(item);
    const { data: permissions } = usePermissions<string[]>();
    const actionMenu = useMemo(() => {
        return isActive ? (
            <div onClick={(e) => e.stopPropagation()} className="note-item-actions">
                <Dropdown
                    overlayClassName="note-item-actions-dropdown"
                    overlay={
                        <Menu mode="vertical">
                            <Menu.Item
                                key="1"
                                style={{
                                    fontWeight: 500,
                                }}
                                icon={
                                    <FormOutlined
                                        style={{
                                            color: 'green',
                                        }}
                                    />
                                }
                                onClick={() => {
                                    setIsEditMode(true);
                                }}
                            >
                                {t('services.buttons.edit')}
                            </Menu.Item>
                            <Menu.Item key="2">
                                <DeleteButton
                                    successNotification={{
                                        message: 'Successfully deleted',
                                        type: 'success',
                                    }}
                                    accessControl={{ enabled: false }}
                                    resource="v1/contact-notes"
                                    recordItemId={item.id}
                                />
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <span onClick={(e) => e.stopPropagation()}>
                        <Space>
                            {t('actions.actions')}
                            <DownOutlined />
                        </Space>
                    </span>
                </Dropdown>
            </div>
        ) : (
            <></>
        );
    }, [isActive]);

    return (
        <Collapse
            accordion={false}
            onChange={(activePanels) => {
                setIsActive(activePanels.length > 0);
            }}
        >
            <Collapse.Panel
                header={stringSlice(convertToPlain(data.content), 20)}
                extra={
                    <Space>
                        {(permissions || []).includes(PERMISSIONS.WL_CONTACT_EDIT)
                            ? actionMenu
                            : null}
                        <div className="w-44">
                            {item.createdAt ? toLocaleDateTime(new Date(item.createdAt)) : null}
                        </div>
                    </Space>
                }
                key="note-item"
            >
                {isEditMode ? (
                    <ContactNoteItemForm
                        contactId={item.contactId}
                        data={item}
                        onCancelEdit={() => {
                            setIsEditMode(false);
                        }}
                        onUpdate={(data) => {
                            setData({
                                ...data,
                                content: data.content,
                                attachments: data.attachments,
                            });
                            setIsEditMode(false);
                        }}
                    />
                ) : (
                    <>
                        <div dangerouslySetInnerHTML={{ __html: data.content }} />
                        {data.attachments &&
                        (permissions || []).includes(PERMISSIONS.WL_CONTACT_EDIT) ? (
                            <Upload
                                className="upload-list-inline"
                                listType="text"
                                multiple
                                // onRemove={onRemoveFile}
                                showUploadList={{
                                    showDownloadIcon: false,
                                    downloadIcon: 'Download',
                                    showRemoveIcon: false,
                                }}
                                fileList={data.attachments.map((item) => {
                                    return {
                                        ...item,
                                        uid: item.id as string,
                                        url: getPublicMediaUrl(item.url),
                                    };
                                })}
                            ></Upload>
                        ) : null}
                    </>
                )}
            </Collapse.Panel>
        </Collapse>
    );
};
