import './styles.scss';

import { PERMISSIONS } from '@constants/permission';
import { IContact } from '@interfaces/contact';
import { useSimpleList } from '@refinedev/antd';
import { HttpError, usePermissions } from '@refinedev/core';
import { List } from 'antd';

import { CreateContactNoteButton } from './create-note-button';
import { ContactNoteItem } from './note-item';

export const ContactNoteList: React.FC<{ contactId: string }> = ({ contactId }) => {
    const { listProps } = useSimpleList<IContact.IContactNote, HttpError, { content: string }>({
        resource: 'v1/contact-notes',
        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc',
                },
            ],
        },
        pagination: {
            pageSize: 10,
        },
        filters: {
            initial: [
                {
                    field: 'contactId',
                    operator: 'eq',
                    value: contactId,
                },
            ],
        },
    });

    const { data: permissions } = usePermissions<string[]>();

    return (
        <div className="note-list-wrapper">
            <div className="flex justify-between mb-4 contact-note-list">
                {(permissions || []).includes(PERMISSIONS.WL_CONTACT_EDIT) ? (
                    <CreateContactNoteButton contactId={contactId} />
                ) : null}
            </div>
            <List
                className="contact-note-list-container"
                itemLayout="horizontal"
                {...listProps}
                renderItem={(item) => <ContactNoteItem key={item.id} item={item} />}
            />
        </div>
    );
};
