import './styles.scss';

import { Form, Input, Select } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';

import { CurrencyType } from '../../enums/currency-type.enum';

type CurrencyConfig = { prefix: string; currency: CurrencyType };

type Props = {
    label: string;
    name: string;
    currencyConfig: CurrencyConfig;
    pricePlan: number;
    setCurrencyConfig: (arg: CurrencyConfig) => void;
    rules?: Array<Rule> | undefined;
    handlePriceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const PriceFormItem: React.FC<Props> = ({
    label,
    name,
    currencyConfig,
    pricePlan,
    rules,
    setCurrencyConfig,
    handlePriceChange,
}) => {
    const prefixSelector = (
        <Form.Item name="currency" noStyle>
            <Select
                defaultValue={currencyConfig.currency}
                style={{ width: 80 }}
                onChange={(value: CurrencyType) => onChangeCurrency(value)}
            >
                <Select.Option value={CurrencyType.Usd}>US</Select.Option>
                <Select.Option value={CurrencyType.Vnd}>VND</Select.Option>
            </Select>
        </Form.Item>
    );

    const onChangeCurrency = (currency: CurrencyType) => {
        switch (currency) {
            case CurrencyType.Usd:
                setCurrencyConfig({
                    currency: CurrencyType.Vnd,
                    prefix: '$',
                });
                break;
            case CurrencyType.Vnd:
                setCurrencyConfig({
                    currency: CurrencyType.Vnd,
                    prefix: 'đ',
                });
                break;
        }
    };

    return (
        <Form.Item className="pricing-form-item" label={label} name={name} rules={rules}>
            <Input
                prefix={currencyConfig.prefix}
                addonBefore={prefixSelector}
                style={{ width: '100%' }}
                onChange={(e) => handlePriceChange?.(e)}
                value={pricePlan}
            />
        </Form.Item>
    );
};
