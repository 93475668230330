export enum ResourceEnum {
    WLRevenue = 'wl_revenue',
    WLService = 'wl_service',
    WLPartner = 'wl_partner',
    WLPayouts = 'wl_payouts',
    WLSetting = 'wl_setting',
    WLContact = 'wl_contact',
    WLQuote = 'wl_quote',
    WLInvoice = 'wl_invoice',
    WLOrder = 'wl_order',
    WLRecurringInvoice = 'wl_recurringInvoice',
    WLResource = 'wl_resource',
    WLHelpdesk = 'wl_helpdesk',
    POSReport = 'pos_report',
    POSShop = 'pos_shop',
    POSTable = 'pos_table',
    POSTableGroup = 'pos_tableGroup',
    POSEmployee = 'pos_employee',
    POSExtraFee = 'pos_extraFee',
    POSPayouts = 'pos_payouts',
    POSSetting = 'pos_setting',
    POSGeneralSetting = 'pos_generalSetting',
    POSPaymentSetting = 'pos_paymentSetting',
    POSEquipmentSetting = 'pos_equipmentSetting',
    POSPaymentTerminalSetting = 'pos_paymentTerminal',
    POSPrintSetting = 'pos_printSetting',
    POSItem = 'pos_item',
    POSCategory = 'pos_category',
    POSOption = 'pos_option',
}
