import './styles.scss';

import { PERMISSIONS } from '@constants/permission';
import { Payment } from '@interfaces/payment';
import { useCustom, useCustomMutation, usePermissions, useTranslate } from '@refinedev/core';
import Response from '@responses/response';
import { Button, Col, Image, notification, Row, Spin } from 'antd';
import { getEnvConfig } from 'getEnvConfig';
import { WLSettingLayout } from 'pages/wl-setting';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export enum TenantPaymentAccountError {
    AccountNotExisted = 'account_not_existed',
    GetFailed = 'get_account_failed',
    TenantNotFound = 'tenant_not_found',
}

const ACCOUNT_UPDATED_KEY = 'account_updated';

export const PayoutLayout: React.FC = () => {
    const apiUrl = getEnvConfig.PORTAL_URL;
    const translate = useTranslate();
    const { data: permissions } = usePermissions<string[]>();
    const { mutate, isLoading: updating } = useCustomMutation<{ data?: string }>();

    const {
        isFetched,
        data: paymentAccount,
        isLoading,
    } = useCustom<Response<Payment.IPaymentAccount>>({
        url: `${apiUrl}/v1/payments/account-link`,
        method: 'get',
        config: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
        },
    });

    const location = useLocation();

    useEffect(() => {
        if (location?.search) {
            const searchQuery = location?.search ?? '';
            if (searchQuery.includes(ACCOUNT_UPDATED_KEY)) {
                updateConnectedAccount();
            }
        }
    }, [location?.search]);

    const connect = () => {
        const request: Payment.ICreateAccountLink = {
            refreshUrl: `${window?.location?.origin}${window?.location?.pathname}?refresh=true&${ACCOUNT_UPDATED_KEY}=true`,
            returnUrl: `${window?.location?.origin}${window?.location?.pathname}?return=true&${ACCOUNT_UPDATED_KEY}=true`,
            country: 'US',
            type: 'account_onboarding',
        };
        mutate(
            {
                url: `${apiUrl}/v1/payments/account-link`,
                method: 'post',
                values: request,
                config: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                    },
                },
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: (result) => {
                    if (result) {
                        window?.open(result?.data?.data, '_self');
                    }
                },
                onError: (error, variables, context) => {
                    notification.error({
                        type: 'error',
                        message: translate('payouts.create_account_failed'),
                    });
                },
            },
        );
    };

    const updateConnect = () => {
        const request: Payment.ICreateAccountLink = {
            refreshUrl: `${window?.location?.origin}${window?.location?.pathname}?refresh=true&${ACCOUNT_UPDATED_KEY}=true`,
            returnUrl: `${window?.location?.origin}${window?.location?.pathname}?return=true&${ACCOUNT_UPDATED_KEY}=true`,
            country: 'US',
            type: 'account_onboarding',
        };
        mutate(
            {
                values: request,
                method: 'put',
                url: `${apiUrl}/v1/payments/account-link/${account?.accountId}`,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: (result, variables, context) => {
                    if (result) {
                        window?.open(result?.data?.data, '_self');
                    }
                },
                onError: (error, variables, context) => {
                    notification.error({
                        type: 'error',
                        message: translate('payouts.update_account_failed'),
                    });
                },
            },
        );
    };

    const updateConnectedAccount = () => {
        mutate(
            {
                values: {},
                method: 'put',
                url: `${apiUrl}/v1/payments/account`,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: (result, variables, context) => {
                    window.open(`${window.location.origin}${window.location.pathname}`, '_self');
                },
                onError: (error, variables, context) => {
                    notification.error({
                        type: 'error',
                        message: translate('payouts.update_account_failed'),
                    });
                    window.open(`${window.location.origin}${window.location.pathname}`, '_self');
                },
            },
        );
    };

    const account = paymentAccount?.data?.data;
    const isCompleted =
        account?.capabilities?.cardPayments === 'active' &&
        account?.capabilities?.transfers === 'active' &&
        account?.payoutsEnabled === true;

    return (
        <section className="account-container w-full h-full">
            <Spin spinning={isLoading || updating} className=" w-full h-full">
                {isFetched && !account && (
                    <div className="payout-notification error w-full">
                        <span className="text-base notification-text">
                            {translate('payouts.account_not_existed')}
                        </span>
                    </div>
                )}

                {isFetched && account && !isCompleted && (
                    <div className="payout-notification restricted w-full">
                        <span className="text-base notification-text">
                            {translate('payouts.account_restricted_error')}
                        </span>
                    </div>
                )}

                {isFetched && account && isCompleted && (
                    <div className="payout-notification complete w-full">
                        <span className="text-base notification-text">
                            {translate('payouts.update_account_notification')}
                        </span>
                    </div>
                )}

                <Row className="w-full tenant-setting-containers" id="tenantSettingContainers">
                    <Col span={24}>
                        <Row className="tenant-setting-header-row px-6">
                            <Col
                                className="tenant-setting-header-col w-full h-full items-center justify-between"
                                span={24}
                            >
                                <h2 className="tenant-setting-header-title">
                                    {translate('settings.payout_setting', 'Payout setting')}
                                </h2>
                            </Col>
                        </Row>

                        <Row className=" tenant-setting-form-row px-6 pt-6 pb-8 w-full mb-6 header-action-row">
                            <Col span={24} className="content-action-col justify-start">
                                {account &&
                                (permissions || []).some((item) =>
                                    [
                                        PERMISSIONS.WL_PAYOUTS_EDIT,
                                        PERMISSIONS.POS_PAYOUTS_EDIT,
                                    ].includes(item),
                                ) ? (
                                    <Button
                                        icon={false}
                                        className="create-issue-button"
                                        type="primary"
                                        onClick={updateConnect}
                                    >
                                        <Image
                                            preview={false}
                                            src={'/images/icons/white-pen.svg'}
                                        />
                                        <span className="ml-2">
                                            {translate('payouts.update_account')}
                                        </span>
                                    </Button>
                                ) : null}
                                {!account &&
                                (permissions || []).some((item) =>
                                    [
                                        PERMISSIONS.WL_PAYOUTS_CREATE,
                                        PERMISSIONS.POS_PAYOUTS_CREATE,
                                    ].includes(item),
                                ) ? (
                                    <Button
                                        icon={false}
                                        className="create-issue-button"
                                        type="primary"
                                        onClick={connect}
                                    >
                                        <Image preview={false} src={'/images/icons/plus.svg'} />
                                        <span className="ml-2">
                                            {translate('payouts.create_account')}
                                        </span>
                                    </Button>
                                ) : null}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
            {(permissions || []).includes(PERMISSIONS.WL_SETTING_CREATE) ? (
                <div className="w-full mt-10">
                    <WLSettingLayout />
                </div>
            ) : null}
        </section>
    );
};
