import './styles.scss';

import { Button, Grid, Pagination, PaginationProps } from 'antd';
import { useTranslation } from 'react-i18next';

export const CustomPagination: React.FC<PaginationProps> = (props) => {
    const { className, ...res } = props;
    const { t } = useTranslation('common');

    const nextDisabled = (): boolean => {
        const next = Math.ceil((res?.total || 0) / (res.pageSize || 10));
        return (res.current || 1) >= next;
    };
    const breakpoint = Grid.useBreakpoint();

    const itemRender: (
        current: number,
        type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
        originalElement: React.ReactNode,
    ) => React.ReactNode = (current, type, originalElement) => {
        if (type === 'page') {
            return <div className={`page body-14-regular`}>{current}</div>;
        }
        if (type === 'prev') {
            return (
                <div className="previous-btn">
                    <Button disabled={res.current === 1}>
                        <img
                            src="/images/icons/left.svg"
                            alt="previous"
                            className="mr-2 icon-action"
                        />
                        {t('buttons.previous')}
                    </Button>
                </div>
            );
        }
        if (type === 'next') {
            return (
                <div className="next-btn">
                    <Button disabled={nextDisabled()}>
                        {t('buttons.next')}{' '}
                        <img
                            src="/images/icons/right.svg"
                            alt="next"
                            className="ml-2 icon-action"
                        />
                    </Button>
                </div>
            );
        }
        return originalElement;
    };

    return (
        <Pagination
            showLessItems={!breakpoint.md}
            showSizeChanger={breakpoint.xl}
            {...res}
            className={`pagination ${className || ''}`}
            itemRender={itemRender}
        />
    );
};
