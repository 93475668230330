import './styles.scss';

import { APPLICATIONS } from '@constants/index.constant';
import { NApplication } from '@interfaces/application';
import { useOne } from '@refinedev/core';
import { monthsToYear, yearToMonths } from '@utils/function';
import { useIsUsingStandardPlan } from '@utils/hooks/useIsUsingStandardPlan';
import { POS_PLAN_ID } from '@zodinet-pos/core-ui';
import { Button, Checkbox, Form, Input, InputNumber, Select } from 'antd';
import { EXPIRED_TIMES } from 'pages/extra-application-plan';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrencyType } from '../../enums/currency-type.enum';
import { SubscriptionOrderTypeEnum } from '../../enums/subscription-order-type.enum';
import { NRegistration } from '../../interfaces/registration';
import { formatPriceBaseOnCurrency } from '../../utils/resource';
import { validateIntNumber } from '../../utils/validator';
import { CustomModal } from '../modules/modal-custom';
import { IForm } from '.';

type IDrawerRegistrationForm = {
    type: SubscriptionOrderTypeEnum;
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    handlePayment: (values: IForm, totalPayment: number, isUsingAddOn?: boolean) => void;
    remainingMonths: number;
    registration: NRegistration.IRegistrationForDashboard;
    isCountByTableQty?: boolean;
    paymentFormula: (params: {
        price: number;
        expiredTime: number;
        qtyTable: number;
        fnbPrice?: number;
    }) => number;
};

export const UpgradeModal: React.FC<IDrawerRegistrationForm> = ({
    type,
    isOpen,
    isLoading,
    registration,
    remainingMonths,
    onClose,
    handlePayment,
    isCountByTableQty,
    paymentFormula,
}) => {
    const { t } = useTranslation(['common']);
    const [form] = Form.useForm();
    const [isRenderTotal, setIsRenderTotal] = useState<boolean>(false);
    const [isUsingAddOn, setIsUsingAddOn] = useState<boolean>(false);

    const isStandard = useIsUsingStandardPlan();

    const { data: extraAppPlans } = useOne<NApplication.IExtraAppPlanData>({
        resource: 'v1/application/extra-app-plans',
        id: APPLICATIONS.POS.id,
        queryOptions: {
            enabled: true,
        },
    });
    const addOnPlan = extraAppPlans?.data?.plans?.find((p) => p.id === POS_PLAN_ID.STANDARD_FNB);

    const renderTotalPayment = useMemo(() => {
        const values = form.getFieldsValue();

        let _qtyTable = registration?.qtyTable || 0,
            _expiredTime = remainingMonths || 0,
            _fnbPrice = registration.fnbPrice || 0;

        switch (type) {
            case SubscriptionOrderTypeEnum.UPGRADE_TABLE:
                _qtyTable = values.quantity;
                _fnbPrice = 0;
                break;

            case SubscriptionOrderTypeEnum.RENEW_LICENSE:
                _expiredTime = yearToMonths(values.quantity);
                if (isUsingAddOn) {
                    _fnbPrice = addOnPlan?.fnbPricing || 0;
                }
                break;
        }

        setIsRenderTotal(false);

        return paymentFormula({
            price: registration?.planPrice,
            expiredTime: _expiredTime,
            qtyTable: _qtyTable,
            fnbPrice: _fnbPrice || 0,
        });
    }, [isRenderTotal]);

    const renewOptions = [
        ...EXPIRED_TIMES,
        {
            value: 24,
        },
        {
            value: 36,
        },
    ];

    return (
        <CustomModal
            open={isOpen}
            footer={false}
            closable={!isLoading}
            onCancel={onClose}
            maskClosable={false}
            width={550}
        >
            <div>
                <p className="text-2xl italic">
                    {t(`plan_form.fields.service_package`)}{' '}
                    <span className="font-bold">
                        {t(
                            `plan_form.plan_type.${registration.extraAppPlanName.toLowerCase()}`,
                        ).toUpperCase()}{' '}
                    </span>
                </p>
                <p className="text-base mt-2 mb-7">
                    {isCountByTableQty ? (
                        <>
                            *{t('plan_form.current_table')}{' '}
                            {t(`plan_form.table_noti`, {
                                usedTable: registration.usedPlan.table || 0,
                                qtyTable: registration.qtyTable,
                            })}
                        </>
                    ) : (
                        <></>
                    )}
                </p>
            </div>
            <Form
                layout="vertical"
                className="upgrade-modal"
                form={form}
                onFinish={(value) => handlePayment(value, renderTotalPayment, isUsingAddOn)}
            >
                {type === SubscriptionOrderTypeEnum.UPGRADE_TABLE ? (
                    <>
                        <Form.Item
                            className="w-full"
                            label={
                                <>
                                    {t('plan_form.fields.upgrade_table')} (
                                    {formatPriceBaseOnCurrency(
                                        registration.planPrice,
                                        CurrencyType.Vnd,
                                    )}{' '}
                                    /{t('plan_form.pc_month')})
                                </>
                            }
                            name={'quantity'}
                            rules={[
                                {
                                    required: true,
                                    message: t('plan_form.fields.upgrade_table_required'),
                                },
                                {
                                    validator: async (_, value) => {
                                        if (value && !validateIntNumber(value))
                                            return Promise.reject(
                                                t('plan_form.fields.number_invalid', {
                                                    ns: 'common',
                                                }),
                                            );
                                        if (value === 0) {
                                            return Promise.reject(
                                                t('plan_form.fields.qty_table_greater_than_zero'),
                                            );
                                        }

                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputNumber
                                type="number"
                                pattern="[0-9]*"
                                placeholder={t('plan_form.fields.qty_table_placeholder')}
                                onChange={() => {
                                    setIsRenderTotal(true);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            className="mt-2"
                            label={t('plan_form.fields.reference_code')}
                            name={'referenceCode'}
                            initialValue={registration.referenceCode}
                        >
                            <Input placeholder={t('plan_form.fields.reference_code_placeholder')} />
                        </Form.Item>
                    </>
                ) : (
                    <>
                        <Form.Item
                            label={t('plan_form.fields.renew_license')}
                            name={'quantity'}
                            rules={[
                                {
                                    required: true,
                                    message: t('plan_form.fields.renew_license_required'),
                                },
                                {
                                    validator: async (_, value) => {
                                        if ((!value && value !== 0) || value > 0) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            t('plan_form.fields.expired_time_greater_than_zero'),
                                        );
                                    },
                                },
                            ]}
                        >
                            <Select
                                getPopupContainer={(trigger) => trigger.parentNode}
                                showSearch={false}
                                placeholder={t('plan_form.fields.expired_time_placeholder')}
                                onChange={() => {
                                    setIsRenderTotal(true);
                                }}
                            >
                                {renewOptions.map((time) => {
                                    return (
                                        <Select.Option
                                            key={time.value}
                                            value={monthsToYear(time.value)}
                                        >
                                            {time.value}{' '}
                                            {t('subscription_order.frequency_type.month')}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className="mt-2"
                            label={t('plan_form.fields.reference_code')}
                            name={'referenceCode'}
                            initialValue={registration.referenceCode}
                        >
                            <Input placeholder={t('plan_form.fields.reference_code_placeholder')} />
                        </Form.Item>

                        {isStandard &&
                        type === SubscriptionOrderTypeEnum.RENEW_LICENSE &&
                        registration.isTrial ? (
                            <Form.Item name="isAddOn" valuePropName="checked">
                                <Checkbox
                                    onChange={(e) => {
                                        setIsUsingAddOn(e.target.checked);
                                        setIsRenderTotal(true);
                                    }}
                                >
                                    {t('buy_add_on')}
                                </Checkbox>
                            </Form.Item>
                        ) : null}
                    </>
                )}
                <p className="mb-2">
                    *{t('plan_form.fields.total_price')}:{' '}
                    {formatPriceBaseOnCurrency(renderTotalPayment, CurrencyType.Vnd)}
                </p>
                <Button
                    type="primary"
                    className="w-full flex justify-center"
                    htmlType="submit"
                    loading={isLoading}
                >
                    {t('plan_form.actions.payment_btn')}
                </Button>
            </Form>
        </CustomModal>
    );
};
