/* eslint-disable @typescript-eslint/no-unused-vars */
import './styles.scss';

import { CameraOutlined, CloseOutlined } from '@ant-design/icons';
import { getPublicMediaUrl } from '@utils/resource';
import { Button, message, Popconfirm, Spin } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import Upload from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';
import imageCompression from 'browser-image-compression';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BaseApi from '../../../services/api/baseApi';
import { getNaturalImageSize } from '../../../utils/check-min-width';

const baseApi = new BaseApi();
export const ImageUploadWithCrop: React.FC<{
    onChange?: (file: File) => void;
    onDelete?: () => void;
    size?: number;
    width: number;
    height: number;
    quality?: number;
    uploadText: string;
    value?: string;
    minWidth?: number;
}> = ({
    onChange,
    size = 5,
    width,
    height,
    quality = 1,
    uploadText,
    value,
    minWidth,
    onDelete,
}) => {
    const { t } = useTranslation('common');
    const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);

    const [imageUrl, setImageUrl] = useState<string | null>();

    useEffect(() => {
        if (value) {
            setImageUrl(getPublicMediaUrl(value));
        } else {
            setImageUrl(null);
        }
    }, [value]);

    const getBase64 = (img: RcFile | File, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        setIsLoadingUpload(true);
        const controller = new AbortController();
        const options = {
            maxSizeMB: 5,
            signal: controller.signal,
        };
        imageCompression(file, options)
            .then(function (compressedFile) {
                getBase64(compressedFile, (url) => {
                    setImageUrl(url);
                    onChange && onChange(compressedFile);
                });
                setIsLoadingUpload(false);
                return false;
            })
            .catch(function (error) {
                console.error('error.message', error.message);
                setIsLoadingUpload(false);
                return false;
            });
    };

    const beforeCrop = async (file: File, fileList: File[]) => {
        if (!minWidth) {
            return true;
        }

        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt = file.size / 1024 / 1024 < size;
        if (!isLt) {
            message.error(`Image must smaller than ${size}MB!`);
        }

        if (!(isJpgOrPng && isLt)) {
            return false;
        }

        const result = await getNaturalImageSize(file);
        if (!result) {
            return false;
        }

        if (result.width < minWidth) {
            baseApi.createErrorNotification(t('min_width', { width: minWidth }));
            return false;
        }

        return true;
    };

    return (
        <Spin spinning={isLoadingUpload}>
            <ImgCrop
                modalTitle={t('edit_image')}
                modalOk={t('ok')}
                modalCancel={t('cancel')}
                minZoom={0.1}
                aspect={width / height}
                quality={quality}
                beforeCrop={beforeCrop}
            >
                <Upload
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    maxCount={1}
                >
                    <div className="relative h-full w-full">
                        {imageUrl && (
                            <img
                                src={imageUrl}
                                alt="img"
                                className="rounded-xl img w-auto h-full m-auto"
                            />
                        )}
                        <div className={`${!imageUrl ? 'upload' : 'unload'}`}>
                            <Button className="btn-upload">
                                <CameraOutlined />
                                <span className="upload-text">{uploadText}</span>
                            </Button>
                        </div>
                        {onDelete && imageUrl ? (
                            <div
                                className="absolute top-0 right-0 h-8 w-8"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Popconfirm
                                    title={t('image.confirm_delete')}
                                    onConfirm={() => {
                                        setImageUrl(null);
                                        onDelete();
                                    }}
                                    onCancel={() => {
                                        return;
                                    }}
                                    okText={t('yes')}
                                    cancelText={t('no')}
                                >
                                    <CloseOutlined
                                        style={{ fontSize: '24px', color: 'red' }}
                                        className="h-full w-full flex justify-center"
                                    />
                                </Popconfirm>
                            </div>
                        ) : null}
                    </div>
                </Upload>
            </ImgCrop>
        </Spin>
    );
};
