import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './styles.scss';

import { PERMISSIONS } from '@constants/permission';
import { usePermissions } from '@refinedev/core';
import { validPermission } from '@utils/resource';
import { Button } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { APPLICATIONS } from '../../constants/index.constant';
import { IMarketplace } from '../../interfaces/marketplace';

// Using multi applications
// const settings = {
//     dots: false,
//     infinite: false,
//     arrows: false,
//     variableWidth: true,
//     speed: 300,
//     swipeToSlide: false,
//     slidesToShow: 2,
//     slidesToScroll: 2,
// };

const getApplicationsContent = (
    id: string,
): { title: string; description: string; buttonTitle: string; activeModule: boolean } => {
    const { data: permissions } = usePermissions<string[]>();

    let title = '',
        description = '',
        buttonTitle = 'try_now',
        activeModule = true;
    switch (id) {
        case APPLICATIONS.PAYGATE.id: {
            activeModule = validPermission(permissions || [], PERMISSIONS.PAYGATE_LIST);
            title = 'paygate';

            if (activeModule) {
                buttonTitle = 'add_transaction';
                description = 'paygate.used_case.description';
                break;
            }

            description = 'paygate.unused_case.description';
            break;
        }

        case APPLICATIONS.TAX.id: {
            activeModule = validPermission(permissions || [], PERMISSIONS.TAX_LIST);
            title = 'tax';

            if (activeModule) {
                buttonTitle = 'add_transaction';
                description = 'tax.used_case.description';
                break;
            }

            description = 'tax.unused_case.description';
            break;
        }
    }

    return { title, description, buttonTitle, activeModule };
};

const Item: FC<{
    application: IMarketplace.IApplication;
    onChangeOpenModal: (value: boolean, application: IMarketplace.IApplication) => void;
}> = ({ application, onChangeOpenModal }) => {
    const { t } = useTranslation('common');
    const { title, description, buttonTitle, activeModule } = getApplicationsContent(
        application.id,
    );

    return (
        <div className="mx-5 my-4">
            {/* Decorative Circles */}
            <div className="absolute top-[-40px] left-[-95px] w-56 h-56 bg-[#F5852433] rounded-full z-0"></div>
            <div className="absolute top-[-60px] right-[-40px] w-32 h-32 bg-[#F5852433] rounded-full z-0"></div>
            <div className="mb-3 relative z-1">
                <h3 className="text-lg font-semibold mb-1">{t(`modules_banner.${title}.title`)}</h3>
                <p className="text-sm font-normal mx-6 h-10 flex justify-center items-center">
                    {t(`modules_banner.${description}`)}
                </p>
            </div>
            <Button
                type="primary"
                className="button-action"
                onClick={() => {
                    onChangeOpenModal(true, application);
                }}
                icon={activeModule ? <img src="/images/icons/star.svg" className="w-5" /> : null}
            >
                <p>{t(`modules_banner.actions.${buttonTitle}`)}</p>
            </Button>
        </div>
    );
};

export const ModulesBanner: FC<{
    applications: IMarketplace.IApplication[];
    onChangeOpenModal: (value: boolean) => void;
}> = ({ onChangeOpenModal, applications }) => {
    return (
        <>
            <section className="my-5 modules-banner">
                {/* Using multi applications */}
                {/* <div className="slider-container">
                    <Slider {...settings}>
                        {applications?.map((application) => (
                            <div
                                className="item relative overflow-hidden rounded-lg shadow-md"
                                key={application.id}
                            >
                                <Item
                                    application={application}
                                    onChangeOpenModal={onChangeOpenModal}
                                    active={activeApplications.includes(application.id)}
                                />
                            </div>
                        ))}
                    </Slider>
                </div> */}

                {/* Using for 2 applications */}
                <div className="flex flex-col lg:flex-row justify-between gap-5">
                    {applications?.map((application) => (
                        <div
                            className="flex-1 item relative overflow-hidden rounded-lg"
                            key={application.id}
                        >
                            <Item application={application} onChangeOpenModal={onChangeOpenModal} />
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
};
