import { DeleteOutlined } from '@ant-design/icons';
import { Button, Menu, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

export const PopupDelete: React.FC<{ id: string; remove: (id: string) => void }> = ({
    id,
    remove,
}) => {
    const { t } = useTranslation('common');

    return (
        <>
            <Menu
                className="w-full"
                items={[
                    {
                        key: 'delete',
                        label: (
                            <Popconfirm
                                placement="topLeft"
                                title={t('delete_confirm', { ns: 'common' })}
                                onConfirm={() => remove(id)}
                                okText={t('delete', { ns: 'common' })}
                                cancelText={t('no', { ns: 'common' })}
                                className="w-full"
                                icon={<></>}
                            >
                                <Button
                                    className="p-0 border-0 delete-button"
                                    icon={<DeleteOutlined />}
                                >
                                    {t('delete', { ns: 'common' })}
                                </Button>
                            </Popconfirm>
                        ),
                    },
                ]}
            />
        </>
    );
};
