import { NCollaborator } from '@interfaces/collaborator';
import { HttpError, useApiUrl, useCustom } from '@refinedev/core';
import Response from '@responses/response';
import { camelToSnakeCase } from '@utils/string';
import { DebounceAsyncValidator } from '@utils/validator';
import { Col, Form, FormProps, Input, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

type CollaboratorDetailFormProps = {
    formProps: FormProps;
    isEditMode: boolean;
    id?: string;
};

const enum FieldTypeEnum {
    CODE = 'code',
    EMAIL = 'email',
    DISCOUNT = 'discount',
    FULL_NAME = 'fullName',
    PHONE_NUMBER = 'phoneNumber',
}

export const CollaboratorDetailForm: FC<CollaboratorDetailFormProps> = ({
    formProps,
    isEditMode,
    id,
}) => {
    const apiUrl = useApiUrl();
    const { t } = useTranslation(['collaborator', 'common']);

    const [code, setCode] = useState<string>('');

    const { refetch } = useCustom<Response<boolean>, HttpError, NCollaborator.ICheckUniqueCode>({
        method: 'post',
        url: `${apiUrl}/v1/collaborator/check-unique-code`,
        config: {
            payload: { code: code },
        },
        queryOptions: {
            enabled: false,
        },
    });

    const asyncValidateUniqueCode = DebounceAsyncValidator(400, async () => {
        const { data } = await refetch();

        if (data && data?.data?.data) {
            return true;
        }

        return false;
    });

    const initialValues: any = !isEditMode
        ? {}
        : {
              ...formProps.initialValues,
          };

    return (
        <Form
            {...formProps}
            layout="vertical"
            initialValues={{ ...initialValues }}
            onFinish={(values) => {
                if (id) {
                    values.id = id;
                }

                values.discount = Number(values.discount);

                formProps.onFinish && formProps.onFinish(values);
            }}
            className={s.collaboratorFormContainer}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name={FieldTypeEnum.EMAIL}
                        label={t(`fields.${FieldTypeEnum.EMAIL}`)}
                        rules={[
                            {
                                required: true,
                                message: t(`fields.${FieldTypeEnum.EMAIL}_required`),
                            },
                            {
                                type: 'email',
                                message: t(`fields.${FieldTypeEnum.EMAIL}_invalid`),
                            },
                        ]}
                    >
                        <Input
                            maxLength={255}
                            placeholder={t(`fields.${FieldTypeEnum.EMAIL}_placeholder`)}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={FieldTypeEnum.PHONE_NUMBER}
                        label={t(`fields.${camelToSnakeCase(FieldTypeEnum.PHONE_NUMBER)}`)}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    `fields.${camelToSnakeCase(
                                        FieldTypeEnum.PHONE_NUMBER,
                                    )}_required`,
                                ),
                            },
                        ]}
                    >
                        <MaskedInput
                            mask={'+84 (000)-000-000'}
                            placeholder={t(
                                `fields.${camelToSnakeCase(
                                    FieldTypeEnum.PHONE_NUMBER,
                                )}_placeholder`,
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={FieldTypeEnum.FULL_NAME}
                        label={t(`fields.${camelToSnakeCase(FieldTypeEnum.FULL_NAME)}`)}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    `fields.${camelToSnakeCase(FieldTypeEnum.FULL_NAME)}_required`,
                                ),
                            },
                        ]}
                    >
                        <Input
                            maxLength={255}
                            placeholder={t(
                                `fields.${camelToSnakeCase(FieldTypeEnum.FULL_NAME)}_placeholder`,
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={FieldTypeEnum.DISCOUNT}
                        label={t(`fields.${FieldTypeEnum.DISCOUNT}`)}
                        rules={[
                            {
                                required: true,
                                message: t(`fields.${FieldTypeEnum.DISCOUNT}_required`),
                            },
                            {
                                validator: (_, value) => {
                                    if (!value) {
                                        return Promise.resolve();
                                    }

                                    if (Number(value) < 0) {
                                        return Promise.reject(
                                            t(`fields.${FieldTypeEnum.DISCOUNT}_min`),
                                        );
                                    }

                                    if (Number(value) > 100) {
                                        return Promise.reject(
                                            t(`fields.${FieldTypeEnum.DISCOUNT}_max`),
                                        );
                                    }

                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input
                            type="number"
                            addonAfter="%"
                            pattern="[0-9]*"
                            placeholder={t(`fields.${FieldTypeEnum.DISCOUNT}_placeholder`)}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={FieldTypeEnum.CODE}
                        label={t(`fields.${FieldTypeEnum.CODE}`)}
                        rules={[
                            {
                                required: true,
                                message: t(`fields.${FieldTypeEnum.CODE}_required`),
                            },
                            {
                                validator: async (_, value) => {
                                    if (
                                        value === '' ||
                                        !value ||
                                        (initialValues?.code && initialValues.code === value)
                                    ) {
                                        return Promise.resolve();
                                    }

                                    const result = await asyncValidateUniqueCode();
                                    if (result) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(t(`fields.${FieldTypeEnum.CODE}_unique`));
                                },
                            },
                        ]}
                    >
                        <Input
                            maxLength={20}
                            onChange={(e) => setCode(e.target.value)}
                            placeholder={t(`fields.${FieldTypeEnum.CODE}_placeholder`)}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
