import { Button } from 'antd';
export const ApplicationSettingIndex: React.FC = () => {
    const onConnectedClick = () => {
        window.open(
            'https://oauth.zaloapp.com/v4/oa/permission?app_id=1934090375602228650&redirect_uri=https%3A%2F%2Fportal.ffood.com.vn%2Fcallback%2Fzalo',
        );
    };

    return (
        <>
            <Button type="primary" onClick={onConnectedClick}>
                Kết nối Zalo OA
            </Button>
        </>
    );
};
