import { Image, Modal, ModalProps } from 'antd';
import React from 'react';

import s from './modal.module.scss';

export const CustomModal: React.FC<ModalProps> = ({ children, ...rest }) => {
    return (
        <Modal
            centered
            wrapClassName={`${s.modalCustom} ${rest.wrapClassName}`}
            {...rest}
            closeIcon={<Image src="/icons/bt_close.svg" preview={false} />}
        >
            {children}
        </Modal>
    );
};
