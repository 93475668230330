import './styles.scss';

import { DATE_FORMAT } from '@constants/index.constant';
import { useTable } from '@refinedev/antd';
import { CrudFilters, LogicalFilter, useList, useTranslate } from '@refinedev/core';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';

import { LineChart } from '../../components/charts/line-chart';
import { IMarketplace } from '../../interfaces/marketplace';
import { IReport } from '../../interfaces/report';

const REPORT_RESOURCE_URL = 'v1/report';
const APPLICATION_RESOURCE_URL = 'v1/application';

const DEFAULT_RANGE_DATE: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs(dayjs().add(-1, 'month'), DATE_FORMAT),
    dayjs(dayjs(), DATE_FORMAT),
];
export const SaRevenueDefaultIndex: React.FC = () => {
    const translate = useTranslate();

    const [dateRangeEarning, setDateRangeEarning] = useState(DEFAULT_RANGE_DATE);
    const [granularityEarning, setGranularityEarning] = useState<string>('day');

    const [dateRangeSubscription, setDateRangeSubscription] = useState(DEFAULT_RANGE_DATE);
    const [granularitySubscription, setGranularitySubscription] = useState<string>('day');

    const { tableProps: dataEarning, setFilters: setFiltersEarning } = useTable<IReport.IRevenue>({
        resource: `${REPORT_RESOURCE_URL}/sa-earning`,
        initialFilter: [
            {
                field: 'granularity',
                operator: 'eq',
                value: 'day',
            },
            {
                field: 'dateStart',
                operator: 'eq',
                value: dayjs().subtract(1, 'months').format(DATE_FORMAT),
            },
            {
                field: 'dateEnd',
                operator: 'eq',
                value: dayjs().format(DATE_FORMAT),
            },
        ],
        hasPagination: false,
    });
    const { tableProps: dataTrackingSubscription, setFilters: setFiltersTrackingSubscription } =
        useTable<IReport.IRevenue>({
            resource: `${REPORT_RESOURCE_URL}/sa-tracking-subscription`,
            initialFilter: [
                {
                    field: 'granularity',
                    operator: 'eq',
                    value: 'day',
                },
                {
                    field: 'dateStart',
                    operator: 'eq',
                    value: dayjs().subtract(1, 'months').format(DATE_FORMAT),
                },
                {
                    field: 'dateEnd',
                    operator: 'eq',
                    value: dayjs().format(DATE_FORMAT),
                },
            ],
            hasPagination: false,
        });
    const { data: listApplications } = useList<IMarketplace.IApplication>({
        resource: `${APPLICATION_RESOURCE_URL}`,
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'includeUnpublish',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
    });

    const filterByDateRange = (prev: CrudFilters, dayString: string[]) => {
        const newFilter = prev;
        const indexDateStart = newFilter.findIndex(
            (item) => (item as LogicalFilter).field === 'dateStart',
        );
        const indexDateEnd = newFilter.findIndex(
            (item) => (item as LogicalFilter).field === 'dateEnd',
        );
        if (indexDateStart !== -1 && indexDateEnd !== -1) {
            (newFilter[indexDateStart] as LogicalFilter).value = dayString[0];
            (newFilter[indexDateEnd] as LogicalFilter).value = dayString[1];
        } else {
            newFilter.push({
                field: 'dateStart',
                operator: 'eq',
                value: dayString[0],
            });
            newFilter.push({
                field: 'dateEnd',
                operator: 'eq',
                value: dayString[1],
            });
        }
        return newFilter;
    };

    const filterByGranularity = (prev: CrudFilters, value: string) => {
        const newFilter = prev;
        const index = newFilter.findIndex(
            (item) => (item as LogicalFilter).field === 'granularity',
        );

        if (index !== -1) {
            (newFilter[index] as LogicalFilter).value = value;
        } else {
            newFilter.push({
                field: 'granularity',
                operator: 'eq',
                value: value,
            });
        }
        return newFilter;
    };

    const filterByApplication = (prev: CrudFilters, value: string) => {
        const newFilter = prev;
        const index = newFilter.findIndex(
            (item) => (item as LogicalFilter).field === 'application',
        );

        if (index !== -1) {
            (newFilter[index] as LogicalFilter).value = value;
        } else {
            newFilter.push({
                field: 'application',
                operator: 'eq',
                value: value,
            });
        }
        return newFilter;
    };

    const onChangeDateRangeEarning = (dayString: string[]) => {
        setDateRangeEarning([dayjs(dayString[0], DATE_FORMAT), dayjs(dayString[1], DATE_FORMAT)]);
        setFiltersEarning((prev) => filterByDateRange(prev, dayString));
    };

    const onChangeGranularityEarning = (value: string) => {
        setGranularityEarning(value);
        setFiltersEarning((prev) => filterByGranularity(prev, value));
    };

    const onChangeApplicationEarning = (value: string) => {
        setFiltersEarning((prev) => filterByApplication(prev, value));
    };
    const onChangeDateRangeTracking = (dayString: string[]) => {
        setDateRangeSubscription([
            dayjs(dayString[0], DATE_FORMAT),
            dayjs(dayString[1], DATE_FORMAT),
        ]);
        setFiltersTrackingSubscription((prev) => filterByDateRange(prev, dayString));
    };

    const onChangeGranularityTracking = (value: string) => {
        setGranularitySubscription(value);
        setFiltersTrackingSubscription((prev) => filterByGranularity(prev, value));
    };

    const onChangeApplicationTracking = (value: string) => {
        setFiltersTrackingSubscription((prev) => filterByApplication(prev, value));
    };

    return (
        <>
            <div className="sa-revenue-page-wrapper">
                <Row gutter={[32, 32]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                        <div className="total-earning-chart">
                            <LineChart
                                title={translate('reports.titleEarningChart')}
                                onChangeDateRange={onChangeDateRangeEarning}
                                onChangeGranularity={onChangeGranularityEarning}
                                onChangeApplication={onChangeApplicationEarning}
                                data={dataEarning?.dataSource ? [...dataEarning.dataSource] : []}
                                valueDateRange={dateRangeEarning}
                                valueGranularity={granularityEarning}
                                applications={listApplications?.data}
                            />
                        </div>
                        <div className="total-subscription-chart">
                            <LineChart
                                title={translate('reports.titleSubscriptionChart')}
                                onChangeDateRange={onChangeDateRangeTracking}
                                onChangeGranularity={onChangeGranularityTracking}
                                onChangeApplication={onChangeApplicationTracking}
                                data={
                                    dataTrackingSubscription?.dataSource
                                        ? [...dataTrackingSubscription.dataSource]
                                        : []
                                }
                                valueDateRange={dateRangeSubscription}
                                valueGranularity={granularitySubscription}
                                isFormatData={false}
                                applications={listApplications?.data}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
