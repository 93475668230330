import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { IResourceComponentsProps, useApiUrl, useCustom, useCustomMutation } from '@refinedev/core';
import Response from '@responses/response';
import { Button, Card, Col, notification, Row, Spin, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { MARKETPLACE_SETTING_RESOURCE } from '../../constants/index.constant';
import { getEnvConfig } from '../../getEnvConfig';
import { IMarketplaceSetting } from '../../interfaces/components/marketplace-setting';
import { Hint } from '../modules/hint';
import { ImageUploadWithCrop } from '../modules/image-upload-with-crop';
import { BannerSetting } from './banner-setting';

export const MarketplaceSettings: React.FC<IResourceComponentsProps<unknown>> = () => {
    const marketAPI = getEnvConfig.MARKET_API_URL;
    const { mutate: customMate, isLoading: isLoading } = useCustomMutation<Response<boolean>>();
    const apiUrl = useApiUrl();

    const { t } = useTranslation('marketplace');

    const { data: setting, isLoading: isLoadingSetting } = useCustom<
        Response<IMarketplaceSetting.IMarketplaceSettingInfo>
    >({
        url: `${marketAPI}/${MARKETPLACE_SETTING_RESOURCE}/banner`,
        method: 'get',
    });

    const [isExistImage, setIsExistImage] = useState<boolean>(false);

    const onDeleteFallbackImage = async () => {
        setIsExistImage(false);
        customMate({
            url: `${marketAPI}/${MARKETPLACE_SETTING_RESOURCE}/delete-fallback-image/${setting?.data?.data?.id}`,
            method: 'put',
            values: {},
            successNotification: {
                message: t('marketplace.delete_img_success'),
                type: 'success',
            },
            errorNotification: {
                message: t('marketplace.delete_img_err'),
                type: 'error',
            },
        });
    };

    const onChangeDefaultBanner = async (file: File) => {
        setIsExistImage(true);
        const formData = new FormData();
        formData.append('file', file);

        customMate({
            url: `${marketAPI}/${MARKETPLACE_SETTING_RESOURCE}/upload-fallback-image`,
            method: 'post',
            values: formData,
            successNotification: {
                message: t('marketplace.upload_img_success'),
                type: 'success',
            },
            errorNotification: {
                message: t('marketplace.upload_img_err'),
                type: 'error',
            },
        });
    };

    const [items, setItems] = useState<Array<IMarketplaceSetting.ICreateMarketplaceSetting>>([]);

    useEffect(() => {
        if (setting?.data?.data?.banners?.length) {
            setItems(setting?.data?.data?.banners);
        }
        if (setting?.data?.data?.imageFallbackUrl) {
            setIsExistImage(true);
        }
    }, [setting]);

    const addTab = () => {
        const newPanes = [...items];
        const addNewTab = {
            id: uuidv4(),
        };
        newPanes.push(addNewTab);
        setItems(newPanes);
    };

    const onDeleteBannerSetting = (bannerId: string) => {
        customMate(
            {
                url: `${marketAPI}/${MARKETPLACE_SETTING_RESOURCE}/banner/${bannerId}`,
                method: 'put',
                values: {},
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: (data) => {
                    const response = data.data;
                    if (response.isSuccess) {
                        notification.success({
                            message: t('marketplace.delete_img_success'),
                        });
                        let newPanes = [...items];
                        newPanes = newPanes.filter((banner) => banner.id !== bannerId) || [];
                        setItems(newPanes);
                    } else {
                        notification.error({ message: t('marketplace.delete_img_err') });
                    }
                },
            },
        );
    };

    return (
        <>
            <Spin spinning={isLoadingSetting || isLoading}>
                <section className="marketplace-setting w-full rounded-xl">
                    <h3 className="mb-0">{t('marketplace.marketplace_setting')}</h3>
                    <Row>
                        <Col xs={24} xl={12}>
                            <h4 className="mt-3 font-bold">
                                {t('marketplace.fallback_image_banner')}
                            </h4>
                            <div
                                className={`mt-2 banner ${!isExistImage ? 'banner-not-value' : ''}`}
                            >
                                <ImageUploadWithCrop
                                    width={430}
                                    minWidth={430}
                                    height={175}
                                    uploadText={t('marketplace.upload')}
                                    onChange={(fileOrn) => onChangeDefaultBanner(fileOrn)}
                                    value={setting?.data?.data?.imageFallbackUrl ?? ''}
                                    onDelete={onDeleteFallbackImage}
                                />
                                <Hint width={430} height={175} size={5} fileType={['JPG', 'PNG']} />
                            </div>
                        </Col>
                    </Row>

                    <div>
                        <div className="flex justify-end">
                            <Button type="primary" icon={<PlusOutlined />} onClick={addTab}>
                                {t('marketplace.add_banner_setting')}
                            </Button>
                        </div>

                        <Tabs type="card" className="mt-2 tab-payment-method">
                            {(items || []).length &&
                                (items || []).map((setting, index) => (
                                    <Tabs.TabPane tab={`Banner ${index + 1}`} key={setting?.id}>
                                        <Card>
                                            <BannerSetting
                                                setting={setting}
                                                onDeleteBannerSetting={onDeleteBannerSetting}
                                            ></BannerSetting>
                                        </Card>
                                    </Tabs.TabPane>
                                ))}
                        </Tabs>
                    </div>
                </section>
            </Spin>
        </>
    );
};
