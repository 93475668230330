import { AntDesignOutlined, PlusOutlined } from '@ant-design/icons';
import { mapAntdSorterToCrudSorting, useDrawerForm } from '@refinedev/antd';
import { IResourceComponentsProps, useTable } from '@refinedev/core';
import { BusinessTypeEnum } from '@zodinet-pos/core-ui';
import { Avatar, Button, Dropdown, Menu, notification, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';

import { CustomSearch } from '../../components/modules/custom-search';
import { CustomPagination } from '../../components/modules/pagination';
import { PAGE_SIZE } from '../../constants/index.constant';
import { ItemVisibilityEnum } from '../../enums';
import { NBusinessType } from '../../interfaces/business-type';
import { getPublicMediaUrl } from '../../utils/resource';
import { Chip } from '../modules/chip';
import { BusinessTypeDetail } from './detail/detail';

type Prop = {
    name?: string;
};
export const BusinessTypes: React.FC<IResourceComponentsProps<unknown, Prop>> = () => {
    const { t, i18n } = useTranslation(['business_type', 'common']);

    const { setFilters, tableQueryResult, current, setCurrent, pageSize, setPageSize, setSorters } =
        useTable<NBusinessType.IInfo>({
            resource: 'v1/business-type',
            sorters: {
                initial: [
                    {
                        field: 'name',
                        order: 'asc',
                    },
                ],
            },
            filters: {
                initial: [],
            },
            pagination: {
                mode: 'server',
                pageSize: PAGE_SIZE,
            },
        });

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<NBusinessType.IInfo>({
        action: 'create',
        resource: 'v1/business-type',
        successNotification: { message: t('create_successfully'), type: 'success' },
        redirect: false,
        onMutationSuccess: () => {
            createFormProps.form.resetFields();
        },
        onMutationError: (error) => {
            notification.error({
                message: error?.message
                    ? t(`notifications.errors.${error?.response?.data?.error}`, { ns: 'common' })
                    : t('notifications.errors.create_business_type_failed', { ns: 'common' }),
                type: 'error',
            });
        },
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<NBusinessType.IInfo>({
        action: 'edit',
        resource: 'v1/business-type',
        successNotification: { message: 'Successfully edited', type: 'success' },
        redirect: false,
    });

    const menu = (id: string) => (
        <Menu mode="vertical">
            <Menu.Item
                key="1"
                icon={<img src="/images/icons/edit.svg" alt="edit" className="cursor-pointer" />}
                onClick={() => {
                    editShow(id);
                }}
            >
                {t('actions.edit', { ns: 'common' })}
            </Menu.Item>
        </Menu>
    );

    const onSearch = (event: any) => {
        setCurrent(1);
        setFilters([
            {
                field: 'filter',
                operator: 'eq',
                value: event?.target?.value,
            },
        ]);
    };

    const onChangeTable = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const renderStatus = (visibility: ItemVisibilityEnum) => {
        let label = '';
        let type = '';
        switch (visibility) {
            case ItemVisibilityEnum.VISIBLE:
                label = t(`visibility.VISIBLE`);
                type = 'success';
                break;
            case ItemVisibilityEnum.HIDDEN:
                label = t('visibility.HIDDEN');
                type = 'warning';
                break;
            case ItemVisibilityEnum.UNAVAILABLE:
                label = t('visibility.UNAVAILABLE');
                type = 'disable';
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    const renderType = (visibility: BusinessTypeEnum) => {
        let label = '';
        let type = '';
        switch (visibility) {
            case BusinessTypeEnum.NET:
                label = i18n.exists(`types.${BusinessTypeEnum.NET}`, { ns: 'business_type' })
                    ? t(`types.${BusinessTypeEnum.NET}`)
                    : BusinessTypeEnum.NET;
                type = 'warning';
                break;
            case BusinessTypeEnum.OTHER:
                label = i18n.exists(`types.${BusinessTypeEnum.OTHER}`, { ns: 'business_type' })
                    ? t(`types.${BusinessTypeEnum.OTHER}`)
                    : BusinessTypeEnum.OTHER;
                type = 'disable';
                break;
            case BusinessTypeEnum.COMBO:
                label = i18n.exists(`types.${BusinessTypeEnum.COMBO}`, { ns: 'business_type' })
                    ? t(`types.${BusinessTypeEnum.COMBO}`)
                    : BusinessTypeEnum.COMBO;
                type = 'success';
                break;

            case BusinessTypeEnum.FNB:
                label = i18n.exists(`types.${BusinessTypeEnum.FNB}`, { ns: 'business_type' })
                    ? t(`types.${BusinessTypeEnum.FNB}`)
                    : BusinessTypeEnum.FNB;
                type = 'disable';
                break;

            case BusinessTypeEnum.RETAIL:
                label = i18n.exists(`types.${BusinessTypeEnum.RETAIL}`, { ns: 'business_type' })
                    ? t(`types.${BusinessTypeEnum.RETAIL}`)
                    : BusinessTypeEnum.RETAIL;
                type = 'disable';
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    return (
        <>
            <BusinessTypeDetail
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                isEditMode={false}
            />
            <BusinessTypeDetail
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
                isEditMode={true}
            />
            <section className="partner-list-container">
                <div className="list-header">
                    <CustomSearch
                        placeholder={t('search')}
                        className={'search-item'}
                        onChange={(event: any) => onSearch(event)}
                        allowClear={true}
                    />
                    <Button className="btn-add-new" type="primary" onClick={() => createShow()}>
                        <PlusOutlined />
                        {t('create')}
                    </Button>
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult?.data?.data}
                            pagination={false}
                            rowKey="id"
                            onChange={onChangeTable}
                        >
                            <Table.Column
                                title={<>{t('fields.name.label')}</>}
                                dataIndex="name"
                                key="name"
                                sorter
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            />

                            <Table.Column
                                title={<>{t('fields.code.label')}</>}
                                dataIndex="code"
                                key="code"
                                sorter
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            />

                            <Table.Column
                                sorter
                                title={<>{t('fields.visibility.label')}</>}
                                dataIndex="visibility"
                                key="visibility"
                                render={(text, _) => renderStatus(text)}
                            />

                            <Table.Column
                                title={<>{t('fields.image_url.label')}</>}
                                dataIndex="imageUrl"
                                key="imageUrl"
                                sorter
                                render={(row, record: NBusinessType.IInfo) => (
                                    <div className="flex justify-between items-center ">
                                        <div className="flex justify-start items-center">
                                            <Avatar
                                                size={60}
                                                src={
                                                    record.imageUrl
                                                        ? getPublicMediaUrl(record.imageUrl)
                                                        : null
                                                }
                                                icon={<AntDesignOutlined />}
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                            <Table.Column
                                title={<>{t('fields.image_fallback_url.label')}</>}
                                dataIndex="imageFallbackUrl"
                                key="imageFallbackUrl"
                                sorter
                                render={(row, record: NBusinessType.IInfo) => (
                                    <div className="flex justify-between items-center ">
                                        <div className="flex justify-start items-center">
                                            <Avatar
                                                size={60}
                                                src={
                                                    record.imageUrl
                                                        ? getPublicMediaUrl(record.imageUrl)
                                                        : null
                                                }
                                                icon={<AntDesignOutlined />}
                                            />
                                        </div>
                                    </div>
                                )}
                            />

                            <Table.Column
                                sorter
                                title={<>{t('fields.type.label')}</>}
                                dataIndex="type"
                                key="type"
                                width={300}
                                render={(text, _) => renderType(text)}
                            />
                            <Table.Column
                                dataIndex="id"
                                key="action"
                                render={(id: string) => (
                                    <div className="flex justify-end items-center">
                                        <Dropdown
                                            overlay={menu(id)}
                                            placement="bottomRight"
                                            arrow
                                            overlayClassName="contact-dropdown-container"
                                            trigger={['click']}
                                        >
                                            <img
                                                src="/images/icons/dots-vertical.svg"
                                                alt="more"
                                                className="cursor-pointer"
                                            />
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </Table>
                    </div>
                </div>

                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>
        </>
    );
};
