import { IResourceComponentsProps } from '@refinedev/core';

import { BusinessTypes } from '../../components/business-type/list';

type Prop = {
    name?: string;
};
export const BusinessIndex: React.FC<IResourceComponentsProps<unknown, Prop>> = () => {
    return <BusinessTypes />;
};
