import './styles.scss';

import { CheckOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { PlanFrequencyEnum } from '@enums/plan-frequency.enum';
import { mapAntdSorterToCrudSorting } from '@refinedev/antd';
import { useApiUrl, useCustomMutation, useDelete, useTable } from '@refinedev/core';
import { checkCountTableQty, isFreePlan } from '@utils/plan';
import { POS_PLAN_ID } from '@zodinet-pos/core-ui';
import type { MenuProps } from 'antd';
import { Dropdown, notification, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { CustomSearch } from '../../components/modules/custom-search';
import { CustomPagination } from '../../components/modules/pagination';
import { DATE_FORMAT_MINUTE, PAGE_SIZE } from '../../constants/index.constant';
import { NRegistration } from '../../interfaces/registration';
import { formatDate } from '../../utils/resource';

export const RegistrationIndex: React.FC = () => {
    const { t } = useTranslation(['registration']);
    const { mutate } = useCustomMutation();
    const apiUrl = useApiUrl();

    const { setFilters, tableQueryResult, current, setCurrent, pageSize, setPageSize, setSorters } =
        useTable<NRegistration.IRegistrationInfo>({
            resource: 'v1/registration',
            sorters: {
                initial: [
                    {
                        field: 'created_at',
                        order: 'asc',
                    },
                ],
            },
            filters: {},
            pagination: {
                mode: 'server',
                pageSize: PAGE_SIZE,
            },
        });

    const renderItems = (id: string, record: NRegistration.IRegistrationInfo) => {
        let items: MenuProps['items'] = [];
        if (canActive(record)) {
            items = [
                {
                    key: '1',
                    label: t('actions.activate', { ns: 'common' }),
                    icon: <CheckOutlined />,
                    onClick: () => {
                        handleActive(id);
                    },
                },
            ];
        } else if (record.referenceCode) {
            items = [
                {
                    key: '1',
                    label: t('actions.resend_mail', { ns: 'common' }),
                    icon: (
                        <img
                            src="/images/icons/send.svg"
                            alt="edit"
                            className="cursor-pointer mr-3"
                        />
                    ),
                    onClick: () => {
                        record.id && handleResendMailToCollaborator(record.id);
                    },
                },
                {
                    key: '2',
                    label: t('actions.copy_link'),
                    icon: <CopyOutlined />,
                    onClick: () => {
                        record.url && copy(record.url);
                        notification.success({
                            className: 'success-notification',
                            message: '',
                            description: t('plan_form.success.copy_success', {
                                ns: 'common',
                            }),
                        });
                    },
                },
            ];
        }

        items.push({
            key: '0',
            label: t('buttons.delete', { ns: 'common' }),
            icon: <DeleteOutlined />,
            onClick: () => {
                handleDelete(id);
            },
        });
        return items;
    };

    const canActive = (record: NRegistration.IRegistrationInfo) =>
        isFreePlan(record.extraAppPlanId) || isTrial(record);

    const isTrial = (record: NRegistration.IRegistrationInfo) =>
        [POS_PLAN_ID.STANDARD, POS_PLAN_ID.STANDARD_FNB, POS_PLAN_ID.FNB_STANDARD].includes(
            record.extraAppPlanId,
        ) && record.planPrice === 0;

    const showExpireTime = (record: NRegistration.IRegistrationInfo) => {
        const frequency = record.frequency ?? PlanFrequencyEnum.Month;
        return `${record.expiredTime} ${
            record.expiredTime > 1 ? t(`frequency.${frequency}`) : t(`frequency.${frequency}s`)
        }`;
    };

    const handleActive = (id: string) => {
        const url = `${apiUrl}/v1/registration/active/${id}`;
        mutate(
            {
                url: url,
                method: 'put',
                values: {},
                errorNotification: false,
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    const code = error?.response?.data?.error || 'unknown';
                    notification.error({
                        className: 'error-notification',
                        message: '',
                        description: t(`plan_form.errors.${code}`, { ns: 'common' }),
                    });
                    console.error(error);
                },
                onSuccess: () => {
                    notification.success({
                        className: 'success-notification',
                        message: '',
                        description: t('plan_form.success.approve_success', {
                            ns: 'common',
                        }),
                    });
                    tableQueryResult.refetch();
                },
            },
        );
    };

    const handleResendMailToCollaborator = (registrationId: string) => {
        const url = `${apiUrl}/v1/registration/resend-mail`;
        mutate(
            {
                url: url,
                method: 'post',
                values: { registrationId },
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    notification.error({
                        className: 'error-notification',
                        message: '',
                        description: t(`plan_form.errors.resend_mail_error`, { ns: 'common' }),
                    });
                    console.error(error);
                },
                onSuccess: () => {
                    notification.success({
                        className: 'success-notification',
                        message: '',
                        description: t('plan_form.success.resend_mail_success', { ns: 'common' }),
                    });
                },
            },
        );
    };
    const { mutate: deleteAction } = useDelete();

    const handleDelete = (id: string) => {
        deleteAction(
            {
                resource: `v1/registration`,
                id,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: () => {
                    notification.success({
                        message: t('notifications.deleteSuccess', { ns: 'common' }),
                    });
                    tableQueryResult.refetch();
                    return;
                },

                onError: () => {
                    notification.error({
                        message: t('notifications.deleteError', { ns: 'common' }),
                    });
                },
            },
        );
    };

    const onSearchRegistration = (value: string) => {
        setCurrent(1);
        setFilters([
            {
                field: 'filter',
                operator: 'eq',
                value: value,
            },
        ]);
    };

    const onChangeTable = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter:
            | SorterResult<NRegistration.IRegistrationInfo>
            | SorterResult<NRegistration.IRegistrationInfo>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    return (
        <>
            <section className="partner-list-container">
                <div className="list-header">
                    <CustomSearch
                        placeholder={t('fields.search.placeholder')}
                        className={'search-item'}
                        onChange={(event) => onSearchRegistration(event.target.value)}
                        allowClear={true}
                    />
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult?.data?.data}
                            pagination={false}
                            rowKey="id"
                            onChange={onChangeTable}
                            scroll={{ y: '100%', x: '800px' }}
                        >
                            <Table.Column
                                sorter
                                title={<>{t('fields.email.label')}</>}
                                dataIndex="email"
                                key="email"
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                                width={250}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('fields.full_name.label')}</>}
                                dataIndex="fullName"
                                key="fullName"
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                                width={250}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('fields.phone_number.label')}</>}
                                dataIndex="phoneNumber"
                                key="phoneNumber"
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                                width={150}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('fields.plan_name.label')}</>}
                                dataIndex="extraAppPlanName"
                                key="extraAppPlanName"
                                render={(text, item: NRegistration.IRegistrationInfo) => (
                                    <p className="table-tbody-text">
                                        {t(`plan_form.plan_type.${text.toLowerCase()}`, {
                                            ns: 'common',
                                        })}
                                        {isTrial(item) ? ` (${t('trial')}) ` : ''}
                                    </p>
                                )}
                                width={150}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('fields.qty_table.label')}</>}
                                dataIndex="qtyTable"
                                key="qtyTable"
                                render={(text, item: NRegistration.IRegistrationInfo) => (
                                    <p className="table-tbody-text">
                                        {item.businessType && !checkCountTableQty(item.businessType)
                                            ? '--'
                                            : text}
                                    </p>
                                )}
                                width={150}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('fields.reference_code.label')}</>}
                                dataIndex="referenceCode"
                                key="referenceCode"
                                render={(text, _) => (
                                    <p className="table-tbody-text">{text || '--'}</p>
                                )}
                                width={150}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('fields.expired_time.label')}</>}
                                dataIndex="expiredTime"
                                key="expiredTime"
                                render={(text, item: NRegistration.IRegistrationInfo) => (
                                    <p className="table-tbody-text">{showExpireTime(item)}</p>
                                )}
                                width={150}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('fields.created_at.label')}</>}
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(text, _) => (
                                    <p className="table-tbody-text">
                                        {formatDate(text, DATE_FORMAT_MINUTE)}
                                    </p>
                                )}
                                width={150}
                            />

                            <Table.Column
                                dataIndex="id"
                                key="action"
                                render={(id, record: NRegistration.IRegistrationInfo) => {
                                    const items = renderItems(id, record);
                                    if (!items.length) return <></>;
                                    return (
                                        <div className="flex justify-end items-center">
                                            <Dropdown
                                                menu={{
                                                    items,
                                                    selectable: true,
                                                }}
                                                placement="bottomRight"
                                                arrow
                                                overlayClassName="contact-dropdown-container"
                                                trigger={['click']}
                                            >
                                                <img
                                                    src="/images/icons/dots-vertical.svg"
                                                    alt="more"
                                                    className="cursor-pointer"
                                                />
                                            </Dropdown>
                                        </div>
                                    );
                                }}
                                width={100}
                            />
                        </Table>
                    </div>
                </div>

                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>
        </>
    );
};
