import './styles.scss';

import { DATE_FORMAT } from '@constants/index.constant';
import { IMarketplace } from '@interfaces/marketplace';
import { ISubscription } from '@interfaces/subscription';
import { useTable } from '@refinedev/antd';
import { useNavigation, useTranslate } from '@refinedev/core';
import { convertPrice } from '@utils/resource';
import { Button, Table } from 'antd';
import { useParams } from 'react-router-dom';
import {
    formatAppSubscriptionPeriodEnd,
    formatDate,
    RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
    subtractDay,
} from 'utils/date';

export const BillingSubscriptionIndex: React.FC = () => {
    const translate = useTranslate();
    const { id } = useParams();
    const { goBack } = useNavigation();

    const { tableProps } = useTable<ISubscription.SubscriptionList>({
        resource: `v1/sale-subscription/billing/${id}`,
        hasPagination: false,
    });

    return (
        <>
            <section className="item-list-container billing-subscription-page-wrapper">
                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table {...tableProps} rowKey="id">
                            <Table.Column
                                title={<>{translate('subscriptions.createdAt')}</>}
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(text, _) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {formatDate(text, DATE_FORMAT)}
                                        </p>
                                    );
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.periodStart')}</>}
                                dataIndex="periodStart"
                                key="periodStart"
                                render={(text, _) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {formatDate(text, DATE_FORMAT)}
                                        </p>
                                    );
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.periodEnd')}</>}
                                dataIndex="periodEnd"
                                key="periodEnd"
                                render={(periodEnd, record: IMarketplace.ISubscriptionInvoice) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {formatAppSubscriptionPeriodEnd(
                                                RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
                                                periodEnd as Date,
                                                record,
                                            )}
                                        </p>
                                    );
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.netAmount')}</>}
                                dataIndex="netAmount"
                                key="netAmount"
                                render={(text, _) => {
                                    return <p className="table-tbody-text">{convertPrice(text)}</p>;
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.paymentFee')}</>}
                                dataIndex="paymentFee"
                                key="paymentFee"
                                render={(text, _) => {
                                    return <p className="table-tbody-text">{convertPrice(text)}</p>;
                                }}
                            />
                        </Table>
                    </div>
                    <div className="go-page-list flex justify-end pt-6">
                        <Button onClick={goBack}>{translate('buttons.cancel')}</Button>
                    </div>
                </div>
            </section>
        </>
    );
};
