import { PlusOutlined } from '@ant-design/icons';
import { DEFAULT_STEP, MAX_VALUE } from '@constants/subcription-plan.constant';
import { CurrencyType } from '@enums/currency-type.enum';
import { NSubscriptionPlan } from '@interfaces/subcription-plan';
import { formatPriceBaseOnCurrency } from '@utils/resource';
import { Button, Col, Divider, InputNumber, Row, Select } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePriceCalculator } from '../hooks/usePriceCalculator';

interface PriceCalculatorFormProps {
    quantity: number;
    onChangeQuantity: (value: number) => void;
    priceThresholds: NSubscriptionPlan.PriceThreshold[];
    minValue: number;
    defaultPrice?: number;
    defaultThreshold?: number;
    currencyType?: CurrencyType;
    translations?: {
        quantity?: string;
        currency?: string;
        total?: string;
    };
    selectOptions?: Array<{ label: string; value: number }>;
    onChangeSelectOptions?: (value: { label: string; value: number }) => void;
}

const CustomSelect: FC<{
    options: {
        label: string;
        value: number;
    }[];
    minValue: number;
    stepValue: number;
    value: number;
    addItem: (value: { label: string; value: number }) => void;
    onChange?: ((value: number | null) => void) | undefined;
    onChangeQuantity: (value: number) => void;
}> = (props) => {
    const { t } = useTranslation('common');
    const { options, minValue, addItem, onChange, value, stepValue, onChangeQuantity } = props;
    const [quantity, setQuantity] = useState<number>(0);
    const [error, setError] = useState<string>();

    const onValidateQuantity = async () => {
        const rounded = Math.round(quantity / stepValue) * stepValue;
        const existedValue = options.some((i) => i.value === rounded);

        if (existedValue) {
            setError(t('modules_banner.form.duplicate_quantity'));
            return;
        }

        if (!rounded) return;
        addItem({ label: rounded.toString(), value: rounded });
        onChangeQuantity(rounded);
        setError(undefined);
    };

    const handleChangeQty = (value: number | null) => {
        const rounded = Math.round((value || 0) / stepValue) * stepValue;
        const existedValue = options.some((i) => i.value === rounded);

        if (existedValue) {
            setError(t('modules_banner.form.duplicate_quantity'));
        } else setError(undefined);

        setQuantity(value || 0);

        onChange && onChange(value);
    };

    return (
        <>
            <Select
                showSearch={false}
                value={value}
                onChange={(value: number) => {
                    onChangeQuantity && onChangeQuantity(value);
                }}
                placeholder={t('modules_banner.form.quantity_placeholder')}
                options={options}
                key={'value'}
                className="mt-1 w-full"
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <div className="mb-2 mx-2">
                            <div className="flex justify-between gap-2 items-center mb-1">
                                <InputNumber
                                    min={minValue}
                                    step={stepValue}
                                    value={value}
                                    onChange={handleChangeQty}
                                    max={MAX_VALUE}
                                    className="w-fit"
                                />
                                <Button
                                    onClick={onValidateQuantity}
                                    type="primary"
                                    className="!h-8 px-2"
                                >
                                    <PlusOutlined />
                                </Button>
                            </div>
                            <p className="text-orange-500 italic">{error && error}</p>
                        </div>
                    </>
                )}
            />
        </>
    );
};

export const PriceCalculatorForm: FC<PriceCalculatorFormProps> = ({
    quantity,
    onChangeQuantity,
    priceThresholds,
    minValue,
    currencyType = CurrencyType.Vnd,
    translations,
    selectOptions,
    onChangeSelectOptions,
}: PriceCalculatorFormProps) => {
    const { t } = useTranslation('common');
    const { calculatePrice, calculateTaxTotal, calculateTotal, calculateTaxPrice } =
        usePriceCalculator({
            priceThresholds,
            minValue,
        });

    const threshold = selectOptions ? calculateTaxPrice(quantity) : calculatePrice(quantity);
    const total = selectOptions ? calculateTaxTotal(quantity) : calculateTotal(quantity);

    const addItem = (value: { label: string; value: number }) => {
        onChangeSelectOptions && onChangeSelectOptions(value);
    };

    return (
        <Row className="price-calculator-form !m-0 border p-2 rounded-lg" gutter={12}>
            <Col span={9} className="grid">
                {translations?.quantity || t('calculator.quantity')}
                {selectOptions?.length ? (
                    <CustomSelect
                        options={selectOptions}
                        addItem={addItem}
                        value={quantity}
                        minValue={minValue}
                        stepValue={threshold.step || DEFAULT_STEP}
                        onChange={(value) => onChangeQuantity(value || 0)}
                        onChangeQuantity={onChangeQuantity}
                    />
                ) : (
                    <InputNumber
                        min={minValue}
                        step={threshold.step}
                        value={quantity}
                        onChange={(value) => onChangeQuantity(value || 0)}
                        className="mt-1 w-full"
                        max={MAX_VALUE}
                    />
                )}
            </Col>
            <Col className="flex items-end mb-1">
                <img src="/images/icons/multiplication-icon.svg" alt="multiplication" />
            </Col>
            <Col className="grid">
                <p>{translations?.currency || t('calculator.currency')}</p>
                <p className="font-medium">
                    {formatPriceBaseOnCurrency(threshold.price, currencyType)}
                </p>
            </Col>
            <Col className="flex items-end mb-1">
                <img src="/images/icons/equal-icon.svg" alt="equal" />
            </Col>
            <Col className="grid">
                <p>{translations?.total || t('calculator.total')}</p>
                <p className="font-medium">{formatPriceBaseOnCurrency(total, currencyType)}</p>
            </Col>
        </Row>
    );
};
