import { BaseKey } from '@refinedev/core';
import { Form, FormProps, Input, Modal, ModalProps, Radio, Space } from 'antd';
import { QuoteStatus } from 'enums/quote-status.enum';
import { useTranslation } from 'react-i18next';

interface IChangeStatusInvoiceProps {
    itemId?: BaseKey;
    modalProps: ModalProps;
    loading: boolean;
    formProps: FormProps<{
        status: QuoteStatus;
    }>;
}

export const ChangeStatusModal: React.FC<IChangeStatusInvoiceProps> = ({
    modalProps,
    formProps,
    itemId,
    loading,
}) => {
    const { t } = useTranslation(['quote']);
    const changeStatusList: Array<{ title: string; value: QuoteStatus }> = [
        {
            value: QuoteStatus.Paid,
            title: t(`quotes.status.${QuoteStatus.Paid}`),
        },
        {
            value: QuoteStatus.Void,
            title: t(`quotes.status.${QuoteStatus.Void}`),
        },
    ];

    return (
        <>
            <Modal
                {...modalProps}
                title={t('invoice.change_invoice_status')}
                key={itemId ? itemId : null}
                confirmLoading={loading}
                width={500}
            >
                <Form {...formProps} layout="vertical">
                    <Form.Item
                        label={t('invoice.fields.status.label')}
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: t('invoice.fields.status.required_message'),
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Space direction="vertical">
                                {changeStatusList.map((status) => (
                                    <Radio key={status.value} value={status.value}>
                                        {status.title}
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={t('invoice.fields.reason.label')}
                        name="reason"
                        rules={[
                            {
                                required: true,
                                message: t('invoice.fields.reason.required_message'),
                            },
                        ]}
                    >
                        <Input.TextArea
                            style={{
                                height: 120,
                                marginBottom: 24,
                            }}
                            placeholder={t('invoice.fields.reason.placeholder')}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
