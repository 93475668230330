import { useCreate, useCustom, useUpdate } from '@refinedev/core';
import { Button, Col, Form, Input, notification, Radio, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemVisibilityEnum } from '../../../enums';
import { getEnvConfig } from '../../../getEnvConfig';
import { NCategory } from '../../../interfaces/category';
const { TextArea } = Input;

const MARKETPLACE_CATEGORY_RESOURCE = 'v1/bo/category';

export const CategoryDetail: React.FC<{
    id?: string | null;
    lowestPriority?: number;
    onChange: (value: boolean, reload?: boolean) => void;
}> = ({ id, onChange }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation(['marketplace', 'common']);
    const [category, setCategory] = useState<NCategory.ICategory | null>();
    const [loading, setLoading] = useState<boolean>(false);
    const { mutate: mutateCreate } = useCreate();
    const { mutate: mutateUpdate } = useUpdate();

    const apiUrl = getEnvConfig.MARKET_API_URL;

    useEffect(() => {
        if (id) {
            getCategoryDetail();
        } else {
            setCategory(null);
        }
    }, [id]);

    const { data: detailCategory, refetch: getCategory } = useCustom<any>({
        url: `${apiUrl}/v1/bo/category/${id}`,
        method: 'get',
        queryOptions: {
            enabled: false,
        },
    });

    const getCategoryDetail = async () => {
        setLoading(true);
        const result = await getCategory();
        if (result?.data?.data) {
            setCategory(detailCategory?.data?.data);
            form.setFieldsValue(result?.data?.data?.data);
        }
        setLoading(false);
        return;
    };

    const onSubmit = async () => {
        let value: any;
        try {
            value = await form.validateFields();
        } catch (err) {
            console.warn(err);
        }
        if (!value) return;
        setLoading(true);

        const request: NCategory.ICategoryRequest = {
            visibility: value.visibility ?? ItemVisibilityEnum.VISIBLE,
            name: value.name,
            description: value.description,
        };

        if (id) {
            request.id = id;
            mutateUpdate(
                {
                    resource: MARKETPLACE_CATEGORY_RESOURCE,
                    dataProviderName: 'marketplace',
                    id: '',
                    values: request,
                    errorNotification: false,
                    successNotification: false,
                },
                {
                    onSuccess: (data) => {
                        const response = data.data;
                        if (response.isSuccess) {
                            notification.success({
                                message: t('marketplace.category.update_category_success'),
                            });
                            onChange(false);
                        } else {
                            notification.error({
                                message: t('marketplace.category.update_category_err'),
                            });
                        }
                    },
                    onError: (error) => {
                        notification.error({
                            message: t(`marketplace.category.${error?.response?.data?.error}`),
                        });
                    },
                },
            );
        } else {
            mutateCreate(
                {
                    resource: MARKETPLACE_CATEGORY_RESOURCE,
                    dataProviderName: 'marketplace',
                    values: request,
                    errorNotification: false,
                    successNotification: false,
                },
                {
                    onSuccess: (data) => {
                        const response = data.data;
                        if (response.isSuccess) {
                            notification.success({
                                message: t('marketplace.category.create_category_success'),
                            });
                            onChange(false);
                        } else {
                            notification.error({
                                message: t('marketplace.category.create_category_err'),
                            });
                        }
                    },
                    onError: (error) => {
                        console.log(error?.response?.data?.error);
                        notification.error({
                            message: t(`marketplace.category.${error?.response?.data?.error}`),
                        });
                    },
                },
            );
        }
        setLoading(false);
    };

    return (
        <>
            <Spin spinning={loading}>
                <section className="category-detail-container">
                    <section className="category-detail-form mb-4">
                        <Form
                            form={form}
                            name="wrap"
                            labelAlign="left"
                            labelWrap
                            wrapperCol={{ flex: 1 }}
                            colon={false}
                            layout="vertical"
                        >
                            <Row gutter={[32, 8]}>
                                <Col className="gutter-row" span={24} md={16}>
                                    <Form.Item
                                        name="visibility"
                                        required
                                        label={t('marketplace.category.status')}
                                    >
                                        <Radio.Group
                                            defaultValue={ItemVisibilityEnum.VISIBLE}
                                            buttonStyle="solid"
                                        >
                                            {(
                                                Object.keys(
                                                    ItemVisibilityEnum,
                                                ) as (keyof typeof ItemVisibilityEnum)[]
                                            ).map((key) => (
                                                <Radio.Button value={ItemVisibilityEnum[key]}>
                                                    {t(`marketplace.category.visibility.${key}`)}
                                                </Radio.Button>
                                            ))}
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('marketplace.category.name_required'),
                                            },
                                        ]}
                                        label={t('marketplace.category.name')}
                                    >
                                        <Input
                                            className="input h-[40px]"
                                            placeholder={t('marketplace.category.name_placeholder')}
                                            maxLength={200}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('marketplace.category.description')}
                                        name="description"
                                        className="mt-6"
                                        rules={[
                                            {
                                                max: 500,
                                                message: t('description_max_length', {
                                                    ns: 'common',
                                                }),
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            rows={3}
                                            placeholder={t(
                                                'marketplace.category.input_description',
                                            )}
                                            className="input"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </section>
                    <div className="btn-group-detail">
                        <div className="flex justify-between items-center">
                            <Button
                                className="mr-2 btn btn-secondary"
                                onClick={() => onChange(false)}
                            >
                                {t('cancel', { ns: 'common' })}
                            </Button>
                            <Button type="primary" className="btn-primary" onClick={onSubmit}>
                                {t('complete', { ns: 'common' })}
                            </Button>
                        </div>
                    </div>
                </section>
            </Spin>
        </>
    );
};
