import './styles.scss';

import { EllipsisOutlined, FolderFilled, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Create,
    CreateButton,
    DeleteButton,
    Edit,
    EditButton,
    SaveButton,
    useDrawerForm,
    useSimpleList,
} from '@refinedev/antd';
import {
    HttpError,
    IResourceComponentsProps,
    useApiUrl,
    useGetIdentity,
    useTranslate,
} from '@refinedev/core';
import {
    Alert,
    Col,
    Collapse,
    Drawer,
    Dropdown,
    Empty,
    Form,
    FormProps,
    Grid,
    Input,
    List,
    Menu,
    message,
    Radio,
    RadioChangeEvent,
    Row,
    Tooltip,
    Upload,
    UploadFile,
    UploadProps,
} from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import { UploadDocumentIcon } from 'components/layout/sidebar/icons/upload-document-icon';
import { useEffect, useState } from 'react';

import { EmbedLinkIcon } from '../../components/layout/sidebar/icons/embed-link-icon';
import { CustomSearch } from '../../components/modules/custom-search';
import { FileList } from '../../components/modules/file-list';
import { FileItem } from '../../components/modules/file-list/item';
import { PERMISSIONS } from '../../constants/permission';
import { DocumentTypeEnum } from '../../enums/Document-type.enum';
import { IDocument, IFolder } from '../../interfaces/document';
import { IUser } from '../../interfaces/user';

export const DOCUMENT_RESOURCE = 'v1/documents';
export const FOLDER_RESOURCE = 'v1/folders';

export const DocumentIndex: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();
    const rights = userIdentity?.rights || [];
    const breakpoint = Grid.useBreakpoint();
    const apiUrl = useApiUrl();
    const token = localStorage.getItem('access_token');
    const [fileInfo, setFileInfo] = useState<UploadFile>();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [editFolder, setEditFolder] = useState<IFolder | null>(null);
    const [isUploadDocument, setIsUploadDocument] = useState<boolean>(false);
    const [typeDocument, setTypeDocument] = useState<DocumentTypeEnum>(DocumentTypeEnum.FILE);
    const [filterNameFile, setFilterNameFile] = useState<string>('');

    const { Panel } = Collapse;
    const { listProps: recentFiles, queryResult: recentQuery } = useSimpleList<
        IDocument,
        HttpError,
        { name: string }
    >({
        pagination: { pageSize: 4, current: 1 },
        resource: DOCUMENT_RESOURCE,
        queryOptions: {
            enabled: false,
        },
    });

    const { listProps: folderList, queryResult: folderQuery } = useSimpleList<
        IFolder,
        HttpError,
        { name: string }
    >({
        resource: FOLDER_RESOURCE,
        hasPagination: false,
        queryOptions: {
            enabled: false,
        },
    });

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
        close: createClose,
    } = useDrawerForm<IFolder>({
        action: 'create',
        resource: FOLDER_RESOURCE,
        successNotification: { message: 'Successfully created', type: 'success' },
        redirect: false,
        onMutationSuccess: (data) => {
            createFormProps.form.resetFields();
            folderQuery.refetch();
        },
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
        close: editClose,
    } = useDrawerForm<IFolder>({
        action: 'edit',
        resource: FOLDER_RESOURCE,
        successNotification: { message: 'Successfully edited', type: 'success' },
        redirect: false,
        onMutationSuccess: (data) => {
            folderQuery.refetch();
            setEditFolder(null);
        },
    });

    const {
        drawerProps: createFileDrawerProps,
        formProps: createFileFormProps,
        saveButtonProps: createFileButtonProps,
        show: createFileShow,
        close: createFileClose,
    } = useDrawerForm<IDocument>({
        action: 'create',
        resource: DOCUMENT_RESOURCE,
        successNotification: { message: 'Successfully created', type: 'success' },
        redirect: false,
        onMutationSuccess: (data) => {
            createFileFormProps.form.resetFields();
            setTypeDocument(DocumentTypeEnum.FILE);
            recentQuery.refetch();
            folderQuery.refetch();
            setFileInfo(undefined);
            setIsUploadDocument(true);
        },
    });

    const fileTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.ms-powerpoint',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ];

    const beforeUpload = (file: RcFile) => {
        const isValidType = fileTypes.indexOf(file.type) >= 0;
        if (!isValidType) {
            message.error('You can only upload DOC/DOCX/XLSX/PPT/PDF file!');
        }
        const isLt = file.size / 1024 / 1024 < 10;
        if (!isLt) {
            message.error(`File must be smaller than ${10}MB!`);
        }
        if (!(isValidType && isLt)) {
            return false;
        }

        return true;
    };

    const uploadProps: UploadProps = {
        accept: fileTypes.join(','),
        name: 'file',
        maxCount: 1,
        multiple: false,
        showUploadList: false,
        beforeUpload,
        action: `${apiUrl}/v1/resource/upload-temporary-attachment`,
        headers: { Authorization: `Bearer ${token}` },
        onChange(info) {
            const { status, response, size, name } = info.file;
            if (status === 'uploading') {
                setIsUploading(true);
            }
            if (status === 'done') {
                setIsUploading(false);
                setFileInfo(info.file);

                createFileFormProps.form?.setFieldsValue({ url: response.url });
            } else if (status === 'error') {
                setIsUploading(false);
                setFileInfo(undefined);
                message.error(info.file.error);
            }
        },
    };

    useEffect(() => {
        recentQuery.refetch();
        folderQuery.refetch();
    }, []);

    const uploadDocumentSuccess = () => {
        setIsUploadDocument(false);
    };

    const onChangeFilter = (value: string) => {
        setFilterNameFile(value);
    };

    const form = (formProps: FormProps) => {
        return (
            <Form
                {...formProps}
                layout="vertical"
                initialValues={
                    editFolder ? { name: editFolder.name } : { ...createFormProps.initialValues }
                }
            >
                <Row gutter={20}>
                    <Col md={24}>
                        <Form.Item
                            label={t('resource.fields.name')}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t('resource.fields.nameRequired'),
                                },
                            ]}
                        >
                            <Input maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <>
            <div className="resource-file-container">
                {rights.indexOf(PERMISSIONS.WL_SERVICE_CREATE) >= 0 && (
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <div className="flex">
                                <div className="filter-wrapper">
                                    <CustomSearch
                                        placeholder="Search resources"
                                        className={'search-item'}
                                        onChange={(event: any) => {
                                            onChangeFilter(event.target.value);
                                        }}
                                        allowClear={true}
                                    />
                                </div>
                                <div className="ml-auto">
                                    <CreateButton
                                        icon={<PlusOutlined />}
                                        className="mr-3 btn-create-folder"
                                        onClick={() => createShow()}
                                    >
                                        {t('resource.buttons.newFolder')}
                                    </CreateButton>
                                    <CreateButton
                                        icon={<UploadOutlined />}
                                        type="primary"
                                        onClick={() => createFileShow()}
                                    >
                                        {t('resource.buttons.newResource')}
                                    </CreateButton>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
                {(folderList?.dataSource?.length || 0) > 0 ? (
                    <Collapse
                        defaultActiveKey={['1', '2'].concat(
                            folderList?.dataSource?.map((f) => f.id) || [],
                        )}
                        expandIconPosition="end"
                    >
                        <Panel header={t('resource.label.recent')} key="1">
                            <div className="file-list">
                                <List
                                    loading={recentQuery.isFetching}
                                    grid={{
                                        gutter: 20,
                                        xs: 1,
                                        sm: 1,
                                        md: 2,
                                        lg: 4,
                                        xl: 4,
                                        xxl: 4,
                                    }}
                                    dataSource={recentFiles.dataSource}
                                    renderItem={(item: IDocument) => (
                                        <List.Item key={item.id} className="file-item">
                                            <FileItem
                                                onDeleteSuccess={() => {
                                                    recentQuery.refetch();
                                                    folderQuery.refetch();
                                                }}
                                                file={item}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </Panel>
                        <Panel header={t('resource.label.folders')} key="2">
                            <div className="folder-list">
                                <List
                                    loading={folderQuery.isFetching}
                                    grid={{
                                        gutter: 20,
                                        xs: 1,
                                        sm: 1,
                                        md: 2,
                                        lg: 4,
                                        xl: 4,
                                        xxl: 6,
                                    }}
                                    dataSource={folderList.dataSource}
                                    renderItem={(item: IFolder) => (
                                        <List.Item key={item.id} className="folder-item">
                                            <Tooltip title={item.name}>
                                                <div className="flex items-center">
                                                    <FolderFilled style={{ fontSize: 22 }} />
                                                    <a
                                                        href={'#' + item.id}
                                                        className="overflow-clip whitespace-nowrap text-ellipsis ml-2 mr-2"
                                                    >
                                                        {item.name}
                                                    </a>

                                                    <div className="ml-auto">
                                                        {(rights || []).indexOf(
                                                            PERMISSIONS.WL_RESOURCE_DELETE,
                                                        ) >= 0 &&
                                                            (rights || []).indexOf(
                                                                PERMISSIONS.WL_RESOURCE_EDIT,
                                                            ) >= 0 && (
                                                                <Dropdown
                                                                    overlayClassName="service-dropdown-container"
                                                                    overlay={
                                                                        <Menu mode="vertical">
                                                                            <Menu.Item
                                                                                key="1"
                                                                                icon={
                                                                                    <EditButton
                                                                                        onClick={() => {
                                                                                            setEditFolder(
                                                                                                item,
                                                                                            );
                                                                                            editShow(
                                                                                                item.id,
                                                                                            );
                                                                                        }}
                                                                                        style={{
                                                                                            padding:
                                                                                                '5px 28px 5px 12px ',
                                                                                            margin: 0,
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                style={{
                                                                                    padding: 0,
                                                                                }}
                                                                            ></Menu.Item>

                                                                            <Menu.Item
                                                                                key="1"
                                                                                icon={
                                                                                    <DeleteButton
                                                                                        successNotification={(
                                                                                            data: any,
                                                                                        ) => {
                                                                                            if (
                                                                                                data
                                                                                                    ?.data
                                                                                                    ?.errors[0] ===
                                                                                                '403'
                                                                                            ) {
                                                                                                return {
                                                                                                    message:
                                                                                                        'Only delete empty folder',
                                                                                                    type: 'error',
                                                                                                };
                                                                                            }
                                                                                            if (
                                                                                                !data
                                                                                                    ?.data
                                                                                                    ?.isSuccess
                                                                                            ) {
                                                                                                return {
                                                                                                    message:
                                                                                                        'Delete folder failed',
                                                                                                    type: 'error',
                                                                                                };
                                                                                            }
                                                                                            return {
                                                                                                message:
                                                                                                    'Successfully deleted',
                                                                                                type: 'success',
                                                                                            };
                                                                                        }}
                                                                                        resource="v1/folders"
                                                                                        recordItemId={
                                                                                            item.id
                                                                                        }
                                                                                        onSuccess={(
                                                                                            data,
                                                                                        ) => {
                                                                                            if (
                                                                                                data
                                                                                                    .data
                                                                                                    .isSuccess
                                                                                            ) {
                                                                                                folderQuery.refetch();
                                                                                            }
                                                                                        }}
                                                                                        style={{
                                                                                            margin: 0,
                                                                                            padding:
                                                                                                '5px 12px',
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                style={{
                                                                                    padding: 0,
                                                                                }}
                                                                            ></Menu.Item>
                                                                        </Menu>
                                                                    }
                                                                    trigger={['click']}
                                                                >
                                                                    <EllipsisOutlined
                                                                        style={{
                                                                            fontSize: 24,
                                                                        }}
                                                                    />
                                                                </Dropdown>
                                                            )}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </Panel>
                        {folderList?.dataSource?.map((folder) => {
                            return (
                                <Panel id={folder.id} header={folder.name} key={folder.id}>
                                    <FileList
                                        isUploadDocument={isUploadDocument}
                                        uploadDocumentSuccess={uploadDocumentSuccess}
                                        folderId={folder.id}
                                        filterNameFile={filterNameFile}
                                        deleteDocumentSuccess={() => recentQuery.refetch()}
                                        isFetchedFolder={folderQuery.isRefetching}
                                    ></FileList>
                                </Panel>
                            );
                        })}
                    </Collapse>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                <>
                    {(editDrawerProps.open || createDrawerProps.open) && (
                        <Drawer
                            {...(editFolder ? editDrawerProps : createDrawerProps)}
                            width={breakpoint.sm ? '700px' : '85%'}
                            maskClosable={false}
                            zIndex={100}
                            className="resource-drawer drawer-form-container create-folder"
                            onClose={() => {
                                if (editFolder) {
                                    setEditFolder(null);
                                    editFormProps.form?.resetFields();
                                    editClose();
                                    return;
                                }
                                createFormProps.form?.resetFields();
                                createClose();
                            }}
                            title={
                                <span className="text-lg">
                                    {!editFolder
                                        ? `${t('resource.label.newFolder')}`
                                        : 'Edit Folder'}
                                </span>
                            }
                            extra={
                                editFolder ? (
                                    <SaveButton {...editSaveButtonProps} />
                                ) : (
                                    <SaveButton {...createSaveButtonProps} />
                                )
                            }
                        >
                            {editFolder ? (
                                <Edit
                                    title={<></>}
                                    footerButtons={<></>}
                                    headerProps={{ extra: null, backIcon: null }}
                                    breadcrumb={null}
                                >
                                    {form(editFormProps)}
                                </Edit>
                            ) : (
                                <Create
                                    title={<></>}
                                    footerButtons={<></>}
                                    breadcrumb={null}
                                    headerProps={{ extra: null, backIcon: null }}
                                >
                                    {form(createFormProps)}
                                </Create>
                            )}
                        </Drawer>
                    )}
                </>
                <>
                    {createFileDrawerProps.open && (
                        <Drawer
                            {...createFileDrawerProps}
                            width={breakpoint.sm ? '700px' : '85%'}
                            maskClosable={false}
                            zIndex={100}
                            className="resource-drawer drawer-form-container resource-file-container"
                            onClose={() => {
                                createFileFormProps.form.resetFields();
                                setTypeDocument(DocumentTypeEnum.FILE);
                                setFileInfo(undefined);
                                setIsUploading(false);
                                createFileClose();
                            }}
                            title={
                                <span className="text-lg">{`${t(
                                    'resource.label.uploadFile',
                                )}`}</span>
                            }
                            extra={<SaveButton {...createFileButtonProps} />}
                        >
                            <Create
                                breadcrumb={null}
                                headerProps={{ extra: null, backIcon: null }}
                                title={<></>}
                                footerButtons={<></>}
                                isLoading={
                                    isUploading || (createFileButtonProps.loading as boolean)
                                }
                            >
                                <Form
                                    {...createFileFormProps}
                                    onFinish={async (values: any) => {
                                        if (typeDocument === DocumentTypeEnum.FILE) {
                                            values.url = fileInfo?.response.url;
                                            values.name = fileInfo?.name;
                                            values.size = fileInfo?.size;
                                        }

                                        createFileFormProps.onFinish &&
                                            createFileFormProps.onFinish(values);
                                    }}
                                    initialValues={{ type: DocumentTypeEnum.FILE }}
                                    disabled={isUploading}
                                    layout="vertical"
                                    className="upload-document-form"
                                >
                                    <Row gutter={20}>
                                        <Col md={24}>
                                            <Form.Item
                                                label={t('resource.fields.folder')}
                                                name="folderId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'resource.fields.folderRequired',
                                                        ),
                                                    },
                                                ]}
                                                className="select-folder"
                                            >
                                                {folderList?.dataSource?.length ? (
                                                    <Radio.Group
                                                        buttonStyle="solid"
                                                        className="item-folder"
                                                    >
                                                        {folderList.dataSource?.map((folder) => {
                                                            return (
                                                                <Tooltip title={folder.name}>
                                                                    <Radio.Button
                                                                        key={folder.id}
                                                                        value={folder.id}
                                                                    >
                                                                        <FolderFilled
                                                                            style={{
                                                                                fontSize: 20,
                                                                                marginRight: 8,
                                                                            }}
                                                                        />
                                                                        {folder.name}
                                                                    </Radio.Button>
                                                                </Tooltip>
                                                            );
                                                        })}
                                                    </Radio.Group>
                                                ) : (
                                                    <>
                                                        <Empty
                                                            className="m-0"
                                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                        />
                                                        <Alert
                                                            className="mt-1"
                                                            message="Please create a folder before uploading"
                                                            type="warning"
                                                        />
                                                    </>
                                                )}
                                            </Form.Item>

                                            <Form.Item
                                                name="type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('resource.fields.typeRequired'),
                                                    },
                                                ]}
                                                className="select-type"
                                            >
                                                <Radio.Group
                                                    className="item-type"
                                                    defaultValue={DocumentTypeEnum.FILE}
                                                    onChange={(e: RadioChangeEvent) => {
                                                        setTypeDocument(e.target.value);
                                                        setFileInfo(undefined);
                                                        createFileFormProps.form?.setFieldsValue({
                                                            url: '',
                                                        });
                                                    }}
                                                >
                                                    <Radio value={DocumentTypeEnum.FILE}>
                                                        {t('resource.label.uploadFile')}
                                                    </Radio>
                                                    <Radio value={DocumentTypeEnum.LINK}>
                                                        {t('resource.label.link')}
                                                    </Radio>
                                                </Radio.Group>
                                            </Form.Item>

                                            {typeDocument === DocumentTypeEnum.FILE ? (
                                                <Form.Item
                                                    label={t('resource.fields.file')}
                                                    name="url"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'resource.fields.fileRequired',
                                                            ),
                                                        },
                                                    ]}
                                                    className="select-file-for-folder"
                                                >
                                                    <Upload {...uploadProps} disabled={isUploading}>
                                                        {isUploading ? (
                                                            <>Uploading...</>
                                                        ) : fileInfo ? (
                                                            <>{fileInfo.name}</>
                                                        ) : (
                                                            <>
                                                                <p className="ant-upload-drag-icon flex justify-center">
                                                                    <UploadDocumentIcon />
                                                                </p>
                                                                <p className="ant-upload-text">
                                                                    {t('resource.upload.text')}
                                                                </p>
                                                                <p className="ant-upload-hint">
                                                                    {t('resource.upload.hint')}
                                                                </p>
                                                            </>
                                                        )}
                                                    </Upload>
                                                </Form.Item>
                                            ) : (
                                                <Row gutter={16}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="name"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        'resource.fields.nameRequired',
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                maxLength={100}
                                                                placeholder={t(
                                                                    'resource.fields.namePlaceholder',
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="url"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        'resource.fields.urlRequired',
                                                                    ),
                                                                },
                                                            ]}
                                                            className="field-url"
                                                        >
                                                            <Input
                                                                prefix={<EmbedLinkIcon />}
                                                                placeholder={t(
                                                                    'resource.fields.urlPlaceholder',
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </Create>
                        </Drawer>
                    )}
                </>
            </div>
        </>
    );
};
