import {
    DATE_FORMAT,
    DATE_FORMAT_MINUTE,
    DATE_FORMAT_PERIOD,
    DATE_FORMAT_TIME,
} from '@constants/index.constant';
import { IMarketplace } from '@interfaces/marketplace';
import { SubscriptionInvoiceStatus } from 'enums/subscription-invoice-status.enum';

import dayjs, { ManipulateType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en';
import 'dayjs/locale/vi';
// dayjs config
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const RECURRING_SUBSCRIPTION_SUBTRACT_DAY = 1;

export const formatDate = (date: Date | string, format = DATE_FORMAT_PERIOD): string => {
    if (!date) return '';
    return dayjs.utc(date).local().format(format);
};

export const toLocaleDateTime = (date: Date): string => {
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};
export const formatDateTime = (date: string | Date): string => {
    return dayjs.utc(date).format(DATE_FORMAT_MINUTE);
};

export const fromNow = (date: string | Date): string => {
    const localTime = dayjs.utc(date).local().format(DATE_FORMAT_TIME);
    return dayjs.utc(localTime).fromNow(true);
};

export const fromNowNotAgoText = (date: string | Date): string => {
    const localTime = dayjs.utc(date).local().format(DATE_FORMAT_TIME);
    return dayjs.utc(localTime).fromNow(true);
};

export const subtractDay = (num: number, currDay: dayjs.Dayjs): Date => {
    const result = currDay.subtract(num, num > 1 ? 'days' : 'day').toDate();
    return result;
};

export const formatAppSubscriptionPeriodEnd = (
    num: number,
    currentDay?: Date,
    invoice?: IMarketplace.ISubscriptionInvoice,
) => {
    const _currDay = currentDay ? dayjs.utc(currentDay) : dayjs.utc();
    if (invoice?.status === SubscriptionInvoiceStatus.Paid && !invoice?.stripeInvoiceId) {
        return formatDate(_currDay.toDate(), DATE_FORMAT);
    }

    return formatDate(subtractDay(num, _currDay), DATE_FORMAT);
};

export const getLocalTime = (date?: string): Date | null => {
    if (!date || !dayjs(date).isValid()) return null;

    const utc = dayjs.utc(date).toDate();
    const local = dayjs(utc).local();
    return local.toDate();
};

export const dateDiff = (date1: Date, date2: Date, getBy: string): number => {
    const diffInTime = date1.getTime() - date2.getTime();
    let result = 0;
    switch (getBy) {
        case 'month':
            result = Math.round(diffInTime / (1000 * 3600 * 24 * 30));
            break;
        case 'day':
            result = Math.round(diffInTime / (1000 * 3600 * 24));
            break;
        case 'hour':
            result = Math.round(diffInTime / (1000 * 3600));
            break;
        case 'minute':
            result = Math.round(diffInTime / (1000 * 60));
            break;
        case 'second':
            result = Math.round(diffInTime / 1000);
            break;
        default:
            break;
    }
    return result;
};

export const dayjsDateDiff = (date1: dayjs.Dayjs, date2: dayjs.Dayjs, getBy?: ManipulateType) => {
    const _getBy = getBy || 'M';
    const _date1 = dayjs(date1).startOf('day');
    const _date2 = dayjs(date2).startOf('day');
    let diff = _date1.diff(_date2, _getBy);

    const addTime = _date2.add(diff, _getBy);

    if (_date1.isAfter(addTime)) diff += 1;

    return diff;
};

export const getCurrentTimeZone = (): string => {
    return dayjs.tz.guess();
};
