import './styles.scss';

import { Toggle } from '@components/modules/toggle';
import { HttpError, useApiUrl, useCustom, useList } from '@refinedev/core';
import { Col, Form, FormProps, Input, Row, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Response from '../../data-access/responses/response';
import { getEnvConfig } from '../../getEnvConfig';
import { NBusinessType } from '../../interfaces/business-type';
import { ITenant } from '../../interfaces/tenant';
import { maskPhone } from '../../utils/string';
import { DebounceAsyncValidator } from '../../utils/validator';

export type FormMode = 'show' | 'add' | 'edit';
type PartnerDetailFormProps = {
    formProps: FormProps;
    mode: FormMode;
    onCancelEdit?: () => void;
    onChangeMode?: (mode: FormMode) => void;
    isCreate?: boolean;
};
export const TenantDetailForm: React.FC<PartnerDetailFormProps> = ({
    formProps,
    mode,
    onCancelEdit,
    onChangeMode,
    isCreate,
}) => {
    const apiUrl = useApiUrl();
    const { t } = useTranslation(['partner', 'common']);

    const [isUpdateAvatar, setIsUpdateAvatar] = useState<boolean>(false);
    const initialValues: ITenant.ITenantInfor = {
        ...formProps.initialValues,
    } as ITenant.ITenantInfor;

    const [host, setHost] = useState<string>(initialValues ? initialValues.host : '');
    const [isSkipOnboarding, setIsSkipOnboarding] = useState<boolean>(false);

    const { refetch } = useCustom<Response<boolean>, HttpError, ITenant.ICheckUniqueHostRequest>({
        url: `${apiUrl}/v1/tenant/check-unique-host`,
        method: 'post',
        config: {
            payload: { host: host },
        },
        queryOptions: {
            enabled: false,
        },
    });

    const { data: businessTypes, isLoading } = useList<NBusinessType.IInfo, HttpError>({
        resource: `v1/business-type`,
    });

    const asyncValidateUniqueHost = DebounceAsyncValidator(400, async () => {
        const { data } = await refetch();

        if (data && data?.data?.data) {
            return true;
        }
        return false;
    });

    const renderFormItem = (isReadonly: boolean, formItem: JSX.Element, showItem: JSX.Element) => {
        if (isReadonly) {
            return showItem;
        }
        return formItem;
    };

    const hostSuffix = <span>.{getEnvConfig.DOMAIN}</span>;

    return (
        <Form
            className={`partner-detail ${mode === 'show' ? 'show-mode' : ''}`}
            {...formProps}
            onFinish={async (values) => {
                const bizTypeCode = values.businessTypes;
                values.businessTypes = {
                    data:
                        businessTypes?.data?.filter(
                            (item: NBusinessType.IInfo) => item.code === bizTypeCode,
                        ) ?? [],
                };
                if (mode === 'edit') {
                    values.businessTypes.tenantId = initialValues.id;
                    values.businessTypes.id = initialValues.businessTypes?.id;
                    values.avatarUrl = isUpdateAvatar ? values.avatarUrl : null;
                } else {
                    values.avatarUrl = values.avatarUrl?.length > 0 ? values.avatarUrl : null;
                    values.skipOnboarding = isSkipOnboarding ?? false;
                }
                if (values) formProps.onFinish && formProps.onFinish(values);
            }}
            layout="vertical"
            initialValues={{
                ...initialValues,
                phone: maskPhone(initialValues?.phone || ''),
                businessTypes: (
                    (initialValues?.businessTypes?.data ?? []) as Record<string, string>[]
                ).map((item: Record<string, string>) => item['code']),
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    <Form.Item
                        className="toggle-button"
                        label={t('partners.fields.host.label')}
                        name="host"
                        rules={
                            mode === 'add'
                                ? [
                                      {
                                          required: true,
                                          message: t('partners.fields.host.required'),
                                      },
                                      {
                                          pattern: /^[a-zA-Z0-9]?[a-zA-Z0-9.-]*[a-zA-Z0-9]$/,
                                          message: t('partners.fields.host.pattern'),
                                      },
                                      {
                                          validator: async (_, value) => {
                                              if (
                                                  value === '' ||
                                                  !value ||
                                                  value === initialValues.host ||
                                                  !/^[a-zA-Z0-9]?[a-zA-Z0-9.-]*[a-zA-Z0-9]$/.test(
                                                      value,
                                                  )
                                              ) {
                                                  return;
                                              }

                                              const result = await asyncValidateUniqueHost();
                                              if (result) {
                                                  return Promise.resolve();
                                              }
                                              return Promise.reject(
                                                  new Error(t('partners.fields.host.unique')),
                                              );
                                          },
                                      },
                                  ]
                                : []
                        }
                    >
                        {renderFormItem(
                            mode !== 'add',
                            <Input
                                placeholder={t('partners.fields.host.placeholder')}
                                suffix={hostSuffix}
                                onChange={(e) => setHost(e.target.value)}
                            />,
                            <span>{initialValues?.host}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.name.label')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: `${t('partners.fields.name.required')}`,
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <Input
                                placeholder={t('partners.fields.name.placeholder')}
                                maxLength={100}
                            />,
                            <span>{initialValues?.name}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.firstName.label')}
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: t('partners.fields.firstName.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <Input placeholder={t('partners.fields.firstName.placeholder')} />,
                            <span>{initialValues?.firstName}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.lastName.label')}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: t('partners.fields.lastName.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <Input placeholder={t('partners.fields.lastName.placeholder')} />,
                            <span>{initialValues?.lastName}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.email.label')}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t('partners.fields.email.required'),
                            },
                            {
                                type: 'email',
                                message: t('partners.fields.email.valid'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode !== 'add',
                            <Input
                                placeholder={t('partners.fields.email.placeholder')}
                                onChange={(e) => {
                                    const _value: string = e?.target?.value;
                                    if (_value) {
                                        formProps?.form?.setFieldsValue({
                                            email: _value.trim().toLowerCase(),
                                        });
                                    }
                                }}
                            />,
                            <span>{initialValues?.email}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.phoneNumber.label')}
                        name="phone"
                        rules={[
                            {
                                pattern: /\(\d{3}\)-\d{3}-\d{4}/,
                                message: t('partners.fields.phoneNumber.required'),
                            },
                            {
                                required: true,
                                message: t('partners.fields.phoneNumber.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <MaskedInput
                                mask={
                                    //  https://imask.js.org/guide.html#masked-pattern
                                    '(000)-000-0000'
                                }
                            />,
                            <span>{maskPhone(initialValues?.phone || '')}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        name="businessTypes"
                        label={t('partners.fields.business_type.label')}
                        required
                        rules={[
                            {
                                required: true,
                                message: t('partners.fields.business_type.required'),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t('partners.fields.business_type.label')}
                            showArrow
                            suffixIcon={<img src="/images/icons/arrow-down-icon.svg" />}
                        >
                            {!isLoading &&
                                businessTypes?.data?.map((item: NBusinessType.IInfo) => (
                                    <Select.Option value={item.code} key={item.code}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                    {isCreate ? (
                        <>
                            <div>
                                <Form.Item
                                    className="invite-user-form-item mt-3"
                                    name="skipOnboarding"
                                >
                                    <Toggle onChange={(value) => setIsSkipOnboarding(value)} />
                                    <span className="pl-2 font-bold">
                                        {t('users.skip_onboarding', { ns: 'common' })}
                                    </span>
                                </Form.Item>
                            </div>
                        </>
                    ) : null}
                </Col>
            </Row>
        </Form>
    );
};
