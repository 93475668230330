import { CustomModal } from '@components/modules/modal-custom';
import { MQTT_TOPIC } from '@constants/mqtt-topic';
import { useSubscription } from '@zodinet-pos/core-ui';
import { Button } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import s from './notification-payment-modal.module.scss';

type QRModalProps = {
    objectId: string;
    onClose: () => void;
    onCloseQrModal: () => void;
    title?: string;
    subTitle?: string;
    id?: string;
    host?: string;
    notification?: string;
};

export const NotificationPaymentModal: FC<QRModalProps> = ({
    objectId,
    onClose,
    onCloseQrModal,
    subTitle,
    id,
    title,
    host,
    notification,
}) => {
    const { t } = useTranslation('common');
    const topic = `${MQTT_TOPIC.PAYMENT}${objectId}`;
    const { message: data } = useSubscription.default(topic);

    const [url, setUrl] = useState<string>();
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const _notification = subTitle || notification;

    useEffect(() => {
        if (subTitle) setIsOpenModal(true);
        if (host) {
            setIsOpenModal(true);
            setUrl(host);
        }

        if (data?.message) {
            const url = data?.message as string;

            if (url) {
                setUrl(url);
                setIsOpenModal(true);
                onCloseQrModal();
            }
        }
    }, [data, subTitle, host]);

    const handleClose = useCallback(() => {
        onClose();
        setIsOpenModal(false);
    }, []);

    return (
        <CustomModal
            footer={false}
            closable={true}
            open={isOpenModal}
            maskClosable={false}
            onCancel={handleClose}
        >
            <section className={`${s.notificationPaymentModal} flex flex-col items-center`}>
                <div className={`  flex flex-col items-center mt-4`}>
                    <img src="/images/payment-success.png" className={s.successImg} />
                    <h4 className={`${s.title} text-center mt-4 mb-2`}>
                        {title ?? t('plan_form.success.register_success')}
                    </h4>
                    {_notification && <p className="italic">{t(_notification)}</p>}
                </div>
                {url && !id && (
                    <div className={`${s.btnWrap} px-4 pt-4 flex items-center justify-center`}>
                        <Button
                            type="primary"
                            className={`${s.backBtn} flex items-center justify-center mt-2`}
                            onClick={() => {
                                window.location.href = url;
                                handleClose();
                            }}
                        >
                            {t('plan_form.visit_tenant')}
                        </Button>
                    </div>
                )}
            </section>
        </CustomModal>
    );
};
