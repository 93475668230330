import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, Button } from 'antd';
import { useState } from 'react';

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.location.href,
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message as string);
            setLoading(false);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            window?.location?.reload();
        }
    };

    return (
        <form className="mt-3">
            <PaymentElement />
            {errorMessage && (
                <div className="mt-4">
                    <Alert message={errorMessage} type="error" />
                </div>
            )}
            <div className="flex">
                <Button
                    type="primary"
                    loading={loading}
                    className="mt-3 ml-auto"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </div>
        </form>
    );
};

export default CheckoutForm;
