import { Create, Edit } from '@refinedev/antd';
import { ButtonProps, Drawer, DrawerProps, FormProps, Grid } from 'antd';
import { ReactNode } from 'react';

import s from './styles.module.scss';

type FormDrawerProps = {
    title: string;
    isEditMode: boolean;
    children: ReactNode;
    formProps: FormProps;
    drawerProps: DrawerProps;
    saveButtonProps: ButtonProps;
    resetStatusDrawer: () => void;
    formLoading?: boolean;
};

export const CollaboratorDetail: React.FC<FormDrawerProps> = ({
    title,
    isEditMode,
    children,
    formProps,
    drawerProps,
    saveButtonProps,
    resetStatusDrawer,
    formLoading,
}) => {
    const breakpoint = Grid.useBreakpoint();

    return (
        <Drawer
            {...drawerProps}
            zIndex={1000}
            title={title}
            maskClosable={false}
            bodyStyle={{ padding: 0 }}
            onClose={resetStatusDrawer}
            className={s.collaboratorDrawerContainer}
            width={breakpoint.sm ? '700px' : '90%'}
            afterOpenChange={() => {
                formProps.form?.resetFields();
            }}
        >
            {!isEditMode ? (
                <Create
                    title={<></>}
                    breadcrumb={null}
                    isLoading={formLoading || false}
                    saveButtonProps={saveButtonProps}
                    headerProps={{ backIcon: null, extra: null }}
                >
                    {children && <>{children}</>}
                </Create>
            ) : (
                <Edit
                    title={<></>}
                    breadcrumb={null}
                    isLoading={formLoading || false}
                    saveButtonProps={saveButtonProps}
                    headerProps={{ backIcon: null, extra: null }}
                >
                    {children && <>{children}</>}
                </Edit>
            )}
        </Drawer>
    );
};
