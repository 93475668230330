import './styles.scss';

import { AntDesignOutlined, CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { LoginIcon } from '@components/layout/icons/login';
import { Chip } from '@components/modules/chip';
import { CustomSearch } from '@components/modules/custom-search';
import { CustomPagination } from '@components/modules/pagination';
import { PINConfirm } from '@components/modules/pin-confirm';
import { PartnerDetail } from '@components/partner/detail';
import { PAGE_SIZE } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { PartnerTypeEnum } from '@enums/partner-type.enum';
import { TenantStatusEnum } from '@enums/tenant-status.enum';
import { IPartner } from '@interfaces/partner';
import { ITenant } from '@interfaces/tenant';
import {
    DeleteButton,
    getDefaultSortOrder,
    mapAntdSorterToCrudSorting,
    useDrawerForm,
} from '@refinedev/antd';
import { useApiUrl, useCustomMutation, usePermissions, useTable } from '@refinedev/core';
import { formatDate, getPublicMediaUrl } from '@utils/resource';
import {
    Avatar,
    Button,
    Dropdown,
    Menu,
    notification,
    Popconfirm,
    Radio,
    RadioChangeEvent,
    Table,
    TablePaginationConfig,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const PartnerIndex: React.FC = () => {
    const { t } = useTranslation(['partner', 'common']);
    const { mutate } = useCustomMutation();
    const apiUrl = useApiUrl();

    const [isLoginOnBehalf, setIsLoginOnBehalf] = useState(false);
    const [loginOnBehalfId, setLoginOnBehalfId] = useState<{ userId: string; tenantId: string }>({
        userId: '',
        tenantId: '',
    });

    const {
        setFilters,
        setSorters,
        sorters,
        filters,
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
    } = useTable<ITenant.ITenantInfor>({
        resource: 'v1/partner',
        sorters: {
            initial: [
                {
                    field: 'name',
                    order: 'asc',
                },
            ],
        },
        filters: {
            initial: [
                {
                    field: 'type',
                    operator: 'eq',
                    value: PartnerTypeEnum.WHITELABEL,
                },
            ],
        },
        pagination: {
            pageSize: PAGE_SIZE,
            current: 1,
        },
        initialPageSize: PAGE_SIZE,
        syncWithLocation: true,
    });

    const { data: userRights } = usePermissions<string[]>();

    const defaultType = filters?.find((f: any) => f.field === 'type')?.value;

    const [partnerType, setPartnerType] = useState<PartnerTypeEnum>(
        defaultType || PartnerTypeEnum.WHITELABEL,
    );

    const tags = [
        { value: PartnerTypeEnum.WHITELABEL, label: t('partners.type.wl_plural') },
        { value: PartnerTypeEnum.AFFILIATE, label: t('partners.type.ap_plural') },
    ];

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IPartner.IPartnerInfo>({
        action: 'create',
        resource: 'v1/partner',
        successNotification: { message: 'Successfully created', type: 'success' },
        redirect: false,
        onMutationSuccess: () => {
            createFormProps.form.resetFields();
        },
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IPartner.IPartnerInfo>({
        action: 'edit',
        resource: 'v1/partner/' + partnerType,
        successNotification: { message: 'Successfully edited', type: 'success' },
        redirect: false,
        onMutationSuccess: () => {
            tableQueryResult.refetch();
        },
    });

    const menu = (
        id: string,
        status: TenantStatusEnum,
        record: ITenant.ITenantInfor & { tenantId?: string },
    ) => (
        <Menu mode="vertical">
            {/* send invite */}
            {record.status === TenantStatusEnum.PENDING &&
            (userRights || []).includes(PERMISSIONS.WL_PARTNER_EDIT) ? (
                <Menu.Item
                    key="1"
                    style={{ padding: 0 }}
                    icon={
                        <Popconfirm
                            title={t('partners.messages.resend_invitation')}
                            placement="rightBottom"
                            onConfirm={() => {
                                handleResendInvitation(record.id);
                            }}
                            okText={t('partners.yes')}
                            cancelText={t('partners.no')}
                            className="px-3 py-1.5 invite__wrapper"
                        >
                            <Button
                                icon={
                                    <img
                                        src="/images/icons/send.svg"
                                        alt="invite"
                                        className="cursor-pointer mr-2"
                                        width={18}
                                        height={18}
                                    />
                                }
                                style={{
                                    padding: '5px 16px 5px 12px',
                                    margin: 0,
                                    marginRight: '0rem',
                                }}
                            >
                                {t('partners.invite')}
                            </Button>
                        </Popconfirm>
                    }
                ></Menu.Item>
            ) : null}
            {/* edit invite */}
            {(userRights || []).includes(PERMISSIONS.WL_PARTNER_EDIT) ? (
                <Menu.Item
                    key="2"
                    icon={
                        <img
                            src="/images/icons/edit.svg"
                            alt="edit"
                            className="cursor-pointer"
                            width={18}
                            height={18}
                        />
                    }
                    onClick={() => {
                        editShow(id);
                    }}
                >
                    {t('actions.edit', { ns: 'common' })}
                </Menu.Item>
            ) : null}
            {/* delete invite */}
            {(userRights || []).includes(PERMISSIONS.WL_PARTNER_DELETE) ? (
                [PartnerTypeEnum.WHITELABEL].includes(partnerType) ? (
                    <Menu.Item
                        key="3"
                        style={{ padding: 0 }}
                        icon={
                            <DeleteButton
                                successNotification={{
                                    message: 'Successfully deleted',
                                    type: 'success',
                                }}
                                errorNotification={{
                                    message: 'Delete failed',
                                    type: 'error',
                                }}
                                resource="v1/partner"
                                recordItemId={id}
                                onSuccess={() => {
                                    tableQueryResult.refetch();
                                }}
                                style={{
                                    padding: '5px 12px',
                                    margin: '0px',
                                }}
                            />
                        }
                    ></Menu.Item>
                ) : (
                    <Menu.Item
                        key="3"
                        icon={
                            <DeleteButton
                                successNotification={{
                                    message: 'Successfully deleted',
                                    type: 'success',
                                }}
                                errorNotification={{
                                    message: 'Delete failed',
                                    type: 'error',
                                }}
                                resource="v1/partner/user"
                                recordItemId={id}
                                onSuccess={() => {
                                    tableQueryResult.refetch();
                                }}
                            />
                        }
                    ></Menu.Item>
                )
            ) : null}
            {status != TenantStatusEnum.PENDING &&
            (userRights || []).includes(PERMISSIONS.WL_PARTNER_EDIT) &&
            [PartnerTypeEnum.WHITELABEL].includes(partnerType) ? (
                <Menu.Item
                    key="4"
                    icon={
                        status === TenantStatusEnum.ACTIVE ? <CloseOutlined /> : <CheckOutlined />
                    }
                    onClick={() => {
                        const newStatus =
                            status == TenantStatusEnum.ACTIVE
                                ? TenantStatusEnum.DEACTIVATED
                                : TenantStatusEnum.ACTIVE;
                        handleStatus(id, newStatus);
                    }}
                >
                    {t(`actions.${status == TenantStatusEnum.ACTIVE ? 'deactivate' : 'activate'}`, {
                        ns: 'common',
                    })}
                </Menu.Item>
            ) : null}
            {record.status === TenantStatusEnum.ACTIVE &&
            (userRights || []).includes(PERMISSIONS.WL_PARTNER_LIST) ? (
                <Menu.Item
                    key="5"
                    icon={
                        <div className="cursor-pointer">
                            <LoginIcon />
                        </div>
                    }
                    onClick={() => {
                        setLoginOnBehalfId({
                            tenantId:
                                partnerType === PartnerTypeEnum.WHITELABEL
                                    ? record?.id
                                    : record?.tenantId ?? '',
                            userId:
                                partnerType === PartnerTypeEnum.WHITELABEL ? '' : record?.id ?? '',
                        });
                        setIsLoginOnBehalf(true);
                    }}
                >
                    {t('actions.login_on_behalf', { ns: 'common' })}
                </Menu.Item>
            ) : null}
        </Menu>
    );

    const renderStatus = (code: TenantStatusEnum) => {
        let label = '';
        let type = '';
        switch (code) {
            case TenantStatusEnum.PENDING:
                label = t('partners.status.pending', { ns: 'partner' });
                type = 'warning';
                break;
            case TenantStatusEnum.ACTIVE:
                label = t('partners.status.active', { ns: 'partner' });
                type = 'success';
                break;
            case TenantStatusEnum.DEACTIVATED:
                label = t('partners.status.deactivated', { ns: 'partner' });
                type = 'disable';
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    const handlePartnerTypeChange = (e: RadioChangeEvent) => {
        setPartnerType(e.target.value);
        setFilters([
            {
                field: 'type',
                operator: 'eq',
                value: e.target.value,
            },
        ]);
        setCurrent(1);
    };

    const handleResendInvitation = (id: string) => {
        const url = `${apiUrl}/v1/partner/invitation/${partnerType}/${id}`;
        mutate(
            {
                url: url,
                method: 'put',
                values: {},
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data, variables, context) => {
                    if (data && data.data?.isSuccess) {
                        notification.success({
                            className: 'success-notification',
                            message: '',
                            description: t('partners.messages.send_invitation_success'),
                        });
                    } else {
                        notification.error({
                            className: 'error-notification',
                            message: '',
                            description: t('partners.errors.send_invitation_failed'),
                        });
                    }
                },
            },
        );
    };

    const handleStatus = (id: string, status: TenantStatusEnum) => {
        const url = `${apiUrl}/v1/tenant/${status}/${id}`;
        mutate(
            {
                url: url,
                method: 'put',
                values: {},
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data, _, __) => {
                    if (data && data.data?.isSuccess) {
                        notification.success({
                            className: 'success-notification',
                            message: '',
                            description: t('notifications.editSuccess', {
                                ns: 'common',
                            }).replace('{{resource}}', 'status'),
                        });

                        tableQueryResult.refetch();
                    } else {
                        notification.error({
                            className: 'error-notification',
                            message: '',
                            description: t('notifications.editError', {
                                ns: 'common',
                            })
                                .replace('{{resource}}', 'status')
                                .replace('{{statusCode}}', 'tenant.update_failed'),
                        });
                    }
                },
            },
        );
    };

    const onChangeTable = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<ITenant.ITenantInfor> | SorterResult<ITenant.ITenantInfor>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const handleVerifyPinSuccessfully = (data: ITenant.ILoginOnBehalf) => {
        if (data) {
            window.open(
                `${window.location.protocol}//${data.tenantDomain}/login-on-behalf?token=${data.loginPayload?.token}`,
                '_self',
            );
        }
    };

    return (
        <>
            <PartnerDetail
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                isEditMode={false}
                type={partnerType}
            />
            <PartnerDetail
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
                isEditMode={true}
                type={partnerType}
            />
            <section className="partner-list-container">
                <Radio.Group
                    onChange={handlePartnerTypeChange}
                    value={partnerType}
                    style={{ marginBottom: 8 }}
                    options={tags}
                    optionType="button"
                    className="filter-group"
                ></Radio.Group>
                <div className="list-header">
                    <CustomSearch
                        placeholder={t('partners.fields.type.placeholder', { ns: 'partner' })}
                        className={'search-item'}
                        defaultValue={filters?.find((f: any) => f.field === 'filter')?.value}
                        onChange={(event) => {
                            setFilters([
                                {
                                    field: 'filter',
                                    operator: 'eq',
                                    value: event.target.value,
                                },
                            ]);
                            setCurrent(1);
                        }}
                        allowClear={true}
                    />
                    {(userRights || []).includes(PERMISSIONS.WL_PARTNER_CREATE) ? (
                        <Button className="btn-add-new" type="primary" onClick={() => createShow()}>
                            <PlusOutlined />
                            {t('add_new', { ns: 'common' })}
                        </Button>
                    ) : null}
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult.data?.data}
                            loading={tableQueryResult.isFetching}
                            onChange={onChangeTable}
                            rowKey="id"
                            pagination={false}
                            tableLayout="fixed"
                            scroll={{ x: '900px' }}
                        >
                            <Table.Column
                                title={<>{t('partners.name')}</>}
                                dataIndex="name"
                                key="name"
                                sorter
                                width={250}
                                defaultSortOrder={getDefaultSortOrder('name', sorters)}
                                render={(row, record: ITenant.ITenantInfor) => (
                                    <div className="flex justify-between items-center ">
                                        <div className="flex justify-start items-center">
                                            <Avatar
                                                size={40}
                                                src={
                                                    record.avatarUrl
                                                        ? getPublicMediaUrl(record.avatarUrl)
                                                        : null
                                                }
                                                icon={<AntDesignOutlined />}
                                            />
                                            <p className="table-tbody-text ml-2 item-name pd-0 w-52">
                                                {row}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('email', sorters)}
                                title={<>{t('partners.fields.email.label', { ns: 'partner' })}</>}
                                dataIndex="email"
                                key="email"
                                width={250}
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('status', sorters)}
                                title={<>{t('partners.fields.status.label', { ns: 'partner' })}</>}
                                dataIndex="status"
                                key="status"
                                render={(text, _) => renderStatus(text)}
                            />

                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('createdAt', sorters)}
                                title={<>{t('partners.created_at', { ns: 'partner' })}</>}
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(text, _) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                            />
                            <Table.Column
                                dataIndex="id"
                                key="action"
                                width={100}
                                fixed="right"
                                render={(_, record: ITenant.ITenantInfor) => {
                                    return (
                                        <div className="flex justify-end items-center">
                                            {(userRights || []).some((_right) =>
                                                [
                                                    PERMISSIONS.WL_PARTNER_EDIT,
                                                    PERMISSIONS.WL_PARTNER_DELETE,
                                                ].includes(_right),
                                            ) ? (
                                                <Dropdown
                                                    overlay={menu(record.id, record.status, record)}
                                                    placement="bottomRight"
                                                    arrow
                                                    overlayClassName="contact-dropdown-container"
                                                    trigger={['click']}
                                                >
                                                    <img
                                                        src="/images/icons/dots-vertical.svg"
                                                        alt="more"
                                                        className="cursor-pointer"
                                                    />
                                                </Dropdown>
                                            ) : null}
                                        </div>
                                    );
                                }}
                            />
                        </Table>
                    </div>
                    <div className="pagination-container pt-3 pb-4 px-6">
                        <CustomPagination
                            pageSize={pageSize}
                            total={tableQueryResult?.data?.total}
                            current={current}
                            onChange={(currentPage: number, size: number) => {
                                setCurrent(currentPage);
                                setPageSize(size);
                            }}
                        />
                    </div>
                </div>

                {isLoginOnBehalf ? (
                    <PINConfirm
                        title={t('pin.title', { ns: 'common' })}
                        visible={isLoginOnBehalf}
                        confirmData={{
                            tenantId: loginOnBehalfId?.tenantId ?? '',
                            userId: loginOnBehalfId?.userId ?? '',
                        }}
                        setVisible={setIsLoginOnBehalf}
                        onClose={(data: ITenant.ILoginOnBehalf | null) => {
                            if (!data) {
                                return;
                            }
                            handleVerifyPinSuccessfully(data);
                        }}
                    />
                ) : null}
            </section>
        </>
    );
};
