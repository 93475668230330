import { BusinessTypeEnum, POS_PLAN_ID } from '@zodinet-pos/core-ui';

export const checkCountTableQty = (businessType?: BusinessTypeEnum) => {
    if (!businessType) return true;
    return [BusinessTypeEnum.NET, BusinessTypeEnum.COMBO].includes(businessType);
};

export const isFreePlan = (planId: string) => {
    return [POS_PLAN_ID.FREE].includes(planId);
};

export const isTrialPlan = (planId: string) => {
    return [POS_PLAN_ID.STANDARD, POS_PLAN_ID.STANDARD_FNB, POS_PLAN_ID.FNB_STANDARD].includes(
        planId,
    );
};

export const isStandardPlan = (planId: string) => {
    return [POS_PLAN_ID.STANDARD, POS_PLAN_ID.STANDARD_FNB, POS_PLAN_ID.FNB_STANDARD].includes(
        planId,
    );
};
