import './styles.scss';

import { CreditCardOutlined, EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@constants/permission';
import { CurrencyType } from '@enums/currency-type.enum';
import { IUser } from '@interfaces/user';
import { DeleteButton } from '@refinedev/antd';
import { BaseKey, usePermissions, useTranslate } from '@refinedev/core';
import { useGetIdentity } from '@refinedev/core';
import { Card, Divider, Dropdown, Image, Menu, Tooltip, Typography } from 'antd';

import { IProduct } from '../../interfaces/product';
import { formatPriceBaseOnCurrency, getPublicMediaUrl } from '../../utils/resource';

const { Paragraph } = Typography;

type ProductItemProps = {
    isTenant: boolean;
    isContact?: boolean;
    item: IProduct;
    editShow: (id?: BaseKey) => void;
    tenantId?: string;
};

export const ProductItem: React.FC<ProductItemProps> = ({
    isTenant,
    item,
    editShow,
    isContact,
    tenantId,
}) => {
    const t = useTranslate();
    const { data: rights } = usePermissions<string[]>();
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();

    return (
        <Card className="product-item">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                }}
            >
                <div style={{ textAlign: 'center' }}>
                    <Image
                        preview={false}
                        src={getPublicMediaUrl(item.featureImageUrl)}
                        alt={item.name}
                    />
                </div>
                <Paragraph ellipsis={{ rows: 2, tooltip: true }} className="service-name">
                    {item.name}
                </Paragraph>
                <Tooltip placement="topLeft" title={item.description}>
                    <div className="package-content">Package Content</div>
                </Tooltip>
                <div className="frequency">{t('services.frequency.' + item.frequency)}</div>
                <Divider />
                <div className="actions flex justify-between">
                    <div className="money">
                        {!isContact ? (
                            <>
                                <CreditCardOutlined />
                                <span>
                                    {formatPriceBaseOnCurrency(item.price, CurrencyType.Vnd)}
                                </span>
                            </>
                        ) : null}
                    </div>
                    <div className="flex items-center">
                        <div className="chip">
                            <span className={`content ${item.isActive ? '' : 'inactive'}`}>
                                <span className="dot">&nbsp;</span>
                                <span className="label">
                                    {item.isActive
                                        ? t('services.isActive')
                                        : t('services.inactive')}
                                </span>
                            </span>
                        </div>
                        {tenantId === userIdentity?.tenantId &&
                        (rights || []).some((right) =>
                            [PERMISSIONS.WL_SERVICE_EDIT, PERMISSIONS.WL_SERVICE_DELETE].includes(
                                right,
                            ),
                        ) ? (
                            <div className="service-action ml-4">
                                <Dropdown
                                    overlayClassName="service-dropdown-container"
                                    overlay={
                                        <Menu mode="vertical">
                                            {(rights || []).includes(
                                                PERMISSIONS.WL_SERVICE_EDIT,
                                            ) ? (
                                                <Menu.Item
                                                    key="1"
                                                    style={{
                                                        fontWeight: 500,
                                                    }}
                                                    icon={
                                                        <FormOutlined
                                                            style={{
                                                                color: 'green',
                                                            }}
                                                        />
                                                    }
                                                    onClick={() => editShow(item.id)}
                                                >
                                                    {t('services.buttons.edit')}
                                                </Menu.Item>
                                            ) : null}

                                            {(rights || []).includes(
                                                PERMISSIONS.WL_SERVICE_DELETE,
                                            ) ? (
                                                <Menu.Item
                                                    key="2"
                                                    icon={
                                                        <DeleteButton
                                                            successNotification={{
                                                                message: 'Successfully deleted',
                                                                type: 'success',
                                                            }}
                                                            resource="v1/products"
                                                            recordItemId={item.id}
                                                        />
                                                    }
                                                ></Menu.Item>
                                            ) : null}
                                        </Menu>
                                    }
                                    trigger={['click']}
                                >
                                    <EllipsisOutlined
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </Card>
    );
};
